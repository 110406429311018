import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import bulletIcon from "../../assets/icons/bullet.png";
import urlIcon from "../../assets/icons/url.png";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { getIndustryWiseInterviews } from "../../api/industryLeaderInterviewApi";
import { toast } from "react-toastify";
import { getIndustryObservationsApi } from "../../api/industryObservationApi";
import "./AdvisoryNotes.scss";
import add_advisory_img from "../../assets/images/add-advisory.png";
import edit_advisory_img from "../../assets/images/edit-advisory.png";
import delete_advisory from "../../assets/images/delete-advisory.png";
import { useSelector } from "react-redux";
import {
  addAdvisoryApi,
  deleteAdvisoryApi,
  editAdvisoryApi,
  getAdvisoryApi,
} from "../../api/userApi";

export default function AdvisoryNotes({
  industryId,
  clientId,
}) {
  const [advisories, setAdvisories] = useState([]);
  const [add_advisory, setAddAdvisory] = useState("");
  const [edit_advisory, setEditAdvisory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAdvisoryLoading, setIsAdvisoryLoading] = useState(true);

  const getAdvisoryList = async (industryId, clientId) => {
    setIsAdvisoryLoading(false);
    try {
      let params = { industryId: industryId };
      if (clientId){
       params = { clientId: clientId }; 
      }
      const result = await getAdvisoryApi({ ...params });
      setAdvisories(result);
      setIsAdvisoryLoading(true);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Failed",
        {
          position: "bottom-center",
        }
      );
    }
  };

  useEffect(() => {
    if (industryId || clientId) getAdvisoryList(industryId, clientId);
  }, [industryId, clientId]);

  const addAdvisory = async () => {
    // TO DO add_advisory
    if (add_advisory !== "") {
      let params = {};
      if (industryId) {
        params = { industryId: industryId, description: add_advisory };
      } else {
        params = { clientId: clientId, description: add_advisory };
      }
      setIsLoading(false);
      try {
        const result = await addAdvisoryApi(params);
        setIsLoading(true);
        setAddAdvisory("");
        toast.success("Advisory Added successfully", {
          position: "bottom-center",
        });
        getAdvisoryList(industryId, clientId);
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.message || error?.message || "Failed",
          {
            position: "bottom-center",
          }
        );
      }
    } else {
      toast.error("Error while adding advisory Failed", {
        position: "bottom-center",
      });
    }
  };

  const deleteAdvisory = async (advisory_id) => {
    // TO DO add_advisory
    let params = { advisoryId: advisory_id };
    setIsLoading(false);
    try {
      const result = await deleteAdvisoryApi(params);
      // setObservations(result);
      setIsLoading(true);
      toast.success("Advisory deleted successfully", {
        position: "bottom-center",
      });
      getAdvisoryList(industryId, clientId);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Failed",
        {
          position: "bottom-center",
        }
      );
    }
  };

  const editOpenAdvisory = async (advisory_id) => {
    // TO DO add_advisory
    let params = { advisoryId: advisory_id };
    setIsLoading(false);
    try {
      const result = await editAdvisoryApi(params);
      // setObservations(result);
      setIsLoading(true);
      toast.success("Advisory deleted successfully", {
        position: "bottom-center",
      });
      getAdvisoryList(industryId, clientId);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Failed",
        {
          position: "bottom-center",
        }
      );
    }
  };

  const editAdvisory = async (advisory_id) => {
    // TO DO add_advisory
    let params = { advisoryId: advisory_id };
    setIsLoading(false);
    try {
      const result = await editAdvisoryApi(params);
      // setObservations(result);
      setIsLoading(true);
      toast.success("Advisory deleted successfully", {
        position: "bottom-center",
      });
      getAdvisoryList(industryId, clientId);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Failed",
        {
          position: "bottom-center",
        }
      );
    }
  };

  return (
    <Box id={"observations_container"} backgroundColor={"#F8F8F8"}>
      <Text id={"observations_container_label"}>Advisory Notes</Text>
      {isAdvisoryLoading ? (
        <>
          <Box m={"2px 6px"} overflow={"auto"} h={"90px"}>
            {advisories &&
              advisories.map((advisory, index) => (
                <>
                  <Box
                    backgroundColor={"#E6E6E6"}
                    m={"4px 9px"}
                    p={"11px"}
                    borderRadius={"8px"}
                  >
                    <Flex
                      p={"5px"}
                      borderRadius={"8px"}
                      backgroundColor={"#FFFFFF"}
                      justifyContent={"space-between"}
                    >
                      <Box w={"80%"}>
                        <Text className="advisory_text" p={"5px"} m={"0px"}>
                          {advisory.description}
                        </Text>
                      </Box>
                      <Box className="advisory_action">
                        <Box>
                          <Image
                            src={edit_advisory_img}
                            alt="edit_advisory"
                            className="add_advisory"
                            onClick={() => editOpenAdvisory(advisory._id)}
                          />
                        </Box>
                        <Box>
                          <Image
                            src={delete_advisory}
                            alt="delete_advisory"
                            className="add_advisory"
                            onClick={() => deleteAdvisory(advisory._id)}
                          />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </>
              ))}
          </Box>
        </>
      ) : (
        <Box
          mt={2}
          px={4}
          py={3}
          borderWidth={2}
          borderColor="#E6E6E6"
          borderRadius={4}
        >
          <Text textAlign="center">Loading Advisory...</Text>
        </Box>
      )}
      <Box>
        <Flex m={"10px 5px"} borderRadius={"8px"} backgroundColor={"#FFFFFF"}>
          <Input
            onChange={(e) => setAddAdvisory(e.target.value)}
            placeholder="Type your note here ..."
            border={"none"}
            value={add_advisory}
          />
          <Image
            src={add_advisory_img}
            alt="add_advisory"
            className="other_add_advisory"
            onClick={addAdvisory}
          />
        </Flex>
      </Box>
    </Box>
  );
}
