import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import CreateClient from "./CreateClient";
import PastPerformance from "./PastPerformance";
import AuditSheetUploader from "../Audit/AuditSheetUploader";
// import AgencyRightSidebar from "../../components/AgencySidebar/AgencyRightSidebar";
import { CLIENT_BACKGROUND_TABS } from "../../constants/ui";
import { getClientDetails } from "../../actions/user/agencyActions";

const ClientBackgroundLinks = ({ currentTab, changeTab, clientData }) => {
  return (
    <Box>
      <Box m={"0px"} w={"100%"}>
        <Text
          className={
            currentTab === CLIENT_BACKGROUND_TABS.COMPANY
              ? "riskSubTabs selected"
              : "riskSubTabs"
          }
          cursor="pointer"
          _hover={{}}
        onClick={() => changeTab(CLIENT_BACKGROUND_TABS.COMPANY)}
      >
        Company
        </Text>
      </Box>
      <Box m={"0px"} w={"100%"}>
        <Text
          className={
            currentTab === CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT
              ? "riskSubTabs selected"
              : "riskSubTabs"
          }
          cursor="pointer"
          _hover={{}}
        onClick={() => changeTab(CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT)}
      >
        Upload Statement
        </Text>
      </Box>
      <Box m={"0px"} w={"100%"}>
        <Text
          className={
            currentTab === CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE
              ? "riskSubTabs selected"
              : "riskSubTabs"
          }
          cursor="pointer"
          _hover={{}}
          onClick={() =>
            changeTab(CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE)
          }
      >
        View Past Performance
        </Text>
      </Box>
    </Box>
  );
};

const TabContent = ({ tab, clientData }) => {
  switch (tab) {
    case CLIENT_BACKGROUND_TABS.COMPANY:
      return <CreateClient clientData={clientData} />;
    case CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT:
      return <AuditSheetUploader clientData={clientData} />;
    case CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE:
      return <PastPerformance />;
  }
};

const ClientBackground = () => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const navigateTo = searchParams.get("navigateTo");

  // const { clientsData = [] } = useSelector((state) => state.agencyReducer);

  const { loading, clientDetails } = useSelector(
    (state) => state.agencyReducer
  );
  const [currentTab, setCurrentTab] = useState(CLIENT_BACKGROUND_TABS.COMPANY);

  const changeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  // useEffect(() => {
  //   console.log("===ClientBackground===clientId", clientId);
  //   if (clientId && clientsData) {
  //     setClientData(clientsData.find((client) => client.id === clientId));
  //   }
  // }, [clientId, clientData]);

  useEffect(() => {
    if (clientId) {
      dispatch(getClientDetails(clientId));
    }
  }, [clientId]);
  useEffect(() => {
    console.log("=======navigateTo", navigateTo);
    if (navigateTo) {
      if (navigateTo === CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT) {
        setCurrentTab(CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT);
      } else if (navigateTo === CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE) {
        setCurrentTab(CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE);
      } else {
        setCurrentTab(CLIENT_BACKGROUND_TABS.COMPANY);
      }
    }
    //  else {
    //   if (clientData && clientData?.auditUploadYears?.length > 0)
    //     setCurrentTab(CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE);
    //   else setCurrentTab(CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT);
    // }
    // }, [clientId, clientData]);
  }, [navigateTo]);

  return (
    <>
      <Box margin="0px">
        <HStack
          w={"100%"}
          spacing={4}
          alignItems={"start"}
          className="shadowBox"
        >
          <Box w={"200px!important"} h="350px" overflowY={"auto"}>
            <VStack
              // w={'70%'}
              spacing={4}
              m={1}
              id={"segment_risks_type"}
              className="leftNavOptions"
              alignItems={"center"}
              p="10px 3px"
    >
        <ClientBackgroundLinks
          currentTab={currentTab}
          changeTab={changeTab}
                clientData={clientDetails?.clientDetails}
        />
            </VStack>
          </Box>
          <Box mt={2} w={"80%"}>
            {!loading && (
              <TabContent
                tab={currentTab}
                clientData={clientDetails?.clientDetails}
              />
            )}
      </Box>
        </HStack>
      </Box>
    </>
  );
};

export default ClientBackground;
