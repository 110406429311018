export const TYPES_OF_METRICS = {
  marginAndCost: {
    label: "Margin & Cost",
    value: [
      { label: "ebitda", value: "ebitda" },
      { label: "materials", value: "materials" },
      { label: "employees", value: "employees" },
      { label: "overhead", value: "overheads" },
      { label: "intTaxDep", value: "itda" },
    ],
  },
  efficiencyAndLeverage: {
    label: "Efficiency & Leverage",
    value: [
      { label: "arDays", value: "ar Days" },
      { label: "dpo", value: "dpo" },
      { label: "dii", value: "dii" },
      { label: "timesInterestEarned", value: "tie" },
      { label: "debtRatio", value: "dr" },
    ],
  },
  growthAndReturns: {
    label: "Growth & Returns",
    value: [
      { label: "revenueGrowth", value: "Revenue Growth" },
      { label: "roA", value: "roa" },
      { label: "roCE", value: "roce" },
      { label: "roE", value: "roe" },
    ],
  },
};

export const TYPES_OF_SYSTEMATIC_METRICS = {
  financingAndGrowth: {
    label: "Financing & Growth",
    value: [
      { label: "Total Shareholders Equity", value: "totalShareholdersEquity" },
      { label: "Total Debt", value: "totalDebt" },
      { label: "Total Sales", value: "totalSales" },
      { label: "Total EBITDA", value: "totalEbitda" },
    ],
  },
  assets: {
    label: "Assets",
    value: [
      { label: "Total Tangible Assets", value: "totalTangibleAssets" },
      { label: "Total Intangible Assets", value: "totalIntangibleAssets" },
      { label: "Total Financial Assets", value: "totalFinancialAssets" },
      {
        label: "Total Long Term Assets under development",
        value: "totalLongTermAssetsUnderDevelopment",
      },
    ],
  },
  costHeads: {
    label: "Cost Heads",
    value: [
      { label: "Total Materials Cost", value: "totalMaterialsCost" },
      { label: "Total Interest", value: "totalInterest" },
      { label: "Total Employee Cost", value: "totalEmployeeCost" },
      { label: "Total Tax", value: "totalTax" },
    ],
  },
  trade: {
    label: "Trade",
    value: [
      { label: "Total Working Capital", value: "totalWorkingCapital" },
      { label: "Average Inventory", value: "averageInventory" },
      { label: "Total Trade Receivables", value: "totalTradeReceivables" },
      { label: "Total Trade Payables", value: "totalTradePayables" },
    ],
  },
};

export const COLORS = {
  success: {
    bgColor: "#FFF0E2",
    textColor: "#8E3401",
  },
  warning: {
    bgColor: "#EAF4EB",
    textColor: "#0D3B14",
  },
  danger: {
    bgColor: "#FFE6E6",
    textColor: "#591111",
  },
};

export const NUMERATOR_DENOMINATOR = {
  revenueGrowth: {
    numerator:
      "Revenue from Operations of current period - Revenue from Operations of previous period",
    denominator: "Revenue from Operations of previous period",
  },
  ebitda: {
    numerator:
      "(Profit/(loss) before exceptional items and tax + Finance costs + Depreciation and amortisation expense)",
    denominator: "Revenue from Operations",
  },
  materials: {
    numerator:
      "(Cost of materials consumed + Purchase of stock-in-trade + Changes in Stock)",
    denominator: "Revenue from Operations",
  },
  employees: {
    numerator: "Employee benefit expense",
    denominator: "Revenue from Operations",
  },
  overhead: {
    numerator: "(Other expenses + Impairment Expenses/ losses)",
    denominator: "Revenue from Operations",
  },
  intTaxDep: {
    numerator:
      "(Finance costs + Depreciation and amortisation expense + Current tax - Deferred tax)",
    denominator: "Revenue from Operations",
  },
  arDays: {
    numerator:
      "(365 * ((Trade receivables of current period + Trade receivables of previous period)/2)",
    denominator: "Revenue from Operations",
  },
  dpo: {
    numerator:
      "(365 * ((Trade payables of current period + Trade payables of previous period)/2)",
    denominator:
      "(Inventories of previous period + Cost of materials consumed + Purchase of stock-in-trade + Changes in Stock - Inventories of current period)",
  },
  dii: {
    numerator:
      "(365 * ((Inventories of current period + Inventories of previous period)/2)",
    denominator:
      "(Inventories of previous period + Cost of materials consumed + Purchase of stock-in-trade + Changes in Stock - Inventories of current period)",
  },
  timesInterestEarned: {
    numerator:
      "(Profit/ loss before exceptional items and tax + Finance costs)",
    denominator: "Finance costs",
  },
  debtRatio: {
    numerator: "(Current Borrowings + Non-current Borrowings)",
    denominator: "Total Assets",
  },
  roE: {
    numerator: "Profit/ loss after tax from continuing operations",
    denominator: "Total Shareholders Equity",
  },
  roCE: {
    numerator:
      "(Profit/ loss before exceptional items and tax + Finance costs)",
    denominator: "(Total Assets - Total Current Liabilities)",
  },
  roA: {
    numerator: "Profit/ loss after tax from continuing operations",
    denominator: "Total Assets",
  },
};

// New Navigation
export const TYPES_OF_SEGMENT_METRICS = [
  {
    label: "Topline",
    value: [
      {
        label: "Sales",
        value: [
          { label: "Total Sales", value: "totalSales" },
          { label: "Total EBITDA", value: "totalEbitda" },
        ],
      },
      {
        label: "Distribution",
        value: [
          {
            label: "Total Trade Receivables",
            value: "totalTradeReceivables",
          },
          { label: "Average Inventory", value: "averageInventory" },
        ],
      },
    ],
  },
  {
    label: "Bottomline",
    value: [
      {
        label: "HR",
        value: [{ label: "Total Employee Cost", value: "totalEmployeeCost" }],
      },
      {
        label: "Production",
        value: [
          { label: "Total Materials Cost", value: "totalMaterialsCost" },
          { label: "Total Trade Payables", value: "totalTradePayables" },
        ],
      },
      {
        label: "Operations",
        value: [
          { label: "Total Tangible Assets", value: "totalTangibleAssets" },
          {
            label: "Total Intangible Assets",
            value: "totalIntangibleAssets",
          },
          {
            label: "Total Long Term Assets under development",
            value: "totalLongTermAssetsUnderDevelopment",
          },
        ],
      },
    ],
  },
  {
    label: "Financing",
    value: [
      {
        label: "Finance",
        value: [
          { label: "Total Working Capital", value: "totalWorkingCapital" },
          { label: "Total Financial Assets", value: "totalFinancialAssets" },
          { label: "Total Interest", value: "totalInterest" },
          { label: "Total Tax", value: "totalTax" },
          { label: "Total Debt", value: "totalDebt" },
          {
            label: "Total Shareholders Equity",
            value: "totalShareholdersEquity",
          },
        ],
      },
    ],
  },
];

export const TYPES_OF_COMPETATIVE_METRICS = [
  {
    label: "Topline",
    value: [
      {
        label: "Sales",
        value: [
          { label: "Revenue Growth", value: "Revenue Growth" },
          //Relative Market Share
          { label: "EBITDA %", value: "ebitda" },
          { label: "ROE", value: "roe" },
          // Fixed Asset Turnover Ratio
        ],
      },
      {
        label: "Distribution",
        value: [
          // DRO
          // DIO
        ],
      },
    ],
  },
  {
    label: "Bottomline",
    value: [
      {
        label: "HR",
        value: [{ label: "Employees %", value: "employees" }],
      },
      {
        label: "Production",
        value: [
          { label: "Materials %", value: "materials" },
          { label: "DPO", value: "dpo" },
        ],
      },
      {
        label: "Operations",
        value: [
          { label: "Overheads %", value: "overheads" },
          { label: "ROA", value: "roa" },
        ],
      },
    ],
  },
  {
    label: "Financing",
    value: [
      {
        label: "Finance",
        value: [
          // Working Capital Ratio
          { label: "TIE", value: "tie" },
          { label: "ITDA %", value: "itda" },
          { label: "DR", value: "dr" },
          { label: "ROCE", value: "roce" },
          //Working Capital to Sales Ratio
        ],
      },
    ],
  },
];
