import {
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';
import { HamburgerIcon } from "@chakra-ui/icons";
import './Header.scss'
import { useRef } from "react";
import AgencyLeftSidebar from '../../components/AgencySidebar/AgencyLeftSidebar';

const LeftSideHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <>
      <IconButton
        icon={<HamburgerIcon fontSize={20} />}
        aria-label={'Toggle Navigation'}
        onClick={onOpen}
        color={'#30d5c8 !important;'}
        className="left-sidebar-btn"
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody bg="#E6E6E6">
            <AgencyLeftSidebar onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default LeftSideHeader;
