import React from "react";
import AreaChart from "./AreaChart";
import {
  calculateUnit,
  calculateUnitsForValues,
} from "../../../utils/helperFunction";

const EmployeeBenefit = ({ data, chartDesc, xAxisLabel, yAxisLabel }) => {
  const chartDescStyle = {
    color: "black", // You can change this color to any valid CSS color value
  };
  if (!xAxisLabel) {
    xAxisLabel = "Period";
  }

  const valuesWithUnits = calculateUnitsForValues(data);
  yAxisLabel = `Employee Benefit Expense [units in ${valuesWithUnits}]`;
  return (
    <>
      <AreaChart
        data={data}
        heading="Total Employee Benefit Expense"
        chartDesc={<span style={chartDescStyle}>{chartDesc}</span>}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
      />
    </>
  );
};

EmployeeBenefit.defaultProps = {
  xAxisLabel: "Period",
};

export default EmployeeBenefit;
