export const tolerantDiff = (value1, value2) => {
  const epsilon = 0.0001; // A small tolerance value;
  return Math.abs(value1 - value2) <= epsilon;
};

export const areArraysEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}

export const getToken = () => {
  const token = localStorage.getItem('token') || null;
  return token;
}

export const setToken = (token) => {
  localStorage.setItem('token', token);
}

// destructuring error message from response
export const errMsg = (err) => {
  const { response: {data} = {}, message } = err;
  return data?.message || message;
}

export const isHomePage = ()=>{
  const ishome = window.location.href.endsWith('/');
  return ishome;
};

const uniqueValues = new Set();

export function formatCurrencyValue(value) {
  
  if (value < 0) {
    // Handle negative values
    value = Math.abs(value); // Convert to positive for formatting
    if (value >= 1e7) {
      return "-"+getUniqueFormattedValue((value / 1e7).toFixed(2), " Cr");
    } else if (value >= 1e5) {
      return "-"+getUniqueFormattedValue((value / 1e5).toFixed(2), " Lakh");
    } else if (value >= 1e3) {
      return "-"+getUniqueFormattedValue((value / 1e3).toFixed(2), " K");
    } else {
      return "-"+getUniqueFormattedValue((value).toFixed(2),"");
    }
  } else {
    if (value >= 1e7) {
      return getUniqueFormattedValue((value / 1e7).toFixed(2), " Cr");
    } else if (value >= 1e5) {
      return getUniqueFormattedValue((value / 1e5).toFixed(2), " Lakh");
    } else if (value >= 1e3) {
      return getUniqueFormattedValue((value / 1e3).toFixed(2), " K");
    } else {
      return getUniqueFormattedValue((value || 0).toFixed(2),"");
    }
  }
}

function getUniqueFormattedValue(value, unit = "") {
  // Check if the value is already used
  if (uniqueValues.has(value)) {
    // Add a small offset to make it unique
    let offset = 0.01;
    while (uniqueValues.has((parseFloat(value) + offset).toFixed(2))) {
      offset += 0.01;
    }
    value = (parseFloat(value) + offset).toFixed(2);
  }
  
  // Add the value to the set
  uniqueValues.add(value);
  value = unit === "" ? 0 : value;
  
  return value;
}

export function calculateUnitsForValues(dataArray) {
  // Check if the array is empty
  if (dataArray.length === 0) {
    return [];
  }

  // Find the maximum absolute value in the dataArray
  const maxAbsValue = Math.max(...dataArray.map(item => Math.abs(item.value)));

  // Determine the unit based on the magnitude of maxAbsValue
  let unit = "";
  if (maxAbsValue >= 1e7) {
    unit = "Cr"; // Crore
  } else if (maxAbsValue >= 1e5) {
    unit = "Lakh"; // Lakh
  } else if (maxAbsValue >= 1e3) {
    unit = "Thousand"; // Thousand
  }

  // Calculate units for each value in the array
  const valuesWithUnits = dataArray.map(item => ({
    year: item.year,
    value: item.value,
    unit: unit, // Assign the same unit to all values
  }));

  return unit;
}
