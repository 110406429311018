export const performanceMatrixInterpretationText = {
  english: {
    ebitda: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests":
          "strong operational performance and efficiency, with the potential for further improvement",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure":
            "market dynamics and adjust pricing strategies accordingly",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "competitive edge",
          "focus/develop/invest/implement/conduct":
            "cost control and operational efficiency, research and development to drive innovation",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistently strong operational performance and efficiency, with stable profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "ways to enhance productivity and streamline operations",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "startegic partenerships or acqusitions",
          "align/maintain/manage/ensure/control":
            "customer satisfaction and loyalty to sustain revenue growth",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "the customer base or enter new markets",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "potential decline in operational efficiency and profitability, requiring immediate attention",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declinig trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "areas for cost reduction or process optimization, opportunities for revenue diversification or market expansion",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "marketing and sales strategies",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests":
          "improving operational efficiency and profitability, with room for further growth",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "industry trends and adapt the business strategy",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "initiatives to drive operational excellence, technology and automation, product and service differentiation to capture a larger market share",
          "streamline/optimize/support": "resource allocation",
          "enhance/improve/increase/srengthen/expand": "productivity",
          "reduce/minimize/eliminate": "costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistent level of operational efficiency and profitability, with stable performance",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze:
            "ways to enhance customer value and strengthen competitive positioning",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "opportunities for incremental improvements, new markets or product/service offerings",
          "align/maintain/manage/ensure/control":
            "operational excellence and cost efficiency",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "revenue streams",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "potential decline in operational efficiency and profitability, requiring attention and corrective actions",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "pricing strategies",
          "monitor/measure": "",
          "explore/identify":
            "emerging trends or customer preferences, opportunities for revenue optimization",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "measures to improve operational efficiency and cost-effectiveness, market research",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "marketing and sales efforts to maintain or regain market share",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests":
          "gradual improvement in operational efficiency and profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "market dynamics and adjust pricing strategies",
          "explore/identify":
            "opportunities for process optimization and automation",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "employee training and development",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "skills and capabilities, productivity and cost management",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "consistent operational efficiency and profitability without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "ways to enhance customer satisfaction and loyalty to drive revenue growth",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "areas for incremental improvements, opportunities for product/service diversification or market expansion",
          "align/maintain/manage/ensure/control":
            "cost discipline and operational excellence",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests":
          "potential decline in operational efficiency and profitability, requiring attention and corrective measures",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for cost reduction or process optimization",
          "align/maintain/manage/ensure/control": "competitiveness",
          "focus/develop/invest/implement/conduct":
            "corrective actions to improve operational performance, customer acquisition and retention strategies, innovation and product/service enhancements",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "ongoing efforts to improve operational efficiency and profitability, with potential for further growth",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "customer needs and preferences for targeted product/service development",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "market research, pricing strategies that balance profitability and competitiveness",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "operational efficiency and cost-effectiveness, marketing and sales efforts",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent level of operational efficiency and profitability, but there may be opportunities for improvement",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "areas for incremental improvements, niche markets or customer segments to leverage unique value propositions",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "cost control and process optimization, marketing and sales strategies to increase market share and revenue",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "operational efficiency",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests":
          "potential decline in operational efficiency and profitability, requiring immediate attention and corrective actions",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "ways to differentiate products/services and capture a larger market share",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for cost reduction and efficiency gains, partnerships to drive revenue growth",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "customer relationships",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "operational efficiency, customer relationships",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "ongoing efforts to improve operational efficiency and profitability, with potential for future growth",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "impact of improvement efforts on financial performance",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for product/service diversification",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "cost reduction initiatives, marketing and sales strategies",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "operational efficiency and profitability",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent low level of operational efficiency and profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "new markets or distribution channels",
          analyze: "cost structures",
          "evaluate/assess/consider":
            "factors contributing to low profitability",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "opportunities for cost reduction and efficiency improvement, partnerships or collaborations",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "product/service differentiation and value proposition",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "competitiveness",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests":
          "declining operational efficiency and profitability, requiring immediate attention and corrective actions",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "areas for cost reduction and process optimization, partnerships or strategic alliances to leverage synergies",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "turnaround plan to address the decline and restore profitability",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "operational efficiency and profitability, financial performance",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    materials: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "rising cost of materials consumed relative to sales, potentially impacting profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "cost structure",
          "evaluate/assess/consider": "supply chain",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "alternative material options or product redesign, areas for cost optimization and efficiency improvement, opportunities for strategic sourcing and supplier negotiations",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "inventory management practices",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate":
            "material costs, excess inventory and carrying costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent high level of material inputs relative to sales, potentially affecting profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "areas for incremental improvements in material cost management, opportunities for process optimization",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "supplier relationship management, technology and automation",
          "streamline/optimize/support": "material utilization",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "waste, material consumption",
          "negotiate/restructure": "favorable pricing and terms",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining proportion of material inputs relative to sales, which may positively impact profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "supplier contracts",
          "monitor/measure": "market trends",
          "explore/identify":
            "opportunities for better pricing and terms, alternative sourcing options for cost-effective materials",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "material utilization",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "rising proportion of material inputs relative to sales, potentially impacting profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "supplier relationships",
          "review/benchmark": "cost structure",
          "monitor/measure": "material consumption patterns",
          "explore/identify":
            "areas for cost reduction or optimization in material procurement, opportunities for efficiency improvements",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "effective inventory management practices",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate":
            "stock levels and associated carrying costs",
          "negotiate/restructure": "favorable pricing and terms",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent high level of material inputs relative to sales, potentially impacting profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "areas for incremental improvements in material cost management, opportunities for process optimization",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "supplier relationship management, technology and automation",
          "streamline/optimize/support": "material utilization",
          "enhance/improve/increase/srengthen/expand": "efficiency",
          "reduce/minimize/eliminate": "waste, material consumption",
          "negotiate/restructure": "favorable pricing and terms",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining proportion of material inputs relative to sales, which may positively impact profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "supplier contracts",
          "monitor/measure": "market trends",
          "explore/identify":
            "opportunities for better pricing and terms, alternative sourcing options for cost-effective materials",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "material utilization",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "gradual improvement in material cost management and potential for further optimization",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "cost structure",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for cost reduction in material procurement, sustainable sourcing practices",
          "align/maintain/manage/ensure/control":
            "inventory management techniques, supplier relationship management",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "turnover, brand reputation",
          "reduce/minimize/eliminate": "excess stock, material costs",
          "negotiate/restructure": "favorable terms and pricing",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "consistent level of material cost management without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "areas for incremental improvements in material cost management, opportunities for value engineering, cost-effective material alternatives",
          "align/maintain/manage/ensure/control":
            "competitive pricing and timely deliveries",
          "focus/develop/invest/implement/conduct":
            "optimizing inventory levels",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "relationships with suppliers",
          "reduce/minimize/eliminate": "waste in material utilization",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "declining proportion of material inputs relative to sales, which may positively impact profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "supplier contracts",
          "monitor/measure": "market trends",
          "explore/identify":
            "opportunities for better pricing and terms, alternative sourcing options for cost-effective materials",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "material utilization",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "ongoing efforts to improve material cost management and optimize resource utilization",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "supply chain",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "cost-saving opportunities, opportunities for product redesign or material substitutions",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "effective inventory management techniques, collaborations with suppliers",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "stock levels and carrying costs",
          "negotiate/restructure": "favorable pricing and terms",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistent low level of material inputs relative to sales without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "areas for incremental improvements in material cost management, alternative suppliers or sourcing strategies, innovative materials or processes that reduce costs",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "lean manufacturing principles, research and development",
          "streamline/optimize/support": "material utilization",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "declining proportion of material inputs relative to sales, which may negatively impact profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "partnerships or collaborations to access cost-effective materials",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "supplier contracts",
          "monitor/measure": "",
          "explore/identify": "opportunities for better pricing and terms",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "material utilization, competitiveness",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "ongoing efforts to improve cost management and resource utilization, with potential for future growth",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "material costs",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure":
            "impact of cost-saving initiatives on profitability",
          "explore/identify":
            "opportunities for cost reduction and efficiency improvement, strategic partnerships or collaborations to access cost-effective material sources",
          "align/maintain/manage/ensure/control":
            "lean manufacturing practices",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "material usage",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistent low level of material inputs relative to sales without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "factors contributing to low material costs",
          "review/benchmark": "supply chain processes",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "opportunities for optimization and cost reduction, alternative material options or suppliers to achieve cost savings",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "product differentiation and value proposition",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "competitiveness",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "declining proportion of material inputs relative to sales, which may negatively impact profitability",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "partnerships or collaborations to access cost-effective materials",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "supplier contracts",
          "monitor/measure": "",
          "explore/identify": "opportunities for better pricing and terms",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "material utilization",
          "enhance/improve/increase/srengthen/expand": "competitiveness",
          "reduce/minimize/eliminate": "waste",
          "negotiate/restructure": "",
        },
      },
    ],
    employees: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "substantial proportion of revenue being allocated to employee-related expenses, which may suggest higher labor costs and potential inefficiencies in resource allocation",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "factors driving the increasing trend in employee inputs",
          "evaluate/assess/consider": "",
          "review/benchmark":
            "workforce productivity, resource allocation, and workload distribution",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for process optimization and automation",
          "align/maintain/manage/ensure/control":
            "employee outputs with business goals",
          "focus/develop/invest/implement/conduct":
            "performance management systems and incentive structures",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "operational efficiency",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent and high level of employee-related expenses in proportion to revenue, without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "return on investment for employee-related expenses, efficiency of employee utilization",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend in employee inputs",
          "explore/identify":
            "areas for improvement, opportunities for process streamlining and technology adoption",
          "align/maintain/manage/ensure/control":
            "continued alignment with business objectives",
          "focus/develop/invest/implement/conduct": "cost-control measures",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "productivity",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-cutting measures or improved operational efficiency",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider":
            "effectiveness of cost-reduction initiatives and their implications for employee productivity and morale",
          "review/benchmark": "",
          "monitor/measure": "resource allocation",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "staffing levels with business needs",
          "focus/develop/invest/implement/conduct":
            "strategic workforce planning",
          "streamline/optimize/support":
            "productivity while maintaining cost control",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "increasing proportion of revenue being allocated to employee-related expenses, indicating potential challenges in cost management and resource optimization",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "drivers behind the rising trend in employee inputs",
          "evaluate/assess/consider": "appropriateness of staffing levels",
          "review/benchmark": "workforce planning strategies",
          "monitor/measure": "employee productivity",
          "explore/identify":
            "opportunities for cost optimization through improved resource allocation and process efficiency",
          "align/maintain/manage/ensure/control": "with business objectives",
          "focus/develop/invest/implement/conduct":
            "performance measurement systems",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent and high level of employee-related expenses in proportion to revenue, without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "efficiency of employee utilization, return on investment for employee-related expenses",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend in employee inputs",
          "explore/identify":
            "opportunities for process streamlining and technology adoption",
          "align/maintain/manage/ensure/control":
            "continued alignment with business objectives",
          "focus/develop/invest/implement/conduct": "cost-control measures",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "productivity",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-cutting measures or improved operational efficiency",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend in employee inputs",
          "evaluate/assess/consider":
            "effectiveness of cost-reduction initiatives and their implications for employee productivity and morale",
          "review/benchmark": "",
          "monitor/measure": "resource allocation",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "staffing levels with business needs, cost control",
          "focus/develop/invest/implement/conduct":
            "strategic workforce planning",
          "streamline/optimize/support": "productivity",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "gradual increase in employee-related expenses in proportion to revenue, which may be driven by business growth or strategic investments in human capital",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "alignment between workforce size and business growth projections, effectiveness of training and development programs",
          "review/benchmark": "compensation and benefits strategies",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "competitiveness and employee retention",
          "focus/develop/invest/implement/conduct":
            "performance management systems, culture of accountability and continuous improvement",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "consistent and moderate proportion of revenue being allocated to employee-related expenses without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "factors influencing the moderate level of employee inputs, opportunities for efficiency improvement",
          "review/benchmark": "workforce productivity",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "innovative approaches to talent acquisition and retention",
          "align/maintain/manage/ensure/control":
            "skilled and motivated workforce",
          "focus/develop/invest/implement/conduct":
            "employee engagement initiatives",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "job satisfaction and retention",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-saving measures or improved operational efficiency",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend in employee inputs",
          "evaluate/assess/consider":
            "effectiveness of cost-control initiatives and their implications for workforce productivity and morale",
          "review/benchmark": "",
          "monitor/measure": "resource allocation",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "staffing levels with business needs",
          "focus/develop/invest/implement/conduct":
            "strategic workforce planning",
          "streamline/optimize/support":
            "productivity while maintaining cost control",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "gradual increase in employee-related expenses in proportion to revenue, although starting from a low base",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "effectiveness of talent acquisition and retention strategies",
          "review/benchmark": "compensation and benefits packages",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "competitiveness and employee motivation",
          "focus/develop/invest/implement/conduct":
            "training and development programs",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "employee skills and productivity",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistently low level of employee-related expenses in proportion to revenue without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "factors contributing to the low level of employee inputs, effectiveness of talent acquisition and retention strategies",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "workforce productivity and efficiency",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-saving measures or improved operational efficiency",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider":
            "effectiveness of cost-control initiatives and their implications for workforce productivity and morale",
          "review/benchmark": "",
          "monitor/measure": "resource allocation",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "staffing levels with business needs, cost control",
          "focus/develop/invest/implement/conduct":
            "strategic workforce planning",
          "streamline/optimize/support": "productivity",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "gradual improvement in resource efficiency and cost management, resulting in a lower proportion of revenue being allocated to employee-related expenses",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "drivers behind the rising trend",
          "evaluate/assess/consider": "effectiveness of workforce planning",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "factors contributing to improved resource utilization, flexible work arrangements and innovative HR practices to attract and retain talent",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "training and development programs",
          "streamline/optimize/support": "staffing levels",
          "enhance/improve/increase/srengthen/expand":
            "employee skills and productivity",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistent and very low level of employee-related expenses in proportion to revenue without significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "factors influencing the very low level of employee inputs, effectiveness of talent acquisition and retention strategies",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "workforce productivity and efficiency",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to further cost-saving measures or improved operational efficiency",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend in employee inputs",
          "evaluate/assess/consider":
            "effectiveness of cost-control initiatives and their implications for workforce productivity and morale",
          "review/benchmark": "",
          "monitor/measure": "resource allocation",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "staffing levels with business needs, cost control",
          "focus/develop/invest/implement/conduct":
            "strategic workforce planning",
          "streamline/optimize/support": "productivity",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    overhead: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "Rising proportion of overhead expenses relative to revenue could signal inefficiencies or unchecked cost escalation and may impact profit margins negatively",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "Consistently high level of overhead expenses relative to revenue is potential strain on profitability and may lead to reduced competitiveness",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "May be indicative of improved cost management, signifying successful cost-cutting measures and have positive impact on profit margins.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "Rising proportion of overhead expenses relative to revenue could indicate a need for immediate cost containment measures and may impact profit margins and overall financial health.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "Consistent high level of overhead expenses relative to revenue is potential strain on profitability and may require strategic adjustments to maintain competitiveness",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "Declining proportion of overhead expenses relative to revenue may signify successful cost-cutting measures and positively impact profitability.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "Gradual increase in overhead expenses relative to revenue has a moderate impact on profit margins and requires proactive management to prevent further escalation",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "Consistent level of overhead expenses relative to revenue provides stability and has moderate impact on financial health but may require periodic reviews for efficiency gains",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "Declining proportion of overhead expenses relative to revenue, positively impacting profitability and indicating successful cost-cutting measures.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "Requires proactive management to prevent further escalation and may impact competitiveness if not addressed",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "Consistent low level of overhead expenses relative to revenue provides stability and may contribute to competitiveness. Requires periodic reviews for further efficiency gains.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "Declining proportion of overhead expenses relative to revenue, positively impacting efficiency. May signify successful cost-cutting measures",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "Requires proactive management to prevent further escalation and may impact competitiveness if not addressed",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "Consistent low level of overhead expenses relative to revenue provides stability and may contribute to competitiveness. Requires periodic reviews for further efficiency gains.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "Declining proportion of overhead expenses relative to revenue, positively impacting efficiency. May signify successful cost-cutting measures",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    intTaxDep: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "heavy financial obligations or inefficient tax planning causing growing portion of the company's revenue is being consumed by interest expenses, taxes, and depreciation/amortization.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark":
            "depreciation and amortization schedules to align with business needs",
          "monitor/measure": "",
          "explore/identify":
            "tax planning strategies, tax-saving strategies, such as tax credits or deductions.",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "revenue growth, diversification and cost optimization are crucial",
          "streamline/optimize/support":
            "debt structures to reduce interest expenses",
          "enhance/improve/increase/srengthen/expand": "operational efficiency",
          "reduce/minimize/eliminate":
            "interest expenses, depreciation and amortization",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "Inefficient tax planning and debt management giving consistently high level of financial expenses relative to revenue, with no significant changes.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "depreciation and amortization practices.",
          "monitor/measure": "",
          "explore/identify": "opportunities for cost-effective tax planning",
          "align/maintain/manage/ensure/control": "financial costs",
          "focus/develop/invest/implement/conduct":
            "cost-saving measures to reduce interest expenses.",
          "streamline/optimize/support": "tax planning and liabilities.",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "cost structures, operational costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "better debt management and tax planning, potential for improved profitability improving financial efficiency as a lower proportion of revenue is allocated to interest, tax, depreciation, and amortization",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "operational efficiencies to support cost reduction",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "rising debt or higher tax liabilities growing financial burden on revenue leading to reduced profitability and cash flow",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark":
            "depreciation and amortization schedules to manage costs",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "strategies to lower interest expenses and debt levels, efficient tax planning to reduce tax burdens",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "operational efficiency to offset rising expenses",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent high level of financial expenses relative to revenue with no significant changes",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "operational costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "company is actively reducing the allocation of revenue to ITDA items",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "gradual increase in ITDA expenses as a percentage of revenue",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "company consistently maintains a moderate allocation of revenue to ITDA items",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "positive trend of decreasing ITDA expenses as a percentage of revenue",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "gradual increase in ITDA expenses as a percentage of revenue, which can impact financial health if not managed carefully",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "company consistently maintains a lower allocation of revenue to ITDA items",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "decreasing ITDA expenses as a percentage of revenue, which can improve financial health",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "increasing ITDA expenses as a percentage of revenue, starting from a very low base",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "company consistently maintains very low ITDA expenses as a percentage of revenue",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "company is actively reducing ITDA expenses as a percentage of revenue, starting from a very low base",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    roE: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    roCE: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    roA: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    timesInterestEarned: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests":
          "strong ability to cover interest expenses and indicates a healthy financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "allocating excess earnings towards debt reduction or investment in growth opportunities",
          "review/benchmark": "",
          "monitor/measure": "interest rates",
          "explore/identify":
            "opportunities such as refinancing at lower interest rates or negotiating favorable terms with lenders",
          "align/maintain/manage/ensure/control":
            "strong focus on generating consistent profitability",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "debt structure",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistently strong ability to cover interest expenses and maintain a healthy financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "utilizing excess earnings for debt reduction or strategic investments to drive future growth",
          "review/benchmark": "debt structure and interest rates",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "opportunities for strategic financial management, such as optimizing cash flow and deploying excess funds efficiently",
          "align/maintain/manage/ensure/control": "most favorable terms",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining ability to cover interest expenses, potentially indicating increased financial risk",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for expense reduction, refinancing or alternative financing options",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "cash flow generation, interest coverage, profitability",
          "reduce/minimize/eliminate": "interest costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests":
          "strengthening ability to cover interest expenses and reflects a solid financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "cash flow management and working capital efficiency",
          "review/benchmark": "debt structure and interest rates",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "sustainable interest coverage",
          "focus/develop/invest/implement/conduct":
            "strategies to generate consistent operating income",
          "streamline/optimize/support": "interest coverage",
          "enhance/improve/increase/srengthen/expand": "profitability",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistently strong ability to cover interest expenses and maintain a solid financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider":
            "utilizing excess earnings for debt reduction or strategic investments to drive future growth",
          "review/benchmark": "debt structure and interest rates",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "opportunities such as optimizing cash flow and deploying excess funds efficiently",
          "align/maintain/manage/ensure/control": "most favorable terms",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining ability to cover interest expenses, potentially indicating increased financial risk",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for expense reduction, alternative financing options or refinancing",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "profitability and improve cash flow generation to strengthen interest coverage",
          "reduce/minimize/eliminate": "interest costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests":
          "improving ability to cover interest expenses and suggests a positive trajectory",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "debt structure and interest rates",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "consistent profitability and improving operating income, sufficient funds for interest payments",
          "focus/develop/invest/implement/conduct":
            "effective cash flow management practices",
          "streamline/optimize/support": "interest costs",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "relatively stable ability to cover interest expenses, but there may be opportunities for improvement",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "debt structure and interest rates",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "strategies to generate consistent operating income, opportunities for optimization",
          "align/maintain/manage/ensure/control":
            "sufficient funds for interest payments",
          "focus/develop/invest/implement/conduct":
            "effective cash flow management practices",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "profitability",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests":
          "declining ability to cover interest expenses, potentially indicating increased financial risk",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "cost structures",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for expense reduction, alternative financing options or refinancing opportunities",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "interest coverage, profitability, cash flow generation",
          "reduce/minimize/eliminate": "interest costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improving ability to cover interest expenses but still requires attention to strengthen the financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely interest payments",
          "focus/develop/invest/implement/conduct":
            "increasing profitability and generating higher operating income, rigorous cash flow management practices",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "profitability",
          "reduce/minimize/eliminate": "interest expenses",
          "negotiate/restructure": "debt obligations",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistently weak ability to cover interest expenses, highlighting potential financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "debt restructuring or refinancing options",
          "align/maintain/manage/ensure/control":
            "adequate funds for interest payments",
          "focus/develop/invest/implement/conduct":
            "improving cash flow management, strategies to generate higher operating income",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "profitability",
          "reduce/minimize/eliminate": "interest costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests":
          "deteriorating ability to cover interest expenses, indicating significant financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "assistance to address liquidity and interest coverage concerns",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "cash flow generation, alternative financing options",
          "align/maintain/manage/ensure/control": "working capital efficiently",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "interest coverage",
          "reduce/minimize/eliminate": "expenses",
          "negotiate/restructure": "cost structures",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "slight improvement in the ability to cover interest expenses, but the financial position still requires significant attention",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "financial advisors to explore debt restructuring options",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "debt restructuring options",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "aggressive measures to generate higher operating income, stringent cost control measures",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "overall financial performance, profitability",
          "reduce/minimize/eliminate": "expenses",
          "negotiate/restructure": "favorable terms with lenders",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistently weak ability to cover interest expenses, highlighting significant financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "expert advice for debt restructuring or refinancing options",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "aggressive strategies to generate higher operating income, improving cash flow management and working capital efficiency",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "profitability, interest coverage",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests":
          "deteriorating ability to cover interest expenses, indicating severe financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage":
            "immediate financial assistance to address liquidity concerns, financial advisors to restructure debt obligations",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "stringent cost reduction measures",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "interest coverage, profitability",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable terms with lenders",
        },
      },
    ],
    debtRatio: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "growing reliance on debt to finance operations and investments, potentially leading to increased financial risk",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistently high reliance on debt financing, which may pose financial risks if not managed effectively",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "declining reliance on debt financing, potentially reflecting efforts to improve financial stability and reduce financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "growing reliance on debt financing, which may increase financial risk and impact the company's financial stability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "consistent reliance on debt financing, potentially exposing the company to financial risks if not managed properly",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "decreasing reliance on debt financing, reflecting efforts to improve financial stability and reduce financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "gradual increase in the reliance on debt financing, which should be monitored to ensure it remains within acceptable limits",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "relatively stable level of debt financing, but there may be opportunities to optimize the debt structure and improve financial efficiency",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "decreasing reliance on debt financing, reflecting efforts to improve financial stability and reduce financial risks",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "gradual increase in debt financing, which should be carefully managed to maintain financial stability",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and conservative level of debt financing, reflecting a sound financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "decreasing reliance on debt financing, reflecting efforts to maintain a conservative financial position",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "slight increase in debt financing, potentially reflecting a strategic decision to leverage the business for growth or investment opportunities",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "consistently low reliance on debt financing, highlighting a conservative financial approach",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "decreasing reliance on debt financing, potentially reflecting efforts to further strengthen the financial position by reducing debt",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    arDays: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "long time to collect payments from customers, which may impact cash flow and working capital.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "customer creditworthiness",
          "review/benchmark": "credit and collection processes",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "stricter credit policies",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "customer communication and follow-up",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "prolonged collection period, affecting cash flow and working capital.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "automated invoicing and payment tracking systems",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand": "customer communication",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improvement in collection efficiency, although the collection period remains high.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "areas for further improvement",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand":
            "customer communication and follow-up",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "prolonged collection period, impacting cash flow and working capital.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "credit policies and procedures",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "proactive collection strategies",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "customer communication",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "prolonged collection period, affecting cash flow and working capital.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "automated systems for efficient invoicing, tracking, and follow-up",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improvement in collection efficiency, although the collection period remains high.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand":
            "customer communication and follow-up",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "growing collection period, requiring attention to maintain healthy cash flow.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark":
            "credit policies and procedures for potential enhancements",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "proactive collection strategies",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "customer communication",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "relatively stable collection period, but there is room for improvement.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "automated systems for efficient receivables management and communication",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests": "improvement in collection efficiency.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand":
            "customer communication and follow-up",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "shorter collection period, but it is gradually increasing, requiring attention to maintain efficiency.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "credit policies and procedures",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "balance between risk and collection efficiency",
          "focus/develop/invest/implement/conduct":
            "effective credit monitoring and risk assessment systems",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "customer communication",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and shorter collection period, indicating efficient receivables management.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "credit and collection processes",
          "monitor/measure": "stagnant trend",
          "explore/identify": "areas for further improvement",
          "align/maintain/manage/ensure/control": "healthy payment cycle",
          "focus/develop/invest/implement/conduct":
            "automated systems for efficient invoicing, tracking, and communication",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "customer relationships",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests": "improvement in collection efficiency.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely collection",
          "focus/develop/invest/implement/conduct":
            "regular monitoring and follow-up procedures",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand":
            "efficiency of invoicing and billing processes",
          "reduce/minimize/eliminate": "payment delays",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "exceptionally short collection period, but it is gradually increasing, requiring attention to maintain efficiency.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "credit policies and procedures",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "balance between risk and collection efficiency",
          "focus/develop/invest/implement/conduct":
            "effective credit monitoring and risk assessment systems",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "customer communication",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and exceptionally short collection period, indicating highly efficient receivables management.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "credit and collection processes",
          "monitor/measure": "stagnant trend",
          "explore/identify": "areas for further improvement",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "automated systems for efficient invoicing, tracking, and communication",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "customer relationships",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests": "improvement in collection efficiency.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely collection",
          "focus/develop/invest/implement/conduct":
            "regular monitoring and follow-up procedures",
          "streamline/optimize/support": "credit and collection processes",
          "enhance/improve/increase/srengthen/expand":
            "efficiency of invoicing and billing processes",
          "reduce/minimize/eliminate": "payment delays",
          "negotiate/restructure": "",
        },
      },
    ],
    dpo: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "taking longer to pay its trade payables, potentially straining supplier relationships and affecting working capital.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify":
            "opportunities for early payment discounts to incentivize timely payments",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "robust monitoring systems to track and manage payment deadlines",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable payment terms with suppliers",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "prolonged payment period, potentially impacting supplier relationships and working capital.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "the reasons for the stagnant trend",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "areas for improvement",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "communication and collaboration with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable payment terms with suppliers",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improvement in payment efficiency, although the payment period remains high.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely payment",
          "focus/develop/invest/implement/conduct":
            "robust tracking and monitoring systems",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "supplier relationships through effective communication and collaboration",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "prolonged payment period, potentially affecting working capital and supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules and prioritize timely payments",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable payment terms with suppliers",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "relatively stable prolonged payment period, potentially affecting working capital and supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "the stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules and prioritize timely payments",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable payment terms",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improvement in payment efficiency, although the payment period remains high",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely payment",
          "focus/develop/invest/implement/conduct":
            "robust tracking and monitoring systems",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "supplier relationships through effective communication and collaboration",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "growing payment period, requiring attention to maintain healthy working capital and supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "accounts payable processes",
          "monitor/measure": "increasing trend",
          "explore/identify": "opportunities for improvement and efficiency",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules and prioritize timely payments",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable payment terms",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "relatively stable payment period, but there is room for improvement to optimize working capital and supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "accounts payable processes",
          "monitor/measure": "the stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules and prioritize timely payments",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "favorable payment terms",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "improvement in payment efficiency, although the payment period remains moderate.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely payment",
          "focus/develop/invest/implement/conduct":
            "robust tracking and monitoring systems",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "supplier relationships through effective communication and collaboration",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "shorter payment period, but it is gradually increasing, requiring attention to maintain efficiency and supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "accounts payable processes",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "balance between efficient payment processing and having positive supplier relationships, smooth payment cycles",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules to prioritize timely payments",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and shorter payment period, indicating efficient payment processing and positive supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "accounts payable processes",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "smooth payment cycles",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules to prioritize timely payments",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "improvement in payment efficiency, resulting in a shorter payment period.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely payment",
          "focus/develop/invest/implement/conduct":
            "robust tracking and monitoring systems",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "supplier relationships through effective communication and collaboration",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "exceptionally short payment period, but it is gradually increasing, requiring attention to maintain efficiency and positive supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "accounts payable processes",
          "monitor/measure": "increasing trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "balance between efficient payment processing and having positive supplier relationships",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules to prioritize timely payments",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and exceptionally short payment period, indicating highly efficient payment processing and positive supplier relationships.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "positive relationships, smooth payment cycles",
          "focus/develop/invest/implement/conduct":
            "regular reviews of payment schedules to prioritize timely payments",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "communication with suppliers",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "improvement in payment efficiency, resulting in an exceptionally short payment period.",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "timely payment",
          "focus/develop/invest/implement/conduct":
            "robust tracking and monitoring systems",
          "streamline/optimize/support": "accounts payable processes",
          "enhance/improve/increase/srengthen/expand":
            "supplier relationships through effective communication and collaboration",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    dii: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "holding inventory for an extended period, potentially leading to increased carrying costs, obsolescence, and reduced cash flow",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "inventory management processes",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory performance",
          "monitor/measure": "",
          "explore/identify":
            "inefficiencies, areas for improvement, slow-moving or obsolete inventory and take necessary actions",
          "align/maintain/manage/ensure/control":
            "timely delivery of inventory",
          "focus/develop/invest/implement/conduct":
            "demand forecasting and inventory planning",
          "streamline/optimize/support": "inventory levels",
          "enhance/improve/increase/srengthen/expand":
            "collaboration with suppliers",
          "reduce/minimize/eliminate": "excess inventory, lead times",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "relatively stable but prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "slow-moving items and take appropriate actions",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "inventory tracking and reporting systems",
          "streamline/optimize/support":
            "inventory management processes, supply chain processes",
          "enhance/improve/increase/srengthen/expand": "supplier collaboration",
          "reduce/minimize/eliminate":
            "holding periods and carrying costs, lead times and inventory holding",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improvement in inventory management efficiency, although the holding period remains high",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory policies and procedures",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization techniques",
          "streamline/optimize/support": "inventory levels",
          "enhance/improve/increase/srengthen/expand":
            "efficiency, demand forecasting accuracy",
          "reduce/minimize/eliminate": "carrying costs",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory management processes",
          "monitor/measure": "increasing trend",
          "explore/identify": "slow-moving items and take appropriate actions",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "inventory tracking and reporting systems",
          "streamline/optimize/support": "supplier collaboration",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate":
            "holding periods and carrying costs, lead times and inventory holding",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests":
          "relatively stable but prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory management processes",
          "monitor/measure": "stagnant trend",
          "explore/identify":
            "slow-moving items and take appropriate actions, opportunities for supplier collaboration",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "inventory tracking and reporting systems",
          "streamline/optimize/support": "supply chain processes",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate":
            "holding periods and carrying costs, lead times and inventory holding",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests":
          "improvement in inventory management efficiency, although the holding period remains high",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory policies and procedures",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization techniques",
          "streamline/optimize/support":
            "inventory levels and reduce carrying costs",
          "enhance/improve/increase/srengthen/expand":
            "efficiency, demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        "Indicate/Implies/Suggests":
          "growing inventory holding period, requiring attention to maintain optimal inventory levels and minimize carrying costs",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "slow-moving items",
          "align/maintain/manage/ensure/control":
            "inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization strategies, regular inventory reviews and analysis",
          "streamline/optimize/support":
            "inventory levels and reduce carrying costs, stock replenishment",
          "enhance/improve/increase/srengthen/expand":
            "demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests":
          "relatively stable inventory holding period, but there is room for improvement to optimize inventory management",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark":
            "inventory management processes, inventory policies and procedures",
          "monitor/measure": "stagnant trend",
          "explore/identify": "slow-moving items and take appropriate actions",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct":
            "inventory tracking and reporting systems",
          "streamline/optimize/support":
            "inventory levels and reduce carrying costs",
          "enhance/improve/increase/srengthen/expand": "efficiency",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "improvement in inventory management efficiency, resulting in a shorter holding period",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory policies and procedures",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization techniques",
          "streamline/optimize/support":
            "inventory levels and reduce carrying costs",
          "enhance/improve/increase/srengthen/expand":
            "efficiency, demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "shorter inventory holding period, but it is gradually increasing, requiring attention to maintain optimal inventory levels and minimize stockouts",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "potential supply chain bottlenecks",
          "align/maintain/manage/ensure/control":
            "optimal inventory levels and prevent stockouts, inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization strategies, inventory reviews and analysis",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and shorter inventory holding period, indicating efficient inventory management and potential agility in responding to customer demand",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "optimal inventory levels and prevent stockouts, inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory reviews and analysis",
          "streamline/optimize/support":
            "inventory management processes, stock replenishment and prevent excess inventory",
          "enhance/improve/increase/srengthen/expand":
            "demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "continuous improvement in inventory management efficiency, resulting in a shorter holding period",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory policies and procedures",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization techniques",
          "streamline/optimize/support":
            "inventory levels and reduce carrying costs",
          "enhance/improve/increase/srengthen/expand":
            "efficiency, demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests":
          "exceptionally short inventory holding period, but it is gradually increasing, requiring attention to maintain optimal inventory levels and prevent stockouts",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "increasing trend",
          "explore/identify": "potential supply chain bottlenecks",
          "align/maintain/manage/ensure/control":
            "optimal inventory levels and prevent stockouts, inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization strategies, regular inventory reviews and analysis",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand":
            "demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests":
          "relatively stable and exceptionally short inventory holding period, indicating efficient inventory management and potential agility in responding to customer demand",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "stagnant trend",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "optimal inventory levels and prevent stockouts, inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory reviews and analysis",
          "streamline/optimize/support":
            "inventory management processes, stock replenishment and prevent excess inventory",
          "enhance/improve/increase/srengthen/expand":
            "demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        "Indicate/Implies/Suggests":
          "continuous improvement in inventory management efficiency, resulting in an exceptionally short holding period",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "the reasons for the declining trend",
          "evaluate/assess/consider": "",
          "review/benchmark": "inventory policies and procedures",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control":
            "inventory levels with customer demand",
          "focus/develop/invest/implement/conduct":
            "inventory optimization techniques",
          "streamline/optimize/support":
            "inventory levels and reduce carrying costs",
          "enhance/improve/increase/srengthen/expand":
            "efficiency, demand forecasting accuracy",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
    revenueGrowth: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        "Indicate/Implies/Suggests": "",
        BusinessCapabilityRecommendation: {
          "seek/engage": "",
          analyze: "",
          "evaluate/assess/consider": "",
          "review/benchmark": "",
          "monitor/measure": "",
          "explore/identify": "",
          "align/maintain/manage/ensure/control": "",
          "focus/develop/invest/implement/conduct": "",
          "streamline/optimize/support": "",
          "enhance/improve/increase/srengthen/expand": "",
          "reduce/minimize/eliminate": "",
          "negotiate/restructure": "",
        },
      },
    ],
  },
};

export const metricNotes = {
  english: {
    ebitda: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        interpretation:
          "strong operational performance and efficiency, with the potential for further improvement",
        waysToAssessOrDiagnose:
          "Assess revenue diversification and market expansion. Diagnose pricing strategies and operational efficiency.",
        internalControls:
          "Regular margin analysis, and monitoring key cost drivers.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistently strong operational performance and efficiency, with stable profitability",
        waysToAssessOrDiagnose:
          "Evaluate sustainability of current margins. Diagnose customer retention and satisfaction.",
        internalControls:
          "Consistent financial performance reviews and competitive benchmarking.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "potential decline in operational efficiency and profitability, requiring immediate attention",
        waysToAssessOrDiagnose:
          "Identify causes of margin erosion (e.g., cost increases, competitive pressure).",
        internalControls:
          "Frequent financial health checks and margin analysis.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        interpretation:
          "improving operational efficiency and profitability, with room for further growth",
        waysToAssessOrDiagnose:
          "Investigate opportunities for scaling the business. Diagnose revenue growth strategies.",
        internalControls:
          "Monitoring of profit margins by business segment and product line.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistent level of operational efficiency and profitability, with stable performance",
        waysToAssessOrDiagnose:
          "Monitor market share and competitive dynamics. Diagnose operational efficiencies.",
        internalControls: "Regular cost management and operational audits..",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "potential decline in operational efficiency and profitability, requiring attention and corrective actions",
        waysToAssessOrDiagnose:
          "Analyze shifts in market demand or operational inefficiencies.",
        internalControls:
          "Review pricing strategies and cost control measures.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        interpretation:
          "gradual improvement in operational efficiency and profitability",
        waysToAssessOrDiagnose:
          "Focus on strategic initiatives to improve profitability. Diagnose market positioning and product mix.",
        internalControls:
          "Enhanced performance tracking against strategic goals.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "consistent operational efficiency and profitability without significant changes",
        waysToAssessOrDiagnose:
          "Ensure alignment with industry benchmarks. Diagnose business processes for efficiency.",
        internalControls:
          "Regular review of operational processes and cost structures.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        interpretation:
          "potential decline in operational efficiency and profitability, requiring attention and corrective measures",
        waysToAssessOrDiagnose:
          "Assess need for strategic realignment or cost reduction.",
        internalControls:
          "Implement detailed financial reviews and operational cost analyses.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "ongoing efforts to improve operational efficiency and profitability, with potential for further growth",
        waysToAssessOrDiagnose:
          "Explore turnaround strategies and operational improvements. Diagnose cost drivers and market positioning.",
        internalControls:
          "Strict monitoring of financial ratios and regular profitability analysis.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent level of operational efficiency and profitability, but there may be opportunities for improvement",
        waysToAssessOrDiagnose:
          "Evaluate core vs. non-core business activities. Diagnose potential areas for cost savings.",
        internalControls: "Frequent budget reviews and variance analysis.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        interpretation:
          "potential decline in operational efficiency and profitability, requiring immediate attention and corrective actions",
        waysToAssessOrDiagnose:
          "Urgent need for operational restructuring or market repositioning.",
        internalControls:
          "Crisis management planning and regular financial status updates.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "ongoing efforts to improve operational efficiency and profitability, with potential for future growth",
        waysToAssessOrDiagnose:
          "Identify immediate actions to prevent further decline. Diagnose critical business areas needing attention.",
        internalControls:
          "Daily monitoring of cash flows and emergency cost management.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent low level of operational efficiency and profitability",
        waysToAssessOrDiagnose:
          "Develop a strategic plan for recovery. Diagnose fundamental business model challenges.",
        internalControls:
          "Close monitoring of all financial transactions and cost cutting measures.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        interpretation:
          "declining operational efficiency and profitability, requiring immediate attention and corrective actions",
        waysToAssessOrDiagnose:
          "Consider significant restructuring or divestiture. Diagnose business viability and strategic alternatives.",
        internalControls:
          "Implement comprehensive financial reviews and stakeholder communication plans.",
      },
    ],
    intTaxDep: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "heavy financial obligations or inefficient tax planning causing growing portion of the company's revenue is being consumed by interest expenses, taxes, and depreciation/amortization.",
        waysToAssessOrDiagnose:
          "Assess the efficiency of debt and tax management. Diagnose potential over-leveraging or high depreciation costs.",
        internalControls:
          "Debt restructuring analysis, tax optimization strategies, and review of asset depreciation policies.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "Inefficient tax planning and debt management giving consistently high level of financial expenses relative to revenue, with no significant changes.",
        waysToAssessOrDiagnose:
          "Evaluate the long-term sustainability of high ITD costs. Diagnose impacts on profitability and cash flow.",
        internalControls:
          "Continuous monitoring of financial obligations, efficiency audits, and cash flow forecasting.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "better debt management and tax planning, potential for improved profitability improving financial efficiency as a lower proportion of revenue is allocated to interest, tax, depreciation, and amortization",
        waysToAssessOrDiagnose:
          "Investigate reasons for decreasing costs (e.g., debt repayment, tax incentives). Diagnose impacts on financial health and operational flexibility.",
        internalControls:
          "Review of financial management strategies, analysis of tax planning effectiveness.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "rising debt or higher tax liabilities growing financial burden on revenue leading to reduced profitability and cash flow",
        waysToAssessOrDiagnose:
          "Examine trends in interest rates, tax regulations, or asset purchases. Diagnose impacts on operating margin and financial stability.",
        internalControls:
          "Regular review of financing terms, tax compliance checks, and asset management strategies.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent high level of financial expenses relative to revenue with no significant changes",
        waysToAssessOrDiagnose:
          "Monitor for alignment with industry standards. Diagnose if current levels are impacting competitiveness.",
        internalControls:
          "Benchmarking against industry, periodic reviews of tax and interest strategies.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "company is actively reducing the allocation of revenue to ITDA items",
        waysToAssessOrDiagnose:
          "Assess improvements in financial management or tax benefits. Diagnose implications for long-term growth and investment.",
        internalControls:
          "Analysis of debt management, assessment of tax planning strategies.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        interpretation:
          "gradual increase in ITDA expenses as a percentage of revenue",
        waysToAssessOrDiagnose:
          "Explore opportunities for financial optimization. Diagnose current loan terms, tax policies, and asset depreciation.",
        internalControls:
          "Periodic financial health checks, review of loan agreements, and depreciation schedules.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "company consistently maintains a moderate allocation of revenue to ITDA items",
        waysToAssessOrDiagnose:
          "Maintain current strategies while staying alert to financial market changes. Diagnose balance between financial costs and operational needs.",
        internalControls:
          "Continuous monitoring of loan terms, tax regulations, and asset utilization.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation:
          "positive trend of decreasing ITDA expenses as a percentage of revenue",
        waysToAssessOrDiagnose:
          "Investigate financial efficiency gains. Diagnose potential for reinvestment and growth.",
        internalControls:
          "Review of financial strategies, assessment of tax incentives, and investment in efficient assets.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "gradual increase in ITDA expenses as a percentage of revenue, which can impact financial health if not managed carefully",
        waysToAssessOrDiagnose:
          "Assess potential for refinancing or optimizing tax strategies. Diagnose impact on liquidity and investment opportunities.",
        internalControls:
          "Assessment of refinancing options, tax planning reviews, and analysis of asset utilization.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "company consistently maintains a lower allocation of revenue to ITDA items",
        waysToAssessOrDiagnose:
          "Evaluate if low ratio is benefiting the company’s financial performance. Diagnose potential risks of under-investment.",
        internalControls:
          "Regular review of financial policies, investment strategies, and asset management practices.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation:
          "decreasing ITDA expenses as a percentage of revenue, which can improve financial health",
        waysToAssessOrDiagnose:
          "Identify reasons for further decrease in ITD costs. Diagnose implications for financial agility and investment capacity.",
        internalControls:
          "Analysis of financial management practices, exploration of investment opportunities.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "increasing ITDA expenses as a percentage of revenue, starting from a very low base",
        waysToAssessOrDiagnose:
          "IExamine the adequacy of current investment in assets and financial strategies. Diagnose potential missed opportunities for growth.",
        internalControls:
          "Strategic review of capital investments, financial planning, and tax optimization.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "company consistently maintains very low ITDA expenses as a percentage of revenue",
        waysToAssessOrDiagnose:
          "Assess sustainability and potential impacts on business expansion. Diagnose if strategy aligns with long-term goals.",
        internalControls:
          "Long term financial planning, review of asset investment strategies, and tax efficiency analysis.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation:
          "company is actively reducing ITDA expenses as a percentage of revenue, starting from a very low base",
        waysToAssessOrDiagnose:
          "Investigate reasons for extremely low financial costs. Diagnose potential long-term impacts on business operations and growth.",
        internalControls:
          "Assessment of long term financial strategies, review of asset acquisition and disposal policies.",
      },
    ],
    materials: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "rising cost of materials consumed relative to sales, potentially impacting profitability",
        waysToAssessOrDiagnose:
          "Assess procurement policies and cost of goods sold (COGS). Diagnose supply chain inefficiencies or price increases.",
        internalControls:
          "Regular cost analysis, renegotiation with suppliers, and bulk purchasing strategies.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent high level of material inputs relative to sales, potentially affecting profitability",
        waysToAssessOrDiagnose:
          "Evaluate the impact on profit margins. Diagnose potential inefficiencies in material usage.",
        internalControls:
          "Continuous cost monitoring, supplier performance reviews, and inventory management improvements.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining proportion of material inputs relative to sales, which may positively impact profitability",
        waysToAssessOrDiagnose:
          "Investigate reasons for cost reduction (e.g., supplier negotiation, material changes). Diagnose impact on product quality and sales.",
        internalControls:
          "Quality control checks, supplier relationship management, and cost benefit analysis of material changes.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "rising proportion of material inputs relative to sales, potentially impacting profitability",
        waysToAssessOrDiagnose:
          "Examine potential increases in raw material prices or inefficient production. Diagnose purchasing strategies.",
        internalControls:
          "Supplier cost analysis, alternative sourcing strategies, and production process review.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent high level of material inputs relative to sales, potentially impacting profitability",
        waysToAssessOrDiagnose:
          "Monitor the impact on overall profitability. Diagnose if current levels are sustainable or need optimization.",
        internalControls:
          "Margin analysis, evaluation of pricing strategies, and cost control measures.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining proportion of material inputs relative to sales, which may positively impact profitability",
        waysToAssessOrDiagnose:
          "Assess changes in procurement strategies or improvements in production efficiency. Diagnose implications for product pricing.",
        internalControls:
          "Regular review of procurement policies and analysis of production cost efficiencies..",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        interpretation:
          "gradual improvement in material cost management and potential for further optimization",
        waysToAssessOrDiagnose:
          "Explore opportunities to optimize material costs. Diagnose current market trends and supplier pricing.",
        internalControls:
          "Periodic market analysis, review of vendor contracts, and inventory management optimization.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "consistent level of material cost management without significant changes",
        waysToAssessOrDiagnose:
          "Maintain current strategies while staying alert to market changes. Diagnose balance between material costs and sales revenue..",
        internalControls:
          "Ongoing monitoring of cost trends, supplier negotiations, and evaluation of bulk purchasing benefits.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation:
          "declining proportion of material inputs relative to sales, which may positively impact profitability",
        waysToAssessOrDiagnose:
          "Investigate improvements in material cost management. Diagnose potential for increased profitability.",
        internalControls:
          "Analysis of procurement efficiency, supplier performance evaluations, and cost saving initiatives.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "ongoing efforts to improve material cost management and optimize resource utilization",
        waysToAssessOrDiagnose:
          "Assess potential for further cost reductions without compromising quality. Diagnose efficiency in material utilization.",
        internalControls:
          "Supplier performance audits, cost quality trade off analysis, and lean inventory strategies.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistent low level of material inputs relative to sales without significant changes",
        waysToAssessOrDiagnose:
          "Evaluate if low ratio is due to exceptional procurement or production efficiency. Diagnose sustainability of current practices.",
        internalControls:
          "Regular review of procurement and production processes, supplier relationship assessments.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation:
          "declining proportion of material inputs relative to sales, which may negatively impact profitability",
        waysToAssessOrDiagnose:
          "Identify reasons for further decrease in material costs relative to sales. Diagnose implications for product quality and market competitiveness.",
        internalControls:
          "Quality assurance processes, competitive pricing analysis, and continuous improvement programs.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "ongoing efforts to improve cost management and resource utilization, with potential for future growth",
        waysToAssessOrDiagnose:
          "Examine reasons for extremely low material costs. Diagnose potential risks to product quality and supply chain reliability.",
        internalControls:
          "Quality checks, risk assessments of supplier dependency, and diversification of supplier base.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistent low level of material inputs relative to sales without significant changes",
        waysToAssessOrDiagnose:
          "Assess sustainability and impact on competitive positioning. Diagnose if the strategy aligns with overall business goals.",
        internalControls:
          "Strategic planning reviews, cost benefit analysis of current procurement strategies.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation:
          "declining proportion of material inputs relative to sales, which may negatively impact profitability",
        waysToAssessOrDiagnose:
          "Investigate sustainability of extremely low costs. Diagnose potential long-term impacts on business operations.",
        internalControls:
          "Long term supplier contract reviews, assessment of market volatility effects on material costs.",
      },
    ],
    employees: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "substantial proportion of revenue being allocated to employee-related expenses, which may suggest higher labor costs and potential inefficiencies in resource allocation",
        waysToAssessOrDiagnose:
          "Assess workforce efficiency and productivity. Diagnose potential overstaffing or high labor costs.",
        internalControls:
          "Regular labor cost analysis, productivity assessments, and optimization of staffing levels.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent and high level of employee-related expenses in proportion to revenue, without significant changes",
        waysToAssessOrDiagnose:
          "Evaluate the sustainability of high labor costs. Diagnose impacts on profitability and competitive positioning.",
        internalControls:
          "Continuous monitoring of labor efficiency, benchmarking against industry standards.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-cutting measures or improved operational efficiency",
        waysToAssessOrDiagnose:
          "Investigate reasons for decreasing labor costs (e.g., automation, outsourcing). Diagnose impacts on service quality and employee morale.",
        internalControls:
          "Assessment of workforce morale and productivity, analysis of outsourcing or automation ROI.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "increasing proportion of revenue being allocated to employee-related expenses, indicating potential challenges in cost management and resource optimization",
        waysToAssessOrDiagnose:
          "Examine trends in wage increases or staffing levels. Diagnose impacts on profit margins.",
        internalControls:
          "Regular review of compensation strategies, workforce planning, and efficiency analysis.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent and high level of employee-related expenses in proportion to revenue, without significant changes",
        waysToAssessOrDiagnose:
          "Monitor for alignment with industry trends. Diagnose if current levels are efficient for business operations.",
        internalControls:
          "Ongoing evaluation of employee productivity, wage benchmarking, and operational efficiency audits.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-cutting measures or improved operational efficiency",
        waysToAssessOrDiagnose:
          "Assess changes in operational strategies (e.g., process improvements, technology use). Diagnose implications for workforce management.",
        internalControls:
          "Analysis of operational changes, technology leverage, and workforce restructuring impacts.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        interpretation:
          "gradual increase in employee-related expenses in proportion to revenue, which may be driven by business growth or strategic investments in human capital",
        waysToAssessOrDiagnose:
          "Explore opportunities to optimize employee costs. Diagnose current staffing strategies and operational efficiency.",
        internalControls:
          "Periodic assessments of staffing needs, cost benefit analysis of employee training and development.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "consistent and moderate proportion of revenue being allocated to employee-related expenses without significant changes",
        waysToAssessOrDiagnose:
          "Maintain current staffing strategies while monitoring market and industry trends. Diagnose balance between employee costs and sales efficiency.",
        internalControls:
          "Continuous monitoring of employee cost trends, periodic reviews of staffing models.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation:
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-saving measures or improved operational efficiency",
        waysToAssessOrDiagnose:
          "Investigate improvements in employee cost management. Diagnose potential for increased operational efficiency.",
        internalControls:
          "Review of workforce utilization, employee training effectiveness, and role rationalization.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "gradual increase in employee-related expenses in proportion to revenue, although starting from a low base",
        waysToAssessOrDiagnose:
          "Assess potential risks of understaffing or low wage rates. Diagnose impacts on employee satisfaction and turnover.",
        internalControls:
          "Employee satisfaction surveys, turnover rate analysis, and assessment of market competitive compensation.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistently low level of employee-related expenses in proportion to revenue without significant changes",
        waysToAssessOrDiagnose:
          "Evaluate if low ratio is beneficial for the company’s operations. Diagnose sustainability and potential impact on service quality.",
        internalControls:
          "Regular review of service quality metrics, employee engagement levels, and cost effectiveness of current staffing.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation:
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to cost-saving measures or improved operational efficiency",
        waysToAssessOrDiagnose:
          "Identify reasons for further decrease in employee costs. Diagnose implications for business scalability and service quality.",
        internalControls:
          "Analysis of staffing models, service quality impact assessments, and scalability planning.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "gradual improvement in resource efficiency and cost management, resulting in a lower proportion of revenue being allocated to employee-related expenses",
        waysToAssessOrDiagnose:
          "Examine necessity and sustainability of very low employee costs. Diagnose potential risks to business growth and service levels.",
        internalControls:
          "Strategic review of growth plans, risk assessment of low staffing levels, and potential for increased investment in human resources.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistent and very low level of employee-related expenses in proportion to revenue without significant changes",
        waysToAssessOrDiagnose:
          "Assess sustainability and potential impacts on business operations. Diagnose if strategy aligns with long-term business goals.",
        internalControls:
          "Longterm strategic planning, review of human resource investment versus business growth.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation:
          "declining proportion of employee-related expenses relative to revenue, which may be attributed to further cost-saving measures or improved operational efficiency",
        waysToAssessOrDiagnose:
          "Investigate reasons and sustainability of extremely low labor costs. Diagnose potential long-term impacts on employee morale and business operations.",
        internalControls:
          "Longterm impact analysis of human resource strategies, employee wellbeing assessments.",
      },
    ],
    overhead: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "Rising proportion of overhead expenses relative to revenue could signal inefficiencies or unchecked cost escalation and may impact profit margins negatively",
        waysToAssessOrDiagnose:
          "- Overhead cost analysis - Efficiency audits - Market benchmarking",
        internalControls:
          "Budget optimization, process automation, renegotiation of contracts",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "Consistently high level of overhead expenses relative to revenue is potential strain on profitability and may lead to reduced competitiveness",
        waysToAssessOrDiagnose:
          "- Cost-value assessment - Comparative market analysis - Overhead allocation review",
        internalControls:
          "Cost rationalization, periodic financial reviews, strategic cost cutting",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "May be indicative of improved cost management, signifying successful cost-cutting measures and have positive impact on profit margins.",
        waysToAssessOrDiagnose:
          "- Profitability analysis - Cost structure evaluation - Competitor performance comparison",
        internalControls:
          "Lean management practices, overhead cost tracking, continuous improvement plans",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "Rising proportion of overhead expenses relative to revenue could indicate a need for immediate cost containment measures and may impact profit margins and overall financial health.",
        waysToAssessOrDiagnose:
          "- Market positioning review - Cost-benefit analysis of overheads - Overhead vs. revenue growth analysis",
        internalControls:
          "Strategic reallocation of resources, overhead cost control, efficiency enhancement programs",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "Consistent high level of overhead expenses relative to revenue is potential strain on profitability and may require strategic adjustments to maintain competitiveness",
        waysToAssessOrDiagnose:
          "- Operational efficiency analysis - Industry benchmarking - Fixed vs. variable costs assessment",
        internalControls:
          "Process reengineering, variance analysis, waste reduction strategies",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "Declining proportion of overhead expenses relative to revenue may signify successful cost-cutting measures and positively impact profitability.",
        waysToAssessOrDiagnose:
          "- Scalability assessment - Long-term overhead trends analysis - Peer comparison",
        internalControls:
          "Dynamic budgeting, cost management strategies, performance metrics implementation",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        interpretation:
          "Gradual increase in overhead expenses relative to revenue has a moderate impact on profit margins and requires proactive management to prevent further escalation",
        waysToAssessOrDiagnose:
          "- Financial health assessment - Overhead growth vs. sales growth comparison - Efficiency ratio analysis",
        internalControls:
          "Proactive cost management, performance monitoring, strategic investments",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "Consistent level of overhead expenses relative to revenue provides stability and has moderate impact on financial health but may require periodic reviews for efficiency gains",
        waysToAssessOrDiagnose:
          "- Balanced scorecard analysis - Resource utilization review - Market trend analysis",
        internalControls:
          "Continuous improvement programs, financial forecasting, resource optimization",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation:
          "Declining proportion of overhead expenses relative to revenue, positively impacting profitability and indicating successful cost-cutting measures.",
        waysToAssessOrDiagnose:
          "- Cost optimization analysis - Profit margin improvement strategies - Competitive leverage evaluation",
        internalControls:
          "Strategic cost reduction, operational audits, performance tracking systems",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "Requires proactive management to prevent further escalation and may impact competitiveness if not addressed",
        waysToAssessOrDiagnose:
          "- Growth opportunity assessment - Overhead scalability analysis - Return on investment evaluation",
        internalControls:
          "Investment in growth areas, seeking cost-effective solutions, leveraging economies of scale",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "Consistent low level of overhead expenses relative to revenue provides stability and may contribute to competitiveness. Requires periodic reviews for further efficiency gains.",
        waysToAssessOrDiagnose:
          "- Sustainable growth analysis - Overhead efficiency evaluation - Long-term profitability assessment",
        internalControls:
          "Prudent financial management, maintaining operational efficiency, balanced growth strategies",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation:
          "Declining proportion of overhead expenses relative to revenue, positively impacting efficiency. May signify successful cost-cutting measures",
        waysToAssessOrDiagnose:
          "- Competitive advantage analysis - Market expansion feasibility - Financial resilience assessment",
        internalControls:
          "Strategic partnerships, conservative overhead management, agile response to market changes",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "Requires proactive management to prevent further escalation and may impact competitiveness if not addressed",
        waysToAssessOrDiagnose:
          "- Innovation potential assessment - Market relevance evaluation - Agility and flexibility analysis",
        internalControls:
          "Focused investment in strategic areas, exploring alternative efficiency solutions, agility in resource allocation",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "Consistent low level of overhead expenses relative to revenue provides stability and may contribute to competitiveness. Requires periodic reviews for further efficiency gains.",
        waysToAssessOrDiagnose:
          "- Strategic positioning review - Market stability assessment - Resource optimization analysis",
        internalControls:
          "Long-term strategic planning, efficient capital utilization, risk management strategies",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation:
          "Declining proportion of overhead expenses relative to revenue, positively impacting efficiency. May signify successful cost-cutting measures",
        waysToAssessOrDiagnose:
          "- Business sustainability analysis - Competitive landscape evaluation - Overhead costs vs. market position",
        internalControls:
          "Leveraging competitive advantages, proactive market positioning, focused growth strategy",
      },
    ],
    debtRatio: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "growing reliance on debt to finance operations and investments, potentially leading to increased financial risk",
        waysToAssessOrDiagnose:
          "- Risk assessment - Interest coverage analysis - Credit rating evaluation",
        internalControls:
          "Debt restructuring, stringent financial controls, refinancing strategies",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistently high reliance on debt financing, which may pose financial risks if not managed effectively",
        waysToAssessOrDiagnose:
          "- Cash flow analysis - Leverage metrics review - Default risk evaluation",
        internalControls:
          "Cash management, budget tightening, cost control measures",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining reliance on debt financing, potentially reflecting efforts to improve financial stability and reduce financial risks",
        waysToAssessOrDiagnose:
          "- Solvency analysis - Debt maturity profiling - Cost of capital assessment",
        internalControls:
          "Long-term financial planning, liability management, interest rate hedging",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "growing reliance on debt financing, which may increase financial risk and impact the company's financial stability",
        waysToAssessOrDiagnose:
          "- Financial stress testing - Capital structure analysis - Investor confidence assessment",
        internalControls:
          "Strategic refinancing, working capital management, creditor negotiations",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistent reliance on debt financing, potentially exposing the company to financial risks if not managed properly",
        waysToAssessOrDiagnose:
          "- Liquidity analysis - Fixed charge coverage ratio review - Debt servicing capability evaluation",
        internalControls:
          "Operational efficiency improvements, expense monitoring, renegotiation of loan terms",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "decreasing reliance on debt financing, reflecting efforts to improve financial stability and reduce financial risks",
        waysToAssessOrDiagnose:
          "- Market position analysis - Debt-equity ratio comparison - Long-term viability assessment",
        internalControls:
          "Capital reallocation, divestment of non-core assets, financial contingency planning",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "danger",
        interpretation:
          "gradual increase in the reliance on debt financing, which should be monitored to ensure it remains within acceptable limits",
        waysToAssessOrDiagnose:
          "- Financial flexibility assessment - Interest expense analysis - Competitive leverage comparison",
        internalControls:
          "Optimizing debt portfolio, strategic investments, balanced capital structure maintenance",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "relatively stable level of debt financing, but there may be opportunities to optimize the debt structure and improve financial efficiency",
        waysToAssessOrDiagnose:
          "- Profitability analysis - Interest rate impact evaluation - Peer benchmarking",
        internalControls:
          "Prudent financial management, investment in growth areas, maintaining healthy cash reserves",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation:
          "decreasing reliance on debt financing, reflecting efforts to improve financial stability and reduce financial risks",
        waysToAssessOrDiagnose:
          "- Capital adequacy analysis - Debt capacity evaluation - Growth potential assessment",
        internalControls:
          "Debt reduction strategies, exploring new financing options, proactive financial planning",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "gradual increase in debt financing, which should be carefully managed to maintain financial stability",
        waysToAssessOrDiagnose:
          "- Growth opportunity assessment - Leverage opportunity analysis - Return on investment evaluation",
        internalControls:
          "Capital expenditure planning, seeking low-interest financing, leveraging growth potential",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "relatively stable and conservative level of debt financing, reflecting a sound financial position",
        waysToAssessOrDiagnose:
          "- Competitive advantage analysis - Risk-return profiling - Financial strength assessment",
        internalControls:
          "Strategic asset allocation, maintaining liquidity, cautious borrowing",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation:
          "decreasing reliance on debt financing, reflecting efforts to maintain a conservative financial position",
        waysToAssessOrDiagnose:
          "- Market expansion feasibility - Balance sheet strength evaluation - Funding strategy review",
        internalControls:
          "Exploiting market opportunities, strategic partnerships, conservative debt management",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "slight increase in debt financing, potentially reflecting a strategic decision to leverage the business for growth or investment opportunities",
        waysToAssessOrDiagnose:
          "- Innovation potential assessment - Opportunity cost analysis - Agility evaluation",
        internalControls:
          "Investment in innovation, exploring alternative funding sources, agility in capital deployment",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistently low reliance on debt financing, highlighting a conservative financial approach",
        waysToAssessOrDiagnose:
          "- Strategic positioning - Market stability analysis - Resource optimization review",
        internalControls:
          "Long-term strategic planning, efficient capital utilization, risk management",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation:
          "decreasing reliance on debt financing, potentially reflecting efforts to further strengthen the financial position by reducing debt",
        waysToAssessOrDiagnose:
          "- Business sustainability analysis - Capital structure optimization - Market relevance evaluation",
        internalControls:
          "Focused growth strategy, leveraging competitive advantages, proactive market positioning",
      },
    ],
    timesInterestEarned: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        interpretation:
          "strong ability to cover interest expenses and indicates a healthy financial position",
        waysToAssessOrDiagnose:
          "Assess new investments and expansion opportunities. Diagnose efficiency in asset utilization.",
        internalControls:
          "Enhanced reporting on investment returns and capital allocation efficiency.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistently strong ability to cover interest expenses and maintain a healthy financial position",
        waysToAssessOrDiagnose:
          "Audit operational efficiency. Diagnose market saturation effects.",
        internalControls:
          "Regular review of cost management and pricing strategies.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining ability to cover interest expenses, potentially indicating increased financial risk",
        waysToAssessOrDiagnose:
          "Identify reasons for declining trend (e.g., increased competition, market saturation).",
        internalControls:
          "Tighten financial risk management and conduct frequent financial health checks.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        interpretation:
          "strengthening ability to cover interest expenses and reflects a solid financial position",
        waysToAssessOrDiagnose:
          "Review strategies for sustainable growth. Diagnose business diversification and risk management.",
        internalControls: "Monitor debt levels and interest coverage ratios.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        interpretation:
          "consistently strong ability to cover interest expenses and maintain a solid financial position",
        waysToAssessOrDiagnose:
          "Evaluate market position and competitive strategy. Diagnose revenue streams for diversification.",
        internalControls:
          "Regular profitability and operational efficiency assessments.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "declining ability to cover interest expenses, potentially indicating increased financial risk",
        waysToAssessOrDiagnose:
          "Analyze causes (e.g., increased expenses, market shifts).",
        internalControls:
          "Strengthen cost control measures and review interest expense management.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        interpretation:
          "improving ability to cover interest expenses and suggests a positive trajectory",
        waysToAssessOrDiagnose:
          "Focus on improving operational efficiencies. Diagnose cost structure and interest expense management.",
        internalControls:
          "Enhance monitoring of EBIT and interest payment trends.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "relatively stable ability to cover interest expenses, but there may be opportunities for improvement",
        waysToAssessOrDiagnose:
          "Maintain a cautious approach to expansion. Diagnose competitive positioning.",
        internalControls:
          "Regularly review liquidity and cash flow management.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        interpretation:
          "declining ability to cover interest expenses, potentially indicating increased financial risk",
        waysToAssessOrDiagnose:
          "Assess risk management and financial strategy. Diagnose operational inefficiencies.",
        internalControls:
          "Implement stringent financial controls and regular financial health assessments.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "improving ability to cover interest expenses but still requires attention to strengthen the financial position",
        waysToAssessOrDiagnose:
          "Diagnose operational turnaround strategies. Assess debt restructuring options.",
        internalControls:
          "Balance between effective collections and maintaining customer goodwill, regular review of customer feedback.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistently weak ability to cover interest expenses, highlighting potential financial risks",
        waysToAssessOrDiagnose:
          "Examine underlying issues in operational models. Diagnose cost reduction and revenue improvement areas.",
        internalControls:
          "Periodic review of credit terms and conditions, customer satisfaction analysis.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        interpretation:
          "deteriorating ability to cover interest expenses, indicating significant financial risks",
        waysToAssessOrDiagnose:
          "Immediate need to reassess business model. Diagnose the viability of continuing operations.",
        internalControls:
          "Continuous improvement of collection processes and regular training for accounts receivable staff.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "slight improvement in the ability to cover interest expenses, but the financial position still requires significant attention",
        waysToAssessOrDiagnose:
          "Identify emergency measures to avoid default. Diagnose liquidity issues and short-term survival strategies.",
        internalControls:
          "Daily monitoring of cash flow and stringent cost controls.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "consistently weak ability to cover interest expenses, highlighting significant financial risks",
        waysToAssessOrDiagnose:
          "Develop a restructuring plan. Diagnose areas for immediate improvement.",
        internalControls: "Frequent and detailed financial reporting.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        interpretation:
          "deteriorating ability to cover interest expenses, indicating severe financial risks",
        waysToAssessOrDiagnose:
          "Consider restructuring or liquidation. Diagnose business for potential turnaround or sale.",
        internalControls:
          "Crisis management protocols and regular updates to stakeholders.",
      },
    ],
    arDays: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "long time to collect payments from customers, which may impact cash flow and working capital.",
        waysToAssessOrDiagnose:
          "Assess credit policies and customer payment behaviors. Diagnose liquidity issues and cash flow management.",
        internalControls:
          "Tighten credit policies, enhance collection efforts, and regular review of outstanding accounts.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "prolonged collection period, affecting cash flow and working capital.",
        waysToAssessOrDiagnose:
          "Evaluate effectiveness of current credit and collection policies. Diagnose potential cash flow constraints.",
        internalControls:
          "Regular aging analysis and follow up on overdue accounts.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "improvement in collection efficiency, although the collection period remains high.",
        waysToAssessOrDiagnose:
          "Monitor for shifts in customer payment behaviors. Diagnose improvements in collection processes.",
        internalControls:
          "Maintain stringent credit controls and regular cash flow forecasting.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "prolonged collection period, impacting cash flow and working capital.",
        waysToAssessOrDiagnose:
          "Investigate increasing credit terms or customer payment delays. Diagnose impact on working capital and cash flow.",
        internalControls:
          "Enhance credit risk assessment and improve collection processes.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "prolonged collection period, affecting cash flow and working capital.",
        waysToAssessOrDiagnose:
          "Examine reasons for consistent but high DSO. Diagnose customer base and payment terms.",
        internalControls:
          "Regular monitoring of customer creditworthiness and payment history.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "improvement in collection efficiency, although the collection period remains high.",
        waysToAssessOrDiagnose:
          "Identify strategies contributing to improved collection. Diagnose customer payment trends.",
        internalControls:
          "Continuously review and adjust credit policies as needed.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "warning",
        interpretation:
          "growing collection period, requiring attention to maintain healthy cash flow.s",
        waysToAssessOrDiagnose:
          "Assess for any changes in customer mix or payment terms. Diagnose potential inefficiencies in invoicing processes.",
        internalControls:
          "Regular reviews of invoice accuracy and timeliness, and customer communication enhancement.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "relatively stable collection period, but there is room for improvement.",
        waysToAssessOrDiagnose:
          "Ensure alignment with industry norms. Diagnose the balance between maintaining customer relations and efficient collections.",
        internalControls:
          "Periodic benchmarking against industry standards and customer satisfaction surveys.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation: "improvement in collection efficiency.",
        waysToAssessOrDiagnose:
          "Evaluate the impact of recent changes in credit policies. Diagnose operational improvements.",
        internalControls:
          "Monitor effectiveness of recent policy changes and maintain proactive customer communication.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "shorter collection period, but it is gradually increasing, requiring attention to maintain efficiency.",
        waysToAssessOrDiagnose:
          "Investigate possible over-aggressive collection tactics. Diagnose customer relationship management.",
        internalControls:
          "Balance between effective collections and maintaining customer goodwill, regular review of customer feedback.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "relatively stable and shorter collection period, indicating efficient receivables management.",
        waysToAssessOrDiagnose:
          "Monitor for consistency with industry standards. Diagnose any risk of becoming too lenient to sustain low DSO.",
        internalControls:
          "Periodic review of credit terms and conditions, customer satisfaction analysis.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation: "improvement in collection efficiency.",
        waysToAssessOrDiagnose:
          "Identify best practices leading to efficient collections. Diagnose customer payment behavior.",
        internalControls:
          "Continuous improvement of collection processes and regular training for accounts receivable staff.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "exceptionally short collection period, but it is gradually increasing, requiring attention to maintain efficiency.",
        waysToAssessOrDiagnose:
          "Caution against too restrictive credit policies impacting sales. Diagnose potential loss of business due to strict terms.",
        internalControls:
          "Assess impact on customer relations and sales, consider customer feedback for policy adjustments.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "relatively stable and exceptionally short collection period, indicating highly efficient receivables management.",
        waysToAssessOrDiagnose:
          "Evaluate risk of loss in customer flexibility and satisfaction. Diagnose if extremely low DSO is sustainable.",
        internalControls:
          "Regularly review customer retention rates and sales trends.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation: "improvement in collection efficiency.",
        waysToAssessOrDiagnose:
          "- Business sustainability analysis - Capital structure optimization - Market relevance evaluation",
        internalControls:
          "Maintain effective collection practices while ensuring customer satisfaction remains high.",
      },
    ],
    dpo: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
        interpretation:
          "taking longer to pay its trade payables, potentially straining supplier relationships and affecting working capital.",
        waysToAssessOrDiagnose:
          "Assess liquidity management and cash flow strategies. Diagnose potential strain on supplier relationships.",
        internalControls:
          "Regular vendor payment reviews, supplier relationship management, and cash flow forecasting.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
        interpretation:
          "prolonged payment period, potentially impacting supplier relationships and working capital.",
        waysToAssessOrDiagnose:
          "Evaluate long-term sustainability of current payment practices. Diagnose risks of supplier dissatisfaction or penalties.",
        internalControls:
          "Consistent review of payment terms with suppliers and adherence to industry norms.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "improvement in payment efficiency, although the payment period remains high.",
        waysToAssessOrDiagnose:
          "Investigate reasons for quicker payments (e.g., early payment discounts). Diagnose potential impacts on working capital.",
        internalControls:
          "Analysis of payment term optimization and its effect on cash flow.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
        interpretation:
          "prolonged payment period, potentially affecting working capital and supplier relationships.",
        waysToAssessOrDiagnose:
          "Examine cash retention strategies and working capital management. Diagnose impact on business operations and supplier dynamics.",
        internalControls:
          "Regular audits of payable processes and supplier feedback collection.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
        interpretation:
          "relatively stable prolonged payment period, potentially affecting working capital and supplier relationships.",
        waysToAssessOrDiagnose:
          "Ensure balance between efficient cash use and maintaining good supplier relations. Diagnose for optimal payment terms.",
        internalControls:
          "Ongoing evaluation of payment practices against industry benchmarks and supplier terms.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "improvement in payment efficiency, although the payment period remains high.",
        waysToAssessOrDiagnose:
          "Assess changes in payment policies or cash availability. Diagnose impacts on operational efficiency.",
        internalControls:
          "Review of payment terms negotiation and assessment of cash management strategies.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
        interpretation:
          "growing payment period, requiring attention to maintain healthy working capital and supplier relationships.",
        waysToAssessOrDiagnose:
          "Explore opportunities to optimize working capital. Diagnose the balance between DPO and maintaining supplier relationships.",
        internalControls:
          "Regular working capital assessments and reviews of payment policy adherence.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "relatively stable payment period, but there is room for improvement to optimize working capital and supplier relationships.",
        waysToAssessOrDiagnose:
          "Maintain current payment strategies while monitoring industry trends. Diagnose efficiency in payment processes.",
        internalControls:
          "Continuous monitoring of industry payment trends and internal audit of payable processes.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
        interpretation:
          "improvement in payment efficiency, although the payment period remains moderate.",
        waysToAssessOrDiagnose:
          "Investigate improvements in cash flow management. Diagnose potential for taking advantage of payment term benefits.",
        internalControls:
          "Analysis of early payment discounts and supplier negotiations.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "shorter payment period, but it is gradually increasing, requiring attention to maintain efficiency and supplier relationships.",
        waysToAssessOrDiagnose:
          "Assess potential for improved cash flow management. Diagnose reasons for faster payments and potential cash constraints.",
        internalControls:
          "Detailed analysis of cash flow management and potential renegotiation of payment terms.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "relatively stable and shorter payment period, indicating efficient payment processing and positive supplier relationships.",
        waysToAssessOrDiagnose:
          "Evaluate if low DPO is beneficial for the company’s cash flow strategy. Diagnose potential missed opportunities in working capital optimization.",
        internalControls:
          "Regular review of cash management policies and potential benefits of extending payment terms.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
        interpretation:
          "improvement in payment efficiency, resulting in a shorter payment period.",
        waysToAssessOrDiagnose:
          "Identify reasons for further decrease in payment period. Diagnose implications for cash flow and operational efficiency.",
        internalControls:
          "Review of financial policies and impact assessment on overall financial health.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "exceptionally short payment period, but it is gradually increasing, requiring attention to maintain efficiency and positive supplier relationships.",
        waysToAssessOrDiagnose:
          "Examine necessity for immediate payment practices. Diagnose implications for cash reserves and potential for better terms.",
        internalControls:
          "Strategic review of payment policies and potential renegotiation for longer payment terms.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "relatively stable and exceptionally short payment period, indicating highly efficient payment processing and positive supplier relationships.",
        waysToAssessOrDiagnose:
          "Assess potential risks of cash crunch. Diagnose impact on business flexibility.",
        internalControls:
          "Ongoing monitoring of cash reserves and exploration of options to optimize payment terms.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
        interpretation:
          "improvement in payment efficiency, resulting in an exceptionally short payment period.",
        waysToAssessOrDiagnose:
          "Investigate urgency in supplier payments. Diagnose potential adverse effects on working capital.",
        internalControls:
          "Immediate review of cash flow management strategies and supplier contract terms.",
      },
    ],
    dii: [
      {
        situation: "Very High",
        trend: "rising",
        color: "danger",
        interpretation:
          "holding inventory for an extended period, potentially leading to increased carrying costs, obsolescence, and reduced cash flow",
        waysToAssessOrDiagnose:
          "Assess inventory management and supply chain inefficiencies. Diagnose potential overstocking and obsolescence issues.",
        internalControls:
          "Inventory audits, regular review of inventory turnover, and implementation of JIT (Just In Time) systems.",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "relatively stable but prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        waysToAssessOrDiagnose:
          "Evaluate the adequacy of current inventory levels. Diagnose potential market demand changes.",
        internalControls:
          "Periodic inventory analysis, forecasting accuracy checks, and market trend assessments.",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
        interpretation:
          "improvement in inventory management efficiency, although the holding period remains high",
        waysToAssessOrDiagnose:
          "Investigate reasons for rapid inventory turnover. Diagnose implications for supplier relationships and stock availability.",
        internalControls:
          "Supply chain efficiency reviews and assessment of reorder points and safety stock levels.",
      },
      {
        situation: "High",
        trend: "rising",
        color: "danger",
        interpretation:
          "prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        waysToAssessOrDiagnose:
          "Examine potential issues in demand forecasting and inventory planning. Diagnose potential cash flow constraints due to tied-up capital.",
        internalControls:
          "Enhanced inventory tracking systems, regular stock reviews, and improved demand forecasting methods.",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "danger",
        interpretation:
          "relatively stable but prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        waysToAssessOrDiagnose:
          "Monitor for alignment with industry benchmarks. Diagnose supply chain strategies.",
        internalControls:
          "Benchmarking against industry standards, supply chain optimization reviews, and inventory turnover analysis.",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
        interpretation:
          "relatively stable but prolonged inventory holding period, potentially leading to increased carrying costs and reduced cash flow",
        waysToAssessOrDiagnose:
          "Assess changes in sales strategies or improvements in inventory management. Diagnose impacts on cash flow and storage costs.",
        internalControls:
          "Evaluation of inventory management practices and alignment with sales strategies.",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "warning",
        interpretation:
          "growing inventory holding period, requiring attention to maintain optimal inventory levels and minimize carrying costs",
        waysToAssessOrDiagnose:
          "Assess for any changes in customer mix or payment terms. Diagnose potential inefficiencies in invoicing processes.",
        internalControls:
          "Regular review of inventory levels against sales forecasts and supply chain adjustments.",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
        interpretation:
          "relatively stable inventory holding period, but there is room for improvement to optimize inventory management",
        waysToAssessOrDiagnose:
          "Maintain current inventory strategies while monitoring market trends. Diagnose efficiency in inventory processes.",
        internalControls:
          "Continuous monitoring of inventory metrics, periodic process audits, and inventory turnover rate analysis.",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "success",
        interpretation:
          "improvement in inventory management efficiency, resulting in a shorter holding period",
        waysToAssessOrDiagnose:
          "Investigate improvements in inventory turnover. Diagnose potential for optimized supply chain management.",
        internalControls:
          "Review of supplier performance, analysis of inventory storage costs, and inventory process improvements.",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "shorter inventory holding period, but it is gradually increasing, requiring attention to maintain optimal inventory levels and minimize stockouts",
        waysToAssessOrDiagnose:
          "Assess potential for stockouts and lost sales opportunities. Diagnose just-in-time inventory strategies.",
        internalControls:
          "Analysis of stockout occurrences, supplier lead time assessments, and safety stock evaluations.",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "relatively stable and shorter inventory holding period, indicating efficient inventory management and potential agility in responding to customer demand",
        waysToAssessOrDiagnose:
          "Evaluate if low DII is beneficial for the company’s operations. Diagnose potential missed opportunities in inventory management.",
        internalControls:
          "Regular review of inventory procurement processes and sales demand forecasting.",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "success",
        interpretation:
          "continuous improvement in inventory management efficiency, resulting in a shorter holding period",
        waysToAssessOrDiagnose:
          "Identify reasons for further decrease in inventory holding period. Diagnose implications for supplier relations and order fulfillment.",
        internalControls:
          "Review of order fulfillment processes and assessment of inventory ordering policies.",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
        interpretation:
          "exceptionally short inventory holding period, but it is gradually increasing, requiring attention to maintain optimal inventory levels and prevent stockouts",
        waysToAssessOrDiagnose:
          "Examine the necessity for such rapid inventory turnover. Diagnose implications for production and order fulfillment.",
        internalControls:
          "Strategic review of inventory management policies, and assessment of production scheduling and capacity planning.",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "success",
        interpretation:
          "relatively stable and exceptionally short inventory holding period, indicating efficient inventory management and potential agility in responding to customer demand",
        waysToAssessOrDiagnose:
          "Assess potential risks of insufficient inventory and impact on sales. Diagnose impact on production cycles.",
        internalControls:
          "Continuous monitoring of production processes and inventory level optimization.",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "success",
        interpretation:
          "continuous improvement in inventory management efficiency, resulting in an exceptionally short holding period",
        waysToAssessOrDiagnose:
          "Investigate the urgency in inventory turnover. Diagnose potential adverse effects on production and sales.",
        internalControls:
          "Immediate review of production and inventory policies, and analysis of customer order patterns.",
      },
    ],
    roA: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
      },
    ],
    roE: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
      },
    ],
    roCE: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
      },
    ],
    revenueGrowth: [
      {
        situation: "Very High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "Very High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "High",
        trend: "rising",
        color: "success",
      },
      {
        situation: "High",
        trend: "stagnant",
        color: "success",
      },
      {
        situation: "High",
        trend: "falling",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "rising",
        color: "success",
      },
      {
        situation: "Moderate",
        trend: "stagnant",
        color: "warning",
      },
      {
        situation: "Moderate",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Low",
        trend: "falling",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "rising",
        color: "warning",
      },
      {
        situation: "Very Low",
        trend: "stagnant",
        color: "danger",
      },
      {
        situation: "Very Low",
        trend: "falling",
        color: "danger",
      },
    ],
  },
};
