import { useEffect, useRef } from 'react';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  Box,
  Flex,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import Navbar from "../../components/Header/Navbar";
import AgencyLeftSidebar from "../../components/AgencySidebar/AgencyLeftSidebar";
import { getRegisteredClientsData } from "../../actions/user/agencyActions";
import Footer from "../../components/Footer";
import { usePayment } from "../../contexts/PaymentContext";
import Cookies from "../../components/Cookies/Cookies";

const AgencyLayout2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isAgencyHomePage = location.pathname === "/agency";

  const { showPaymentModal } = usePayment();

  const { clientsData = [] } = useSelector((state) => state.agencyReducer);

  const { user } = useSelector((state) => state.userReducer);
  const clientDashboards = user?.clientDashboards;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const addClientButtonHandler = () => {
    if (clientDashboards?.availableDashboards >= 1) {
      navigate("/agency/client-new");
    } else {
      showPaymentModal();
    }
  };

  useEffect(() => {
    dispatch(getRegisteredClientsData());
    onOpen(true);
  }, [dispatch]);

  return (
    <Box>
      <Navbar />
      <Flex height={"calc(100vh - 90px)"}>
        <Box
          bg="#fff"
          // mt="50px"
          // display={{ base: 'block', lg: 'block' }}
          width={"54px"}
        >
          <Button
            ref={btnRef}
            className="sidebar-btn"
            display={{ base: "block", lg: "block" }}
            onClick={onOpen}
          >
            <HamburgerIcon fontSize={20} />
          </Button>
        </Box>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody bg="#E6E6E6">
              <AgencyLeftSidebar onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Box
          bg="#E6E6E6"
          mt="50px"
          display={{ base: "none", lg: "none" }}
          width={"20%"}
        >
          <AgencyLeftSidebar onClose={onClose} />
        </Box>
        <Box width={"95%"}>
          {clientsData.length < 1 && isAgencyHomePage && (
            <Stack
              w="100%"
              h="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Flex
                justifyContent={"space-evenly"}
                fontSize={"2xl"}
                /* h={"400px"}
                minH={"200px"} */
                alignItems={"center"}
                mt={"40px"}
                w="100%"
              >
                <Flex
                  w={"80%"}
                  h={"80%"}
                  alignItems={"center"}
                  textAlign={"center"}
                  justifyContent={"space-evenly"}
                  // borderRadius={"7px"}
                  // border={"2px dashed"}
                  // borderColor={"primary.600"}
                >
                  You do not have any client associated with your account
                </Flex>
              </Flex>
              <Flex justifyContent={"space-evenly"} justifySelf={"flex-end"}>
                <Flex flexDir={["column", "row"]} p={"30px 0"}>
                  <Box>
                    <Button
                      m={["7px 0", "0 7px"]}
                      variant={"solid"}
                      bg={"#0C543C"}
                      _hover={{}}
                      onClick={addClientButtonHandler}
                    >
                      Register new client
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Stack>
          )}
          <Outlet />
        </Box>
      </Flex>
      <Footer />
      <Cookies />
    </Box>
  );
};

export default AgencyLayout2;
