// import {
//   Button,
//   Flex,
//   Grid,
//   GridItem,
//   Heading,
//   Select,
//   Stack,
// } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import {
//   getCompetitiveMapData,
//   getTrendsForClient,
//   getZScoreForClient,
// } from "../../../actions/user/organisationActions";
// import Loader from "../../../components/Loader/Loader";
// import MetricsSpectrum from "./MetricsSpectrum/MetricsSpectrum";
// import PerformanceMatrix from "./PerformanceMatrix/PerformanceMatrix";
// import "./organisationHome.scss";
// import { regionalLanguageList } from "../../../utils/common";
// // import RecommendationTexts from "./ResourceMatrix/RecommendationTexts";
// // import { getRegisteredClientsData } from "../../actions/user/agencyActions";
// import CompetitiveMap from "./CompetitiveMap/CompetitiveMap";
// import SankeyChart from "./auditPreviewCharts/SankeyChart";
// import StackedBarChart from "../Dashboard/auditPreviewCharts/StackedBarChart";
// import ScatterChartComponent from "./CompetitiveMap/ScatterChart";
// // import RecommendationTexts from "./PerformanceMatrix/RecommendationTexts";
// // import CompetitiveMap from "./CompetitiveMap/CompetitiveMap";

// const OrganisationHome = () => {
//   const dispatch = useDispatch();

//   const [selectedresMatLang, setSelectedResMatLang] = useState("english");

//   const { loading, organisationData, competitiveMap } = useSelector(
//     (state) => state.organisationReducer
//   );
//   const { zScore,client, trend } = organisationData;
//   useEffect(() => {
//     // get data for metric spectrum chart of an organisation page
//     client.id && dispatch(getZScoreForClient(client.id));
//     client.id && dispatch(getTrendsForClient(client.id));
//   }, [dispatch,client.id]);

//   useEffect(() => {
//     if (organisationData?.client?.subsector) {
//       dispatch(getCompetitiveMapData(organisationData.client.subsector));
//     }
//   }, [dispatch, organisationData?.client?.subsector]);
//   const resMatLangChangeHandler = (e) => {
//     setSelectedResMatLang(e.target.value);
//   };
//   const { loading: organData, clientId } = useSelector((state) => state.organisationReducer.orgData);

//   return (
//     <>
//       <Stack
//         className="body-container"
//         p={["45px 7px", "45px 30px"]}
//         gap={[8, 14, 28]}
//         overflowY={"auto"}
//       >

// {organisationData?.client?.auditUploadYears.length > 0 ? (
//           <>
//             {/* <Stack
//               borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
//               p={"21px 0"}
//             >
//               <Heading size={"xl"} pb={"12px"}>
//                 Sankey Chart
//               </Heading>
//               <Grid
//                 templateColumns={[
//                   "1fr",
//                   "repeat(auto-fit, minmax(600px, 1fr))",
//                 ]}
//                 gap={6}
//                 overflowX={"auto"}
//               >
//                   {clientId.loading ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Flex
//                         justifyContent={"space-around"}
//                         alignItems={"center"}
//                       >
//                         <SankeyChart/>
//                       </Flex>
//                     </>
//                   )}
//               </Grid>
//             </Stack>
//             <Stack
//               borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
//               p={"21px 0"}
//             >
//               <Heading size={"xl"} pb={"12px"}>
//                 Waterfall Chart
//               </Heading>
//               <Grid
//                 templateColumns={[
//                   "1fr",
//                   "repeat(auto-fit, minmax(600px, 1fr))",
//                 ]}
//                 gap={6}
//                 overflowX={"auto"}
//               >
//                   {clientId.loading ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Flex
//                         justifyContent={"space-around"}
//                         alignItems={"center"}
//                       >
//                         <StackedBarChart/>
//                       </Flex>
//                     </>
//                   )}
//               </Grid>
//             </Stack> */}

//             <Stack
//               borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
//               p={"21px 0"}
//             >
//               <Heading size={"xl"} pb={"12px"}>
//                 Scatter Chart
//               </Heading>
//               <Grid
//                 templateColumns={[
//                   "1fr",
//                   "repeat(auto-fit, minmax(600px, 1fr))",
//                 ]}
//                 gap={6}
//                 overflowX={"auto"}
//               >
//                   {clientId.loading ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Flex
//                         justifyContent={"space-around"}
//                         alignItems={"center"}
//                       >
//                         <ScatterChartComponent/>
//                       </Flex>
//                     </>
//                   )}
//               </Grid>
//             </Stack>
//             {/* <Stack
//               borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
//               p={"21px 0"}
//             >
//               <Flex
//                 flexWrap={"wrap"}
//                 pb={"12px"}
//                 alignItems={"center"}
//                 justifyContent={"space-between"}
//               >
//                 <Heading size={"xl"} >Performance Metrics</Heading>
//                 <Select
//                   ml={"7px"}
//                   w={"xxs"}
//                   focusBorderColor={"primary.400"}
//                   color={"primary.600"}
//                   onChange={resMatLangChangeHandler}
//                 >
//                   {regionalLanguageList?.map((item, index) => (
//                     <option key={index} value={item.value}>
//                       {item.label}
//                     </option>
//                   ))}
//                 </Select>
//               </Flex>
//               <Grid
//                 templateColumns={[
//                   "1fr",
//                   "repeat(auto-fit, minmax(600px, 1fr))",
//                 ]}
//                 gap={6}
//                 overflowX={"auto"}
//               >
//                 <GridItem w={"100%"} minH={"225px"}>
//                   {loading || zScore?.loading || trend?.loading  ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Flex
//                         justifyContent={"space-around"}
//                         alignItems={"center"}
//                       >
//                         <PerformanceMatrix displayLanguage={selectedresMatLang} />
//                       </Flex>
//                     </>
//                   )}
//                 </GridItem>
//                 {/* <GridItem
//                   w={"100%"}
//                   minH={"225px"}
//                   p={"18px 0 18px 21px"}
//                   className="met-spec-text-cont"
//                 >
//                   {loading ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Stack
//                         border={"2px solid"}
//                         borderColor={"primary.200"}
//                         borderRadius={"12px"}
//                         p={"0 0 12px 12px"}
//                         minH={"400px"}
//                         h={"530px"}
//                       >
//                         <Heading
//                           textAlign={"center"}
//                           size={"lg"}
//                           p={"7px 0"}
//                           borderBottom={"1px solid rgba(125, 125, 125, 0.1)"}
//                         >
//                           Recommendation
//                         </Heading>
//                         <RecommendationTexts
//                           displayLanguage={selectedresMatLang}
//                         />
//                       </Stack>
//                     </>
//                   )}
//                 </GridItem> */}
//               {/* </Grid>
//             </Stack>  */}

//             {/* <Stack
//               borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
//               p={"21px 0"}
//             >
//               <Heading size={"xl"} pb={"12px"} >
//                 Metrics Spectrum
//               </Heading>
//               <Grid
//                 templateColumns={[
//                   "1fr",
//                   "repeat(auto-fit, minmax(600px, 1fr))",
//                 ]}
//                 gap={6}
//                 overflowX={"auto"}
//               >
//                 <GridItem w={"100%"} minH={"225px"}>
//                   {loading || zScore?.loading ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Flex
//                         justifyContent={"space-around"}
//                         alignItems={"center"}
//                       >
//                         <MetricsSpectrum />
//                       </Flex>
//                     </>
//                   )}
//                 </GridItem>
//                 <GridItem
//                   w={"100%"}
//                   minH={"225px"}
//                   className="met-spec-text-cont"
//                 >
//                   {loading ? (
//                     <Loader />
//                   ) : (
//                     <>
//                       <Stack textAlign={"center"} minH={"400px"}>
//                         <Heading>Text</Heading>
//                       </Stack>
//                     </>
//                   )}
//                 </GridItem>
//               </Grid>
//             </Stack> */}
//           </>
//         ) : (
//           <>
//             <Stack minH={"100px"} alignItems={"center"}>
//               <Heading
//                 color={"primary.500"}
//                 w={"full"}
//                 size={"md"}
//                 textAlign={"center"}
//               >
//                 You haven't uploaded your audit sheets as of now. Please upload
//                 and visit this page again.
//                 {organisationData?.client?.companyName};
//               </Heading>
//               <Flex pt={"18px"}>
//                 <Link to={`/auditsheet/uploadauditsheet`}>
//                   <Button variant={"outline"}>Upload</Button>
//                 </Link>
//               </Flex>
//             </Stack>
//           </>
//         )}
//       </Stack>
//     </>
//   );
// };

// export default OrganisationHome;
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAuditSheetDataForClient,
  getCompetitiveMapData,
  getCompetitiveMapDataForClient,
  getTrendsForClient,
  getZScoreForClient,
} from "../../../actions/user/organisationActions";
import Loader from "../../../components/Loader/Loader";
import MetricsSpectrum from "./MetricsSpectrum/MetricsSpectrum";
import PerformanceMatrix from "./PerformanceMatrix/PerformanceMatrix";
import "./organisationHome.scss";
import { regionalLanguageList } from "../../../utils/common";
import ScatterChartComponent from "./CompetitiveMap/ScatterChart";
import StackedBarChart from "./auditPreviewCharts/StackedBarChart";
// import RecommendationTexts from "./PerformanceMatrix/RecommendationTexts";
// import CompetitiveMap from "./CompetitiveMap/CompetitiveMap";

const OrganisationHome = () => {
  const dispatch = useDispatch();

  const [selectedresMatLang, setSelectedResMatLang] = useState("english");

  const { loading, organisationData, competitive } = useSelector(
    (state) => state.organisationReducer
  );
  const { zScore,client, trend, competitiveMap,auditsheet } = organisationData;

  useEffect(() => {
    // get data for metric spectrum chart of an organisation page
    client.id && dispatch(getZScoreForClient(client.id));
    client.id && dispatch(getTrendsForClient(client.id));
    client.id && dispatch(getCompetitiveMapDataForClient(client.id));
    client.id && dispatch(getAuditSheetDataForClient(client.id));
  }, [dispatch,client.id]);

  useEffect(() => {
    if (organisationData?.client?.subsector) {
      dispatch(getCompetitiveMapData(organisationData.client.subsector));
    }
  }, [dispatch, organisationData?.client?.subsector]);

  const resMatLangChangeHandler = (e) => {
    setSelectedResMatLang(e.target.value);
  };
  return (
    <>
      <Stack
        className="body-container"
        p={["45px 7px", "45px 30px"]}
        gap={[8, 14, 28]}
        overflowY={"auto"}
      >
        <Heading textAlign={"center"} textTransform={"capitalize"} size="xl">
          {organisationData?.client?.companyName}
        </Heading>

        {organisationData?.client?.auditUploadYears.length > 0 ? (
          <>
            <Stack
              borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
              p={"21px 0"}
            >
              <Heading size={"xl"} pb={"12px"}>
                Competitive Map
              </Heading>

              <Grid
                templateColumns={[
                  "1fr",
                  "repeat(auto-fit, minmax(600px, 1fr))",
                ]}
                gap={6}
                overflowX={"auto"}
              >
                <GridItem w={"100%"} minH={"225px"}>
                {loading || competitiveMap?.loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Flex
                        justifyContent={"space-around"}
                        alignItems={"center"}
                      >
                        <ScatterChartComponent/>
                      </Flex>
                    </>
                  )}
                 
                </GridItem>
                {/* <GridItem
                  w={"100%"}
                  minH={"225px"}
                  className="met-spec-text-cont"
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Stack textAlign={"center"} minH={"400px"}>
                        <Heading size={"lg"}>Text</Heading>
                      </Stack>
                    </>
                  )}
                </GridItem> */}
              </Grid>
            </Stack>
            <Stack
              borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
              p={"21px 0"}
            >
              <Heading size={"xl"} pb={"12px"}>
                WaterFall Chart
              </Heading>

              <Grid
                templateColumns={[
                  "1fr",
                  "repeat(auto-fit, minmax(600px, 1fr))",
                ]}
                gap={6}
                overflowX={"auto"}
              >
                <GridItem w={"100%"} minH={"225px"}>
                {loading || auditsheet?.loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Flex
                        justifyContent={"space-around"}
                        alignItems={"center"}
                      >
                        <StackedBarChart/>
                      </Flex>
                    </>
                  )}
                 
                </GridItem>
                {/* <GridItem
                  w={"100%"}
                  minH={"225px"}
                  className="met-spec-text-cont"
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Stack textAlign={"center"} minH={"400px"}>
                        <Heading size={"lg"}>Text</Heading>
                      </Stack>
                    </>
                  )}
                </GridItem> */}
              </Grid>
            </Stack>
            <Stack
              borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
              p={"21px 0"}
            >
              <Flex
                flexWrap={"wrap"}
                pb={"12px"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Heading size={"xl"} >Performance Metrics</Heading>
                <Select
                  ml={"7px"}
                  w={"xxs"}
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                  onChange={resMatLangChangeHandler}
                >
                  {regionalLanguageList?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Grid
                templateColumns={[
                  "1fr",
                  "repeat(auto-fit, minmax(600px, 1fr))",
                ]}
                gap={6}
                overflowX={"auto"}
              >
                <GridItem w={"100%"} minH={"225px"}>
                  {loading || zScore?.loading || trend?.loading  ? (
                    <Loader />
                  ) : (
                    <>
                      <Flex
                        justifyContent={"space-around"}
                        alignItems={"center"}
                      >
                        <PerformanceMatrix displayLanguage={selectedresMatLang} />
                      </Flex>
                    </>
                  )}
                </GridItem>
                {/* <GridItem
                  w={"100%"}
                  minH={"225px"}
                  p={"18px 0 18px 21px"}
                  className="met-spec-text-cont"
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Stack
                        border={"2px solid"}
                        borderColor={"primary.200"}
                        borderRadius={"12px"}
                        p={"0 0 12px 12px"}
                        minH={"400px"}
                        h={"530px"}
                      >
                        <Heading
                          textAlign={"center"}
                          size={"lg"}
                          p={"7px 0"}
                          borderBottom={"1px solid rgba(125, 125, 125, 0.1)"}
                        >
                          Recommendation
                        </Heading>
                        <RecommendationTexts
                          displayLanguage={selectedresMatLang}
                        />
                      </Stack>
                    </>
                  )}
                </GridItem> */}
              </Grid>
            </Stack> 

            <Stack
              borderBottom={"2px solid rgba(128, 128, 128, 0.3)"}
              p={"21px 0"}
            >
              <Heading size={"xl"} pb={"12px"} >
                Metrics Spectrum
              </Heading>
              <Grid
                templateColumns={[
                  "1fr",
                  "repeat(auto-fit, minmax(600px, 1fr))",
                ]}
                gap={6}
                overflowX={"auto"}
              >
                <GridItem w={"100%"} minH={"225px"}>
                  {loading || zScore?.loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Flex
                        justifyContent={"space-around"}
                        alignItems={"center"}
                      >
                        <MetricsSpectrum />
                      </Flex>
                    </>
                  )}
                </GridItem>
                <GridItem
                  w={"100%"}
                  minH={"225px"}
                  className="met-spec-text-cont"
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Stack textAlign={"center"} minH={"400px"}>
                        <Heading>Text</Heading>
                      </Stack>
                    </>
                  )}
                </GridItem>
              </Grid>
            </Stack>
          </>
        ) : (
          <>
            <Stack minH={"100px"} alignItems={"center"}>
              <Heading
                color={"primary.500"}
                w={"full"}
                size={"md"}
                textAlign={"center"}
              >
                You haven't uploaded your audit sheets as of now. Please upload
                and visit this page again.
              </Heading>
              <Flex pt={"18px"}>
                <Link to={`/auditsheet/uploadauditsheet`}>
                  <Button variant={"outline"}>Upload</Button>
                </Link>
              </Flex>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default OrganisationHome;