import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
  Grid,
  GridItem,
  Img,
  Heading,
  Stack,
  ListItem,
  List,
  Divider,
} from "@chakra-ui/react";
import bullet from "../../assets/images/bullet.png";

import Loader from "../../components/Loader/Loader";
import {
  getAuditSheetDataForClient,
  getCompetitiveMapData,
  getCompetitiveMapDataForClient,
  getTrendsForClient,
  getZScoreForClient,
} from "../../actions/user/organisationActions";
import ScatterChartComponent from "../../components/CompetitiveMap/ScatterChart";
import MetricsSpectrum from "../../components/MetricsSpectrum/MetricsSpectrum";
import PerformanceMatrix from "../../components/PerformanceMatrix/PerformanceMatrix";
import {
  COLORS,
  NUMERATOR_DENOMINATOR,
  TYPES_OF_METRICS,
} from "../../constants/metrics";

const PerformanceMatrixComponent = ({
  setCurrentType,
  setPatternData,
  currentMetric,
  metricColors,
  setMetricColors,
  setCurrentMetric,
}) => {
  const dispatch = useDispatch();

  const { loading, organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  const { zScore, client, trend } = organisationData;

  useEffect(() => {
    client.id && dispatch(getZScoreForClient(client.id));
    client.id && dispatch(getTrendsForClient(client.id));
    client.id && dispatch(getCompetitiveMapDataForClient(client.id));
    client.id && dispatch(getAuditSheetDataForClient(client.id));
  }, [dispatch, client.id]);

  return (
    <>
      {loading || zScore?.loading || trend?.loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <PerformanceMatrix
              setCurrentType={setCurrentType}
              currentMetric={currentMetric}
              setPatternData={setPatternData}
              metricColors={metricColors}
              setMetricColors={setMetricColors}
              setCurrentMetric={setCurrentMetric}
            />
          </Flex>
        </>
      )}
    </>
  );
};

const CompetitiveMapComponent = ({
  patternData,
  currentType,
  currentMetric,
  setPatternValue,
}) => {
  const dispatch = useDispatch();

  const { loading, organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  const { competitiveMap, client } = organisationData;

  useEffect(() => {
    if (organisationData?.client?.subsector) {
      dispatch(getCompetitiveMapData(organisationData.client.subsector));
    }
  }, [dispatch, organisationData?.client?.subsector]);

  useEffect(() => {
    client.id && dispatch(getCompetitiveMapDataForClient(client.id));
  }, []);

  return (
    <>
      {loading || competitiveMap?.loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <ScatterChartComponent
              patternData={patternData}
              currentType={currentType}
              currentMetric={currentMetric}
              setPatternValue={setPatternValue}
            />
          </Flex>
        </>
      )}
    </>
  );
};

const MetricsSpectrumComponent = ({
  metricColors,
  patternData,
  currentType,
  currentMetric,
}) => {
  const dispatch = useDispatch();

  const { loading, organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  const { zScore, client } = organisationData;

  useEffect(() => {
    client.id && dispatch(getZScoreForClient(client.id));
    client.id && dispatch(getTrendsForClient(client.id));
    client.id && dispatch(getCompetitiveMapDataForClient(client.id));
    client.id && dispatch(getAuditSheetDataForClient(client.id));
  }, [dispatch, client.id]);

  return (
    <>
      {loading || zScore?.loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <MetricsSpectrum
              currentMetric={currentMetric}
              metricColors={metricColors}
              patternData={patternData}
              currentType={currentType}
            />
          </Flex>
        </>
      )}
    </>
  );
};

const SMERiskAssessment = () => {
  const RIGHT_INTERPRETATIONS = [
    "align/maintain/manage/ensure/control",
    "focus/develop/invest/implement/conduct",
    "streamline/optimize/support",
    "enhance/improve/increase/srengthen/expand",
    "reduce/minimize/eliminate",
    "negotiate/restructure",
  ];

  const METRIC_BG_COLORS = ["#FFF0E2", "#EAF4EB", "#FFE6E6"];
  const METRIC_COLORS = ["#8E3401", "#0D3B14", "#591111"];

  const [currentType, setCurrentType] = useState(
    Object.keys(TYPES_OF_METRICS)?.[0]
  );

  const [metricColors, setMetricColors] = useState([]);

  const [currentMetric, setCurrentMetric] = useState(
    Object.values(TYPES_OF_METRICS)?.[0]?.value?.[0]?.label
  );

  const [patternData, setPatternData] = useState(null);

  const [patternValue, setPatternValue] = useState([]);

  const applyColorsToMetrics = (dataList) => {
    const coloredList = dataList.map((_, index) => {
      const colorIndex = index % METRIC_BG_COLORS.length;
      return {
        bgColor: METRIC_BG_COLORS[colorIndex],
        textColor: METRIC_COLORS[colorIndex],
      };
    });

    return coloredList;
  };

  const coloredDataList = applyColorsToMetrics(
    TYPES_OF_METRICS?.[currentType]?.value
  );

  return (
    <>
      <Box className="agency-content">
        <Stack className="body-container" p={["45px 7px", "45px 30px"]}>
          <Box overflowY={"auto"}>
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem colSpan={2}>
                <Box display="flex">
                  <Text
                    textTransform="uppercase"
                    color="#1E1E1E"
                    fontFamily={"Inter"}
                    fontWeight="semibold"
                    letterSpacing=".9px"
                  >
                    TYPE
                  </Text>
                  <Box display="flex" ml={10}>
                    {Object.keys(TYPES_OF_METRICS)?.map?.((key, index) => (
                      <Text
                        width="auto"
                        backgroundColor={
                          currentType === key ? "#5D5D5D" : "#fff"
                        }
                        ml={index === 0 ? 0 : 10}
                        color={currentType === key ? "#fff" : "#5D5D5D"}
                        fontFamily={"Inter"}
                        fontWeight="normal"
                        fontSize="14px"
                        px={5}
                        borderRadius={4}
                        boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                        cursor="pointer"
                        onClick={() => {
                          setCurrentType(key);
                          setCurrentMetric(
                            TYPES_OF_METRICS?.[key]?.value?.[0]?.label
                          );
                        }}
                      >
                        {TYPES_OF_METRICS?.[key]?.label}
                      </Text>
                    ))}
                  </Box>
                </Box>

                <Box display="flex" pt={3}>
                  <Text
                    textTransform="uppercase"
                    color="#1E1E1E"
                    fontFamily={"Inter"}
                    fontWeight="semibold"
                    letterSpacing=".9px"
                  >
                    Metric
                  </Text>
                  <Box
                    display="flex"
                    pt={3}
                    ml={5}
                    borderTop="3px solid #E6E6E6"
                  >
                    {TYPES_OF_METRICS?.[currentType]?.value?.map?.(
                      (val, index) => {
                        return (
                          <Text
                            width="auto"
                            backgroundColor={
                              currentMetric === val?.label
                                ? "#5D5D5D"
                                : metricColors?.[val?.label]?.bgColor ||
                                  coloredDataList?.[index]?.bgColor
                            }
                            ml={index === 0 ? 0 : 10}
                            color={
                              currentMetric === val?.label
                                ? "#fff"
                                : metricColors?.[val?.label]?.textColor ||
                                  coloredDataList?.[index]?.textColor
                            }
                            fontFamily={"Inter"}
                            fontWeight="semibold"
                            fontSize="16px"
                            px={5}
                            textTransform="uppercase"
                            borderRadius={4}
                            boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                            cursor="pointer"
                            onClick={() => {
                              setCurrentMetric(val.label);
                            }}
                          >
                            {val?.value}{" "}
                            {currentType === "marginAndCost" && "%"}
                          </Text>
                        );
                      }
                    )}
                  </Box>
                </Box>
              </GridItem>

              <GridItem fontSize={12} colSpan={1}>
                <Text>
                  <Text display="inline-block" fontWeight="bold">
                    Numerator:
                  </Text>{" "}
                  {NUMERATOR_DENOMINATOR?.[currentMetric]?.numerator}
                </Text>
                <Text>
                  <Text display="inline-block" fontWeight="bold">
                    Denominator:
                  </Text>{" "}
                  {NUMERATOR_DENOMINATOR?.[currentMetric]?.denominator}
                </Text>
              </GridItem>
            </Grid>
            <br />

            <Card boxShadow="0 0 5px 0 rgba(0,0,0,0.2)">
              <CardHeader display="flex" borderBottom="3px solid #F8F8F8">
                <Heading
                  color="#5D5D5D"
                  textTransform="uppercase"
                  fontWeight={600}
                  fontSize={"18px"}
                  letterSpacing={"0.9px"}
                >
                  Current Relative Pattern
                </Heading>
                <Text
                  width="auto"
                  backgroundColor={
                    COLORS[patternData?.interpretation?.color]?.bgColor ||
                    "#EAF4EB"
                  }
                  ml={10}
                  color={
                    COLORS[patternData?.interpretation?.color]?.textColor ||
                    "#0D3B14"
                  }
                  fontFamily={"Inter"}
                  fontWeight="semibold"
                  fontSize="16px"
                  px={5}
                  borderRadius={1}
                  letterSpacing={"0.8px"}
                  boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                >
                  <Text display="inline-block" me={4}>
                    {(patternData?.pattern || "").replace(
                      /\b\w/g,
                      function (match) {
                        return match.toUpperCase();
                      }
                    )}
                  </Text>
                  <Text display="inline-block">
                    {patternValue?.metricValue}
                  </Text>
                </Text>
              </CardHeader>

              {currentType === "growthAndReturns" ? (
                ""
              ) : (
                <CardBody>
                  <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                    <GridItem colSpan={2} borderRight="3px solid #F8F8F8">
                      <Heading
                        color="#5D5D5D"
                        textTransform="uppercase"
                        fontWeight={600}
                        fontSize={"18px"}
                        letterSpacing={"0.9px"}
                      >
                        Ways To Assess Or Diagnose
                      </Heading>
                      <List spacing={3} mt="5">
                        <ListItem
                          color="#1E1E1E"
                          fontWeight="400"
                          letterSpacing="0.8px"
                          display="flex"
                          alignItems="center"
                        >
                          <Img src={bullet} mr={2} />
                          {patternData?.interpretation
                            ?.waysToAssessOrDiagnose || ""}
                        </ListItem>
                      </List>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Heading
                        color="#5D5D5D"
                        textTransform="uppercase"
                        fontWeight={600}
                        fontSize={"18px"}
                        letterSpacing={"0.9px"}
                      >
                        Internal Controls
                      </Heading>
                      <List spacing={3} mt="5">
                        <ListItem
                          color="#1E1E1E"
                          fontWeight="400"
                          letterSpacing="0.8px"
                          display="flex"
                          alignItems="center"
                        >
                          <Img src={bullet} mr={2} />
                          {patternData?.interpretation?.internalControls || ""}
                        </ListItem>
                        {RIGHT_INTERPRETATIONS.map((intrPret) => {
                          const interpretation =
                            patternData?.interpretation
                              ?.BusinessCapabilityRecommendation?.[intrPret];

                          if (!interpretation) return "";

                          return (
                            <ListItem
                              color="#1E1E1E"
                              fontWeight="400"
                              letterSpacing="0.8px"
                              display="flex"
                              alignItems="center"
                            >
                              <Img src={bullet} mr={2} />
                              {intrPret}: {interpretation}
                            </ListItem>
                          );
                        })}
                      </List>
                    </GridItem>
                  </Grid>
                </CardBody>
              )}
            </Card>

            <Box>
              <Grid
                mt={3}
                templateRows="repeat(1, 1fr)"
                templateColumns="repeat(2, 1fr)"
                gap={6}
              >
                <GridItem colSpan={1}>
                  <Box py={5} pl={3} pr={3}>
                    <Text
                      textTransform="uppercase"
                      fontWeight={600}
                      fontSize={18}
                    >
                      Patterns
                    </Text>
                    <PerformanceMatrixComponent
                      setCurrentType={setCurrentType}
                      currentMetric={currentMetric}
                      setPatternData={setPatternData}
                      setMetricColors={setMetricColors}
                      metricColors={metricColors}
                      setCurrentMetric={setCurrentMetric}
                    />
                  </Box>
                </GridItem>
                <GridItem p={8} colSpan={1} borderLeft="3px solid #F8F8F8">
                  <Box
                    py={3}
                    px={5}
                    borderRadius={8}
                    boxShadow="0 0 5px 0 rgba(0,0,0,0.2)"
                  >
                    <CompetitiveMapComponent
                      patternData={patternData}
                      currentType={currentType}
                      currentMetric={currentMetric}
                      setPatternValue={setPatternValue}
                    />
                  </Box>
                </GridItem>
              </Grid>

              <Divider />

              <Grid
                mt={5}
                templateRows="repeat(1, 1fr)"
                templateColumns="repeat(2, 1fr)"
                gap={6}
              >
                <GridItem p={5} colSpan={1}>
                  <MetricsSpectrumComponent
                    currentMetric={currentMetric}
                    patternData={patternData}
                    currentType={currentType}
                    metricColors={metricColors}
                  />
                </GridItem>
                <GridItem colSpan={1}></GridItem>
              </Grid>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default SMERiskAssessment;
