import {
  BALANCE_SHEET,
  PROFIT_LOSS_STATEMENT,
} from "../../utils/Stringconstant";

export const balanceSheetMetrics = [
  "ASSETS",
  "NON-CURRENT ASSETS",
  "Property, Plant and Equipment",
  "Right of use assets",
  "Capital Work-in-Progress",
  "Investment Properties",
  "Goodwill",
  "Other Intangible Assets",
  "Intangible Assets Under Development",
  "Biological Assets Other Than Bearer Plants",
  null,
  "Financial Assets",
  "Investments",
  "Trade receivables",
  "Loans",
  "Other Financial Assets",
  null,
  "Deferred Tax Assets (Net)",
  "Other Non-current Assets",
  null,
  "SUBTOTAL",
  null,
  "CURRENT ASSETS",
  "Inventories",
  "Financial Assets",
  "Investments",
  "Trade Receivables",
  "Cash and Cash Equivalents",
  "Bank Balances other than (32)",
  "Loans",
  "Other Financial Assets",
  null,
  "Current Tax Assets (Net)",
  "Other Current Assets",
  null,
  "SUBTOTAL",
  null,
  "Non-Current Assets Classified as Held for Sale",
  null,
  "TOTAL ASSETS",
  null,
  null,
  null,
  "EQUITY AND LIABILITIES",
  "EQUITY",
  "Equity share capital",
  "Other equity",
  "TOTAL EQUITY",
  null,
  "LIABILITIES",
  "NON-CURRENT LIABILITIES",
  "Financial liabilities",
  "Borrowings",
  "Trade Payable",
  "Other financial liabilities",
  null,
  "Provisions",
  "Deferred tax liabilities (net)",
  "Other non current liabilities",
  null,
  "TOTAL NON-CURRENT LIABILITIES",
  null,
  "CURRENT LIABILITIES",
  "Financial liabilities",
  "Borrowings",
  "Trade payables",
  "Other financial liabilities",
  null,
  "Provisions",
  "Other current liabilities",
  "Current tax liabilities (net)",
  null,
  "TOTAL CURRENT LIABILITIES",
  null,
  "TOTAL EQUITY AND LIABILITIES"
];

export const IncomeStatMetrics = [
  null,
  "Continuing Operations",
  "Revenue from operations",
  "Other Income",
  "Total Revenue (4+5)",
  null,
  "EXPENSES",
  "Cost of materials consumed",
  "Purchases of Stock-in-trade",
  "Changes in stock of finished goods, work-in-\nprogress and stock-in-trade",
  "Employee benefit expense",
  "Finance costs",
  "Depreciation and amortisation expense",
  "Impairment expenses/losses",
  "Other expenses",
  "Total Expenses",
  null,
  "Profit/(loss) before exceptional\nitems and tax (6 - 17)",
  "Exceptional Items",
  "Profit/(loss) before tax (19 - 20)",
  null,
  "Tax Expense",
  "Current tax",
  "Deferred tax",
  "Total tax expense",
  null,
  "Profit/(loss) after tax from\ncontinuing operations (21 - 26)",
  null,
  "Discontinued Operations",
  "Profit/(loss) from discontinued operations",
  "Tax Expense of discontinued operations",
  null,
  "Profit/(loss) after tax from\ndiscontinued operations",
  "Profit/(loss) for the period (28 + 34)",
  null,
  "Other comprehensive income",
  "Items that will not be reclassified\nto profit or loss",
  "Changes in revaluation surplus",
  "Remeasurements of the defined\nbenefit liabilities / (asset)",
  "Equity instruments through other\ncomprehensive income",
  "Fair value changes relating to own\ncredit risk",
  "Others (Change if non-zero)",
  "Income tax relating to items that will\nnot be reclassified to profit or loss",
  null,
  "Items that may be reclassified\n to profit or loss",
  "Exchange differences in translating\nthe financial statements of foreign\noperations",
  "Debt instruments through other\ncomprehensive income",
  "Effective portion of gains and loss\non designated portion of hedging\ninstruments in a cash flow hedge",
  "Share of other comprehensive income\nof equity accounted investees",
  "Others (Change if non-zero)",
  null,
  "Income tax on items that may be\nreclassified to profit or loss",
  null,
  "Total comprehensive income\nfor the period (35 + 37)",
  null,
  "Earnings per equity share\n(for continuing operation)",
  "Basic",
  "Diluted",
  null,
  "Earnings per equity share\n(for discontinued operation)",
  "Basic",
  "Diluted",
  null,
  "Earnings per equity share\n(for continuing and\ndiscontinued operations)",
  "Basic",
  "Diluted"
];

const noOfRows = {
  balanceSheet: 76,
  prsSheet: 67,
};

const auditHeader = {
  balanceSheet: "Standalone Balance Sheet",
  prsSheet: "Statement of Standalone Profit and Loss",
};

export const sheetConfig = {
  [BALANCE_SHEET]: {
    referenceMetrics: balanceSheetMetrics,
    sheetRows: noOfRows.balanceSheet,
    tableHeader: auditHeader.balanceSheet,
  },
  [PROFIT_LOSS_STATEMENT]: {
    referenceMetrics: IncomeStatMetrics,
    sheetRows: noOfRows.prsSheet,
    tableHeader: auditHeader.prsSheet,
  },
};

