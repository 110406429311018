import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Link as ReactRouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  VStack,
  Collapse,
  Link as ChakraLink,
  Button,
  Tooltip,
} from "@chakra-ui/react";

import { setCurrentClient } from "../../actions/client/clientActions";
import { setCurrentIndustry } from "../../actions/industry/industryActions";
import { getPopularSectorsResult } from "../../actions/home/homeActions";

import { usePayment } from "../../contexts/PaymentContext";
import { INDUSTRY_INTERVIEW_AGENCY } from "../../constants/agency";
import { useSectorsContext } from "../../customHooks/useSectorsContext";
import { toast } from "react-toastify";

const AgencySidebar = (props) => {
  const { sectorsData } = useSectorsContext();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const [page_location, setPageLocation] = useState("");
  const { showPaymentModal } = usePayment(); 
  const { industryId } = useSelector((state) => state.industryReducer);
  // const { clientsData } = useSelector((state) => state.agencyReducer);
  const { user, isAuthenticatedUser } = useSelector(
    (state) => state.userReducer
  );
  // const clientDashboards = user?.clientDashboards;

  const [isOpen, setIsOpen] = useState(true);
  const [currentIndustryId, setCurrentIndustryId] = useState();
  // const { popularSectors } = useSelector((state) => state.homeReducer);
  // const [sectorsData, setSectorsData] = useState([]);

  useEffect(() => {
    setPageLocation(location.pathname);
  }, []);

  useEffect(() => {
    if (sectorsData.length > 0) setCurrentIndustryId(sectorsData[0].id);
  }, [sectorsData]);

  // useEffect(() => {
  //   dispatch(getPopularSectorsResult());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (popularSectors?.data && popularSectors.data.length > 0) {
  //     var sortedSectors = popularSectors.data
  //       ?.slice()
  //       .sort((a, b) => b.totalClients - a.totalClients);
  //     setSectorsData(sortedSectors);
  //     dispatch(
  //       setCurrentIndustry({
  //         industryId: sortedSectors[0].id,
  //         sectorName: sortedSectors[0].sector,
  //         totalClients: sortedSectors[0].totalClients,
  //       })
  //     );
  //   }
  // }, [popularSectors.data, dispatch]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };  

  const handleIndustryClick = (sector) => {
    console.log("====sector", isAuthenticatedUser, sector);
    if (isAuthenticatedUser) {
      setCurrentIndustryId(sector.id);
    } else {
      setIndustry({
        industryId: sector.id,
        sectorName: sector.sector,
        totalClients: sector.totalClients,
      });
    }
  };

  const addClientButtonHandler = (event) => {
    toast.error("You will recieve recieve update shortly", {
      position: "top-center",
    });
    // event.stopPropagation();

    // if (clientDashboards?.availableDashboards >= 1) {
    //   navigation("/agency/client-new");
    // } else {
    //   showPaymentModal();
    // }
  };

  const openPaymentModalButtonHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    console.log("=====props.onClose", props.onClose);
    if (props.onClose) props.onClose();
    showPaymentModal();
  };

  const setIndustry = (industry) => {
    console.log("====setIndustry", isAuthenticatedUser, industry);
    if (props.onClose) props.onClose();
    dispatch(setCurrentIndustry(industry));
    if (!isAuthenticatedUser) {
    if (props.onClose) props.onClose();
      dispatch(setCurrentIndustry(industry));      
      navigation(`/industry/${industry.industryId}/dashboard`);
    }
    // if (isAuthenticatedUser) {
    //   navigation(`/agency/industry/${industry.industryId}/industry-activity`);
    // } else {
    //   navigation(`/industry/${industry.industryId}/dashboard`);
    // }
  };
  const setClientId = (sector) => {
    if (props.onClose) props.onClose();
    dispatch(setCurrentClient(sector.clientId));
    setIndustry({
      industryId: sector.id,
      sectorName: sector.sector,
      totalClients: sector.totalClients,
    });
    navigation(`/agency/client/${sector.clientId}/risk-assessment`);
  };

  const OnClickToOrganisationHome = (client) => {
    const data = { client: { ...client } };
    dispatch({
      type: "GET_ORG_DETAILS_SUCCESS",
      payload: data,
    });
  };

  return (
    <>
      <Box px={5}>
        <VStack align="start" className="left-menu" spacing="2">
          {INDUSTRY_INTERVIEW_AGENCY.email === user?.email && (
            <ChakraLink
              py="3"
              width="100%"
              fontWeight="600"
              borderBottom="2px"
              borderColor="white"
              mt="0"
              as={ReactRouterLink}
              to={`/${user?.id}/industry-leader-interviews`}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display="inline-block">Industry Leader Interviews</Box>
              </Box>
            </ChakraLink>
          )}

          {!isAuthenticatedUser && (
            <ChakraLink
              py="3"
              width="100%"
              fontWeight="600"
              borderBottom="2px"
              borderColor="white"
              mt="0"
              as={ReactRouterLink}
              to="https://beta.sylvr.co.in"
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display="inline-block">Home</Box>
              </Box>
            </ChakraLink>
          )}

          <ChakraLink
            py="3"
            width="100%"
            fontWeight="600"
            borderBottom="2px"
            borderColor="white"
            mt="0"
            as={ReactRouterLink}
            to={
              isAuthenticatedUser
                ? "/agency/practice-dashboard"
                : "/practice-dashboard"
            }
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display="inline-block">Practice Dashboard</Box>
              {isAuthenticatedUser && (
                <Button
                  mr={2}
                  size="xs"
                  onClick={openPaymentModalButtonHandler}
                >
                  +
                </Button>
              )}
            </Box>
          </ChakraLink>

          {page_location === "/practice-dashboard" ? (
            <ChakraLink
              py="3"
              width="100%"
              fontWeight="600"
              borderBottom="2px"
              borderColor="white"
              mt="0"
              as={Link}
              to={"/dashboard"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display="inline-block">Industries</Box>
              </Box>
            </ChakraLink>
          ) : (
          <Box
            py="3"
            width="100%"
            mt="0"
            fontWeight="600"
            borderBottom="2px"
            borderColor="white"
          >
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              onClick={toggleSidebar}
            >
                {"Industries"}
              <Box>
                {isAuthenticatedUser && (
                  <Button mr={2} size="xs" onClick={addClientButtonHandler}>
                    +
                  </Button>
                )}
                {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              </Box>
            </Box>
            <Collapse in={isOpen}>
                <VStack
                  pl="4"
                  align="start"
                  fontSize={"14px"}
                  maxHeight={"calc(100vh - 170px)"}
                  overflow={"auto"}
                            >
                  {sectorsData &&
                    sectorsData.map((sector, index) => {
                      return (
                        <>
                        <Box
                            w={"100%"}
                            bgColor={sector.id === industryId ? "#B1B1B1" : ""}
                            p={"3px"}
                            key={`sector-${sector.id}`}
                          >
                            {isAuthenticatedUser ? (
                              <Box
                                style={{ cursor: "pointer" }}
                                onClick={() => handleIndustryClick(sector)}
                        >
                                <Tooltip label={sector.sector} fontSize="11px">
                                  {sector?.sector && sector?.sector.length > 25
                                    ? (sector?.sector).substring(0, 25) + "..."
                                    : sector?.sector}
                                </Tooltip>
                              </Box>
                            ) : (
                          <ChakraLink
                            pt={4}
                            key={sector.id}
                                onClick={() => handleIndustryClick(sector)}
                          >
                            <Tooltip label={sector.sector} fontSize="11px">
                              {sector?.sector && sector?.sector.length > 25
                                    ? (sector?.sector).substring(0, 25) + "..."
                                : sector?.sector}
                            </Tooltip>
                          </ChakraLink>
                            )}
                          </Box>
                          {isAuthenticatedUser && (
                            <Box
                              p="0px 0px 0px 10px"
                              style={{
                                display:
                                  currentIndustryId === sector.id
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {sector?.clients &&
                                sector.clients.map((client, index) => {
                                  return (
                                    <Box>
                                      <ChakraLink
                                        fontSize={"12px"}
                                        pt={4}
                                        key={client._id}
                                        onClick={() =>
                                          setClientId({
                                            ...sector,
                                            clientId: client._id,
                                          })
                                        }
                                      >
                                        {client.companyName}
                                      </ChakraLink>
                        </Box>
                      );
                    })}
                            </Box>
                          )}
                        </>
                      );
                    })}
              </VStack>
            </Collapse>
          </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default AgencySidebar;
