import {
  Text,
  VStack
} from '@chakra-ui/react';
import './LeftSideNav.scss'

const LeftSideNav = () => {
  return (
    <VStack className="left-side-nav"></VStack>
  );
};

export default LeftSideNav;
