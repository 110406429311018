import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Tabs,
  ButtonGroup,
  Button,
  TabList,
  Tab,
  Text,
  Heading,
  Link as ChakraLink,
  Flex,
  Img,
} from "@chakra-ui/react";
import consultExpert from "../../assets/images/consult-expert.png";
import { InfoIcon } from "@chakra-ui/icons";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import YearSelection from "../../components/YearSelection";
import { getClientDetailsAPI } from "../../api/agencyApi";
const AgencyTabs = () => {
  const navigate = useNavigate();

  const { clientId } = useParams();

  const [tabIndex, setTabIndex] = useState(2);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setTabIndex(2);
  }, [clientId]);

  return (
    <Tabs
      className="main-agency-tab"
      variant="enclosed"
      colorScheme="teal"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <Flex justify="space-between" align="center">
        <TabList>
          <Tab
            mr={5}
            onClick={() => {
              navigate(`/agency/client/${clientId}/client-background`);
            }}
          >
            SME Profile
          </Tab>
          <Tab
            mr={5}
            onClick={() => {
              navigate(`/agency/client/${clientId}/industry-activity`);
            }}
          >
            Segment Risks
          </Tab>
          <Tab
            onClick={() => {
              navigate(`/agency/client/${clientId}/risk-assessment`);
            }}
          >
            Competitive Risks
          </Tab>
        </TabList>
        <Box>
          {tabIndex === 0 && (
            <ChakraLink
              href="https://beta.sylvr.co.in/blogs/financial-statements-analysis-to-improve-business-complete-framework"
              target="_blank"
              color="#30d5c8"
              _hover={{ color: "#41e6d9" }}
              fontWeight="bold"
            >
              Understanding Financial Statements analysis to improve business{" "}
              <InfoIcon />
            </ChakraLink>
          )}
          {tabIndex === 1 && (
            <ChakraLink
              href="https://beta.sylvr.co.in/blogs/how-to-create-a-sense-making-performance-improvement-template-for-sme-leaders"
              target="_blank"
              color="#30d5c8"
              _hover={{ color: "#41e6d9" }}
              fontWeight="bold"
            >
              SMEs Strategic Decision Making: Sense-Making for Better
              Performance <InfoIcon />
            </ChakraLink>
          )}
          {tabIndex === 2 && (
            <ChakraLink
              href="https://beta.sylvr.co.in/blogs/master-financial-risk-management"
              target="_blank"
              color="#30d5c8"
              _hover={{ color: "#41e6d9" }}
              fontWeight="bold"
            >
              How to Master Financial Risk Management without Losing Sleep{" "}
              <InfoIcon />
            </ChakraLink>
          )}
        </Box>
      </Flex>
    </Tabs>
  );
};

const ManageClientLayout = () => {
  const { clientId } = useParams();
  const location = useLocation();
  const [clientData, setclientData] =useState({});
  const [page_location, setPageLocation] = useState("");

  useEffect(() => {
    let pathname = location.pathname.split("/");
    setPageLocation(pathname[pathname.length - 1]);
  }, [location.pathname]);

  useEffect(() => {
    if(clientId){
      getClientDetailsAPI({clientId}).then((results) => {
        setclientData(results?.clientDetails);
      });
    }
  }, clientId);
  console.log("===page_location", page_location);
  return (
    <Box
      height={"calc(100% - 0px)"}
      width={{ base: "100%" }}
      px={5}
      backgroundColor="#fff"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {page_location === "industry-activity" ? (
            <Heading
              fontFamily={"Inter"}
              fontSize={"24px"}
              pt={5}
              pb={18}
              pr={6}
            >
              {clientData?.sector}
            </Heading>
          ) : (
            <>
              <Heading
                fontFamily={"Inter"}
                fontSize={"24px"}
                pt={5}
                pb={18}
                pr={6}
              >
            {clientData?.companyName}
          </Heading>
          <Text
            fontSize={"18px"}
            color={"#5D5D5D"}
            fontStyle="italic"
            fontWeight="semibold"
            borderLeft="3px solid #E6E6E6"
            borderRight="3px solid #E6E6E6"
            px={6}
            fontFamily={"Inter"}
          >
            {clientData?.sector}
          </Text>
            </>
          )}

          {/* <Text
            fontSize={"16px"}
            textTransform="uppercase"
            color={"#8F8F8F"}
            fontWeight="semibold"
            letterSpacing="0.8px"
            pl={6}
            display={"flex"}
            alignItems="flex-end"
            fontFamily={"Inter"}
          >
            <Text fontSize="20px" mb="-2px">
              F
            </Text>
            inancial{" "}
            <Text fontSize="20px" ml={6} mb="-2px">
              Y
            </Text>
            ear{" "}
            <Text fontSize="20px" mb="-2px" ml={6}>
              A
            </Text>
            nalyzed
          </Text> */}
          {/* <YearSelection
            years={clientData?.auditUploadYears || []}
            onSelectYear={() => {}}
            selectedYear={null}
          /> */}
        </Box>
        {/* <Button colorScheme="blackAlpha" fontSize="14px">
          <Img src={consultExpert} mr={2} />
          Consult An Expert
        </Button> */}
      </Box>
      <AgencyTabs />
      <Outlet />
    </Box>
  );
};

export default ManageClientLayout;
