import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Flex, Link, Text } from "@chakra-ui/react";

import CreateClient from "./CreateClient";
import PastPerformance from "./PastPerformance";
import SMEAuditSheetUploader from "./SMEAuditSheetUploader";

const CLIENT_BACKGROUND_TABS = {
  COMPANY: "COMPANY",
  UPLOAD_STATEMENT: "UPLOAD_STATEMENT",
  VIEW_PAST_PERFORMANCE: "VIEW_PAST_PERFORMANCE",
};

const ClientBackgroundLinks = ({ changeTab, clientData }) => {
  return (
    <Box ml={{ base: "4", md: "8" }} mt={{ base: "5", md: "10" }} mb={3}>
      <Box
        as={Link}
        fontWeight="bold"
        pr={{ base: "3", md: "6" }}
        borderRight="2px"
        color="primary.500"
        borderRightColor="primary.700"
        onClick={() => changeTab(CLIENT_BACKGROUND_TABS.COMPANY)}
        /* style={{ pointerEvents: clientId ? "none" : "auto" }} */
      >
        Company
      </Box>

      <Box
        as={Link}
        fontWeight="bold"
        pr={{ base: "3", md: "6" }}
        pl={{ base: "3", md: "6" }}
        borderRight="2px"
        color="primary.500"
        borderRightColor="primary.700"
        onClick={() => changeTab(CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT)}
        /* style={{
          pointerEvents:
            clientData?.auditUploadYears?.length > 0 ? "none" : "auto",
        }} */
      >
        Upload Statement
      </Box>

      <Box
        as={Link}
        fontWeight="bold"
        pr={{ base: "3", md: "6" }}
        pl={{ base: "3", md: "6" }}
        color="primary.500"
        onClick={() => changeTab(CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE)}
      >
        View Past Performance
      </Box>
    </Box>
  );
};

const TabContent = ({ tab, clientData }) => {
  switch (tab) {
    case CLIENT_BACKGROUND_TABS.COMPANY:
      return <CreateClient clientData={clientData} />;
    case CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT:
      return <SMEAuditSheetUploader clientData={clientData} />;
    case CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE:
      return <PastPerformance />;
  }
};

const SMEBackground = () => {
  const { clientId } = useParams();

  const { user } = useSelector((state) => state.userReducer);

  const [currentTab, setCurrentTab] = useState(CLIENT_BACKGROUND_TABS.COMPANY);

  const changeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    if (user && user?.auditUploadYears?.length > 0)
      setCurrentTab(CLIENT_BACKGROUND_TABS.VIEW_PAST_PERFORMANCE);
    else setCurrentTab(CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT);
  }, [clientId, user]);

  return (
    <Flex
      flexDirection={{ base: "column-reverse", md: "row" }}
      className="agency-content overflow-auto"
    >
      <Box width="100%">
        <ClientBackgroundLinks
          currentTab={currentTab}
          changeTab={changeTab}
          clientData={user}
        />
        <TabContent tab={currentTab} clientData={user} />
      </Box>
    </Flex>
  );
};

export default SMEBackground;
