import { useEffect, useState } from "react";

import { Box, Button, ButtonGroup, HStack, Text, VStack } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function YearSelection({ years, selectedYear, onSelectYear }) {
  const [startIndex, setStartIndex] = useState(0);

  const handleNext = () => {
    setStartIndex((prevIndex) => Math.min(prevIndex + 3, years.length - 3));
  };

  const handlePrev = () => {
    if (startIndex === 0) return;
    setStartIndex((prevIndex) => Math.max(prevIndex - 3, 0));
  };
  useEffect(() => {
    setStartIndex(years.length - 3);
  }, [years]);
  return (
    <>

    <VStack>
    <Text
        w={'100%'}
        fontSize="12px"
        color="#1E1E1E"
        fontWeight={600}
        textTransform="uppercase"
        letterSpacing="1px"
        textColor={'#162421'}
      >
        Select Year
      </Text>

      <Box display="flex" ml={6}>
        <ChevronLeftIcon cursor="pointer" onClick={handlePrev} />

        <ButtonGroup gap="2" mx={4}>
          {years.slice(startIndex, startIndex + 3).map((year) => (
            <Button
              backgroundColor={selectedYear === year ? "#0C543C" : "#DBE8E8"}
              width="57px"
              height="18px"
              color={selectedYear === year ? "#F8FDFD" : "#4E6565"}
              fontSize="16px"
              fontWeight={600}
              display="flex"
              justifyContent="center"
              alignItems="center"
              // p={1}
              key={year}
              onClick={() => onSelectYear(year)}
            >
              {year}
            </Button>
          ))}
        </ButtonGroup>

        <ChevronRightIcon cursor="pointer" onClick={handleNext} />
      </Box>

    </VStack>
    
    
    
{/*     
    <Box display="flex">
      <Text
        fontSize="12px"
        color="#1E1E1E"
        fontWeight={600}
        textTransform="uppercase"
        letterSpacing="1px"
        textColor={'#162421'}
      >
        Select Year
      </Text>

      <Box display="flex" ml={6}>
        <ChevronLeftIcon cursor="pointer" onClick={handlePrev} />

        <ButtonGroup gap="2" mx={4}>
          {years.slice(startIndex, startIndex + 3).map((year) => (
            <Button
              backgroundColor={selectedYear === year ? "#0C543C" : "#DBE8E8"}
              width="57px"
              height="18px"
              color={selectedYear === year ? "#F8FDFD" : "#4E6565"}
              fontSize="16px"
              fontWeight={600}
              p={1}
              key={year}
              onClick={() => onSelectYear(year)}
            >
              {year}
            </Button>
          ))}
        </ButtonGroup>

        <ChevronRightIcon cursor="pointer" onClick={handleNext} />
      </Box> 
    </Box>*/}
    </>
  );
}
