import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InterpretationModal from "../../pages/Organisation/Dashboard/PerformanceMatrix/InterpretationModal";
import { getRechartContainerWidth } from "../../utils/common";
import { getPerformanceMatrixHoverText } from "../../utils/organisationDashboard";
import { ResponsiveContainer } from "recharts";
import { COLORS, TYPES_OF_METRICS } from "../../constants/metrics";

const PerformanceMatrix = ({
  setCurrentType,
  currentMetric,
  setCurrentMetric,
  setPatternData,
  metricColors,
  setMetricColors,
}) => {
  const dispatch = useDispatch();
  const [isInterpretationMeodalOpen, setIsInterpretationModalOpen] =
    useState(false);
  const [interpretationModalText, setInterpretationModalText] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [varianceData, setVarianceData] = useState(null);
  const [data, setData] = useState(null);
  const [selectedresMatLang] = useState("english");
  const { windowSize } = useSelector((state) => state.uiReducer);
  const [highlightedRow, setHighlightedRow] = useState("");
  const [highlightedColumn, setHighlightedColumn] = useState("");
  const [hoverTextColor, setHoverTextColor] = useState("");

  const { client } = useSelector((state) => state.clientReducer);
  const { details, zScore, trend } = client;
  useEffect(() => {
    if (details && zScore?.data?.length > 0 && trend?.data) {
      const years = [...details?.data?.auditUploadYears];
      const currentYear = years?.sort((a, b) => b - a)[0];
      const currentYearData = zScore?.data?.filter((item) => {
        if (Number(item?.year) === Number(currentYear)) {
          return true;
        }
        return false;
      });
      const varianceData = currentYearData[0]?.metrics;
      setVarianceData(varianceData);

      const result = {
        "Very High": {
          falling: [],
          stagnant: [],
          rising: [],
        },
        High: {
          falling: [],
          stagnant: [],
          rising: [],
        },
        Moderate: {
          falling: [],
          stagnant: [],
          rising: [],
        },
        Low: {
          falling: [],
          stagnant: [],
          rising: [],
        },
        "Very Low": {
          falling: [],
          stagnant: [],
          rising: [],
        },
      };
      for (const variable in varianceData) {
        const zscoreLabel = varianceData[variable].label;
        const trendCategory = trend?.data[variable] || "";
        result[zscoreLabel][trendCategory]?.push(variable);
      }
      setData({ ...result });
    }
  }, [dispatch, zScore, trend, details]);

  useEffect(() => {
    if (!varianceData || !trend?.data) return;
    debugger
    const metricPatternColors = [
      ...TYPES_OF_METRICS.marginAndCost.value.map((val) => val.label),
      ...TYPES_OF_METRICS.efficiencyAndLeverage.value.map((val) => val.label),
      ...TYPES_OF_METRICS.growthAndReturns.value.map((val) => val.label),
    ];

    const res = metricPatternColors.reduce((obj, val) => {
      const key = val.charAt(0).toLowerCase() + val.slice(1);
      const situation = varianceData[key].label;
      const trendOfKey = trend?.data[key];

      const res = getPerformanceMatrixHoverText(
        selectedresMatLang,
        key,
        situation,
        trendOfKey
      )?.[0];

      obj[val] = COLORS?.[res.color];

      return obj;
    }, {});

    setMetricColors(res);
  }, [varianceData, trend]);

  useEffect(() => {
    const findRowColumn = () => {
      let foundIn = null;

      Object.keys(data).forEach((category) => {
        Object.keys(data[category]).forEach((status) => {
          if (data[category][status].includes(currentMetric)) {
            foundIn = { category, status };
          }
        });
      });

      setHighlightedRow(foundIn?.category);
      setHighlightedColumn(foundIn?.status);
    };

    data && currentMetric && findRowColumn();
  }, [data, currentMetric]);

  useEffect(() => {
    if (!currentMetric || !varianceData || !trend?.data) return;

    const inputString = currentMetric;
    const key = inputString.charAt(0).toLowerCase() + inputString.slice(1);
    const situation = varianceData[key].label;
    const trendOfKey = trend?.data[key];

    const res = getPerformanceMatrixHoverText(
      selectedresMatLang,
      key,
      situation,
      trendOfKey
    )?.[0];

    setPatternData({
      pattern: `${situation} - ${trendOfKey}`,
      interpretation: res,
    });
  }, [currentMetric, varianceData, trend]);

  const handleMouseOver = (e, textColor) => {
    setMousePosition({ x: e.clientX + 10, y: e.clientY + 15 });
    setHoverTextColor(textColor);

    const inputString = e.currentTarget.dataset.metric || "";
    const key = inputString.charAt(0).toLowerCase() + inputString.slice(1);
    const situation = varianceData[key].label;
    const trendOfKey = trend?.data[key];

    setInterpretationModalText(
      getPerformanceMatrixHoverText(
        selectedresMatLang,
        key,
        situation,
        trendOfKey
      )
    );
    setIsInterpretationModalOpen(true);
  };

  const handleMouseOut = (e) => {
    setIsInterpretationModalOpen(false);
  };

  if (!data) {
    return (
      <ResponsiveContainer
        width={getRechartContainerWidth(windowSize.innerWidth)}
        aspect={1.2}
        textAlign={"center"}
      >
        <Box
          bg="#B3A7FA1A"
          w="100%"
          p={4}
          color="white"
          display="flex"
          alignItems="center"
        >
          <Heading
            color={"primary.500"}
            w={"full"}
            size={"md"}
            textAlign={"center"}
          >
            No data available
          </Heading>
        </Box>
      </ResponsiveContainer>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        <table id="resource-matrix-table">
          <thead>
            <tr>
              <th></th>
              <th
                style={{
                  color:
                    highlightedColumn === "falling" ? "#162421" : "#92ACAC",
                }}
              >
                Falling
              </th>
              <th
                style={{
                  color:
                    highlightedColumn === "stagnant" ? "#162421" : "#92ACAC",
                }}
              >
                Stagnant
              </th>
              <th
                style={{
                  color: highlightedColumn === "rising" ? "#162421" : "#92ACAC",
                }}
              >
                {" "}
                Rising
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th
                style={{
                  color: highlightedRow === "Very High" ? "#162421" : "#92ACAC",
                }}
              >
                Very High
              </th>
              <td>
                <Flex>
                  {data?.["Very High"]?.falling.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very High"]?.stagnant.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very High"]?.rising.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: highlightedRow === "High" ? "#162421" : "#92ACAC",
                }}
              >
                High
              </th>
              <td>
                <Flex>
                  {data?.High?.falling.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.High?.stagnant.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.High?.rising.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: highlightedRow === "Moderate" ? "#162421" : "#92ACAC",
                }}
              >
                Moderate
              </th>
              <td>
                <Flex>
                  {data?.Moderate?.falling.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex rowGap={3}>
                  {data?.Moderate?.stagnant.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Moderate?.rising.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: highlightedRow === "Low" ? "#162421" : "#92ACAC",
                }}
              >
                Low
              </th>
              <td>
                <Flex>
                  {data?.Low?.falling.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Low?.stagnant.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Low?.rising.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: highlightedRow === "Very Low" ? "#162421" : "#92ACAC",
                }}
              >
                Very Low
              </th>
              <td>
                <Flex>
                  {data?.["Very Low"]?.falling.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very Low"]?.stagnant.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very Low"]?.rising.map((item, index) => (
                    <Box
                      key={index}
                      onMouseEnter={(e) =>
                        handleMouseOver(e, metricColors?.[item]?.textColor)
                      }
                      onMouseOut={handleMouseOut}
                      color={metricColors?.[item]?.textColor}
                      bgColor={metricColors?.[item]?.bgColor}
                      outline={
                        currentMetric === item
                          ? `${metricColors?.[item]?.textColor} solid 1px`
                          : ""
                      }
                      data-metric={item}
                      fontSize={12}
                      onClick={() => {
                        setCurrentMetric(item);
                        Object.keys(TYPES_OF_METRICS).map((key) => {
                          if (
                            TYPES_OF_METRICS[key].value.some(
                              (val) => val.label === item
                            )
                          ) {
                            setCurrentType(key);
                          }
                        });
                      }}
                    >
                      {TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === item
                      )
                        ? `${item} %`
                        : item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
          </tbody>
        </table>

        {isInterpretationMeodalOpen && (
          <InterpretationModal
            position={mousePosition}
            text={interpretationModalText}
            textColor={hoverTextColor}
          />
        )}
      </div>
    );
  }
};

export default PerformanceMatrix;
