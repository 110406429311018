import { Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";
import {
  calculateUnitsForValues,
  formatCurrencyValue,
} from "../../../utils/helperFunction";

const FixedAssets = ({ data, chartDesc, yAxisLabel }) => {
  const valuesWithUnits = calculateUnitsForValues(data);
  yAxisLabel = `Fixed Assets [units in ${valuesWithUnits}]`;
  return (
    <>
      <Stack className="home-page-card">
        <Heading size={"md"} color={"primary.500"} textAlign={"center"}>
          Total Fixed Asset
        </Heading>
        <Text
          color="#D3D3D3"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          fontSize="sm"
        >
          {chartDesc}
        </Text>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 20, right: 20, bottom: 10, left: 60 }}
        >
          <XAxis
            dataKey="year"
            label={{ value: "Period", position: "insideBottom", dy: 10 }}
          />
          <YAxis
            tickFormatter={formatCurrencyValue}
            domain={[0, "dataMax"]}
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "Middle",
              dx: -45,
            }}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" stackId="1" fill="#8884d8" barSize={35} />
        </BarChart>
      </Stack>
    </>
  );
};

export default FixedAssets;
