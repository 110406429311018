import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import FileDragAndDrop from "../../components/FIleDragAndDrop/FileDragAndDrop";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import "../Audit/fileUploader.scss";
import { sheetConfig } from "../Audit/constant";
import { BALANCE_SHEET } from "../../utils/Stringconstant";
import {
  balanceSheetReqBody,
  IncomeStatReqBody,
  isAuditFormatValid,
  isFormulasAndMinEnteredAuditValidated,
  returnFormattedArr,
} from "../Audit/helper";
import { errorMessages } from "../../utils/constant";
import { CheckIcon } from "@chakra-ui/icons";

const SheetUploaderBox = ({
  boxName = "Sheet",
  boxRef,
  setData,
  setRawData,
  setAllyears,
  clientData,
}) => {
  const [isFileInput, setIsFileInp] = useState(true);
  const [previewData, setPreviewData] = useState([]);
  const { sheetRows, tableHeader, referenceMetrics } = sheetConfig[boxName];
  const { auditErrors } = errorMessages;

  const fileChangeHandler = async (e) => {
    try {
      const file = e;

      if (!file) {
        throw new Error(auditErrors.noFileProvided);
      }
      // https://github.com/SheetJS/sheetjs/issues/2295
      const buffer = await file.arrayBuffer();
      /**
       * To parse specific sheets in an xlsx file, add 'sheets:/regex/' property.
       */
      // read first sheetRows rows of data in a table
      const workbook = read(new Uint8Array(buffer), {
        type: "array",
        sheetRows,
        cellFormula: true,
      });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      // convert sheet to array and remove the header from the array.
      const raw_data = utils
        .sheet_to_json(firstSheet, { header: 1, defval: null })
        .slice(1);

      const sheetData = JSON.parse(JSON.stringify(raw_data)); // Deep copy
      const { result = [], allYears = [] } = returnFormattedArr(
        sheetData,
        boxName
      );
      const auditSheetCol0 = raw_data.map((row) => row[0]);

      // Invalid template structure
      // if (JSON.stringify(auditSheetCol0) !== JSON.stringify(referenceMetrics)) {
      if (!isAuditFormatValid(auditSheetCol0, referenceMetrics)) {
        throw new Error(`Invalid template structure of audit file ${boxName}`);
      }

      const formattedResult =
        boxName === BALANCE_SHEET
          ? balanceSheetReqBody(result)
          : IncomeStatReqBody(result);

      // formula validation for the audit sheets and validation for atleast 3 years of audit data is entered
      isFormulasAndMinEnteredAuditValidated(formattedResult, boxName);

      setIsFileInp(false);
      setPreviewData(raw_data);
      setAllyears(allYears);
      setRawData(raw_data);
      setData(result);

      /* toast.success("✅File valid for upload", {
        position: "bottom-center",
      }); */
    } catch (error) {
      setIsFileInp(true); // Reset to allow reupload
      setPreviewData([]); // Reset preview data
      toast.error(error.message, {
        toastId: "File-upload-error",
        position: "bottom-center",
      });
    }
  };

  return (
    <>
      <Stack ref={boxRef} pt={"50px"}>
        <>
          <Heading size={"md"}>Upload {boxName}</Heading>

          <Box justifyContent={"space-evenly"}>
            {!clientData?.auditUploadYears?.length > 0 ? (
              <FileDragAndDrop handleChange={(e) => fileChangeHandler(e)} />
            ) : (
              <Flex
                h={"120px"}
                justifyContent={"start"}
                className="drag-drop-box"
                w="100%"
              >
                <Box
                  w="100%"
                  className="rddf"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  borderRadius={5}
                >
                  <CheckIcon
                    fontSize={26}
                    style={{
                      backgroundColor: "green",
                      borderRadius: "50%",
                      padding: "5px",
                      color: "#fff",
                    }}
                  />
                  <Text>{boxName} is already uploaded</Text>
                </Box>
              </Flex>
            )}
          </Box>

          <Text color="gray">
            <Text display="inline-block" fontWeight="bold">
              Formats
            </Text>
            : CSV, xlsx
          </Text>

          {!isFileInput && <Text>✅ File valid for upload</Text>}
        </>
      </Stack>
    </>
  );
};

export default SheetUploaderBox;
