import { Box, Heading, Stack } from "@chakra-ui/react";
import LineChart from "../LineChart";

export default function SystematicFactorsLineChart({ data, heading, dimension }) {
  return (
    <Stack
      w="100%"
      h="100%"
      borderColor={"#F8F8F8"}
      borderRadius={4}
      borderWidth={1}
    >
      <Heading
        fontWeight={600}
        fontSize={"12px"}
        // p="5px 10px 8px"
        p={2}
        display="inline-block"
        textColor="#0C543C"
        borderRadius={4}
        backgroundColor={"#F8F8F8"}
      >
        {heading}
      </Heading>
      <Box>
        <LineChart data={data} dimension={dimension} />
      </Box>
    </Stack>
  );
}
