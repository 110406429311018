import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Link as ChakraLink,
  VStack,
  HStack,
  Text,
  Spacer,
  Tag,
  IconButton,
  TableContainer,
  Table,
  Th,
  Tr,
  Thead,
  Td,
  Tbody,
  Image,
  useDisclosure,
  Img,
  // AlertDialogFooter,
  // Icon,
} from "@chakra-ui/react";

import Loader from "../../components/Loader/Loader";
import { getRegisteredClientsData } from "../../actions/user/agencyActions";
// import { getClientIdForAudtiSheet } from "../../actions/audit/auditUploadActions";
// import { InfoIcon, RepeatIcon } from "@chakra-ui/icons";
import { errMsg } from "../../utils/helperFunction";
import { toast } from "react-toastify";
import {
  getAllClientMetricFromCacheAPI,
  refreshClientMetricCacheAPI,
} from "../../api/agencyApi";
import { getPerformanceMatrixHoverText } from "../../utils/organisationDashboard";
// import { timeAgo } from "../../utils/common";
import { CLIENT_BACKGROUND_TABS } from "../../constants/ui";
import { setCurrentClient } from "../../actions/client/clientActions";
import AddClients from "../../assets/images/addClients.png";
import { usePayment } from "../../contexts/PaymentContext";
import DashboardCard from "../../assets/video/DashboardCard.mp4";
import { HamburgerIcon } from "@chakra-ui/icons";
import Navbar from "../../components/Header/Navbar";
import FloatingLeftNav from "../../components/Navigation/FloatingLeftNav";
import bulb_on from "../../assets/images/bulb-on.png";
import cross_icon from "../../assets/images/cross-icon.png";
import "./PracticeDashboard.scss";
import { useTourContext } from "../../customHooks/useTourContext";

const PracticeDashboard = () => {
  console.log("=========help");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { is_tour, setIsTour } = useTourContext();
  const [financial_statements, setFinancialStatements] = useState(false);
  const { loading, clientsData } = useSelector((state) => state.agencyReducer);
  const { user, isAuthenticatedUser } = useSelector(
    (state) => state.userReducer
  );
  const clientDashboards = user?.clientDashboards;

  const { showPaymentModal } = usePayment();

  const [clientsMetricPatterns, setClientsMetricPatterns] = useState([]);

  const [uploadedClients,setUploadedClients] = useState([]);
  const [pendingUploadClients,setPendingUploadClients] = useState([]);
  const [currentClientType, setCurrentClientType] = useState("All");
  const [isSortedByMostCritical, setIsSortedByMostCritical] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const [addclient_tour, setAddClientTour] = useState(true);
  const [benchmarked_tour, setBenchMarkedTour] = useState(false);
  const [criticalmetric_tour, setCriticalMetricTour] = useState(false);
  const [clientdashboard_tour, setClientDashboardTour] = useState(false);

  useEffect(() => {
    const segregateData = () => {
      const initialSeparation = { uploadedArray: [], pendingarray: [] };

      const { uploadedArray: newUploadedArray, pendingarray: newPendingarray } =
        clientsData.reduce((acc, current) => {
          if (
            current.auditUploadYears &&
            current.auditUploadYears[0] &&
            !canUpload(
              current.auditUploadYears[current.auditUploadYears.length - 1]
            )
          ) {
          acc.uploadedArray.push(current);
        } else{
          acc.pendingarray.push(current);
        }
        return acc;
      }, initialSeparation);

      setPendingUploadClients(newPendingarray);

      const updatedArray = newUploadedArray.map((obj) => {
        const foundData = clientsMetricPatterns.find(
          (dataObj) => dataObj.clientId === obj.id
        );
        return {
          ...obj,
          criticalMetricLength: foundData
            ? Object.keys(foundData.criticalMetrics).length
            : 0,
        };
      });    

      setUploadedClients(updatedArray);
    };

    clientsData &&
      clientsMetricPatterns &&
      clientsMetricPatterns.length > 0 &&
      clientsData.length > 0 &&
      segregateData();
  }, [clientsData, clientsMetricPatterns]);

  const canUpload = (auditUploadLatestYear) => {
    const today = new Date(Date.now());

    const res =
      Date.parse(today.toISOString()) >
      Date.parse(`${auditUploadLatestYear + 2}-03-01`);

    return res;
  };

  // const updateClientId = (clientId) => {
  //   dispatch(getClientIdForAudtiSheet(clientId));
  // };

  const addClientButtonHandler = () => {
    if (clientDashboards?.availableDashboards >= 1) {
      navigate("/agency/client-new");
    } else {
      showPaymentModal();
    }
  };

  const getClientMetricFromCache = async () => {
    try {
      const data = await getAllClientMetricFromCacheAPI();

      const metricPatternsFromCache = data?.map?.((mp) => {
        const reversedZScore = Object.entries(mp?.currentYearZScore).reduce(
          (result, [metricKey, items]) => {
            items.forEach(({ year, ...metricData }) => {
              const existingZScoreItem = result.find(
                (item) => item.year === year
              );

              if (!existingZScoreItem) {
                const newZScoreItem = {
                  year,
                  metrics: { [metricKey]: { ...metricData } },
                };
                result.push(newZScoreItem);
              } else {
                existingZScoreItem.metrics[metricKey] = { ...metricData };
              }
            });

            return result;
          },
          []
        );

        const varianceData = reversedZScore
          ?.slice?.()
          ?.sort?.((a, b) => b?.year - a?.year)?.[0]?.metrics;

        let criticalMetrics = {};
        for (const variable in varianceData) {
          const key = variable.charAt(0).toLowerCase() + variable.slice(1);
          const situation = varianceData[key].label;
          const trendOfKey = mp?.currentYearTrend?.[key];

          const res = getPerformanceMatrixHoverText(
            "english",
            key,
            situation,
            trendOfKey
          )?.[0];

          if (res?.color === "danger") {
            criticalMetrics[variable] = {
              pattern: `${situation}-${trendOfKey}`,
              internalControls: res?.internalControls,
            };
          }
        }

        const currentSelectedMetric = Object.keys(criticalMetrics || {})?.[0];

        return {
          clientId: mp?.clientId,
          criticalMetrics,
          currentSelectedMetric,
          lastRefreshed: mp?.updatedAt,
        };
      });

      setClientsMetricPatterns(metricPatternsFromCache || []);
    } catch (error) {
      console.error(error);

      const message = errMsg(error);

      toast.error(message, {
        position: "bottom-center",
        toastId: message,
      });
    }
  };

  const changeCurrentClientSelectedMetric = (clientId, newMetric) => {
    setClientsMetricPatterns((prev) => {
      return prev?.map?.((cm) => {
        if (clientId === cm?.clientId) {
          return {
            ...cm,
            currentSelectedMetric: newMetric,
          };
        }
        return cm;
      });
    });
  };

  const refreshClientMetricCache = async (clientId) => {
    try {
      const data = await refreshClientMetricCacheAPI(clientId);

      const reversedZScore = Object.entries(data?.currentYearZScore).reduce(
        (result, [metricKey, items]) => {
          items.forEach(({ year, ...metricData }) => {
            const existingZScoreItem = result.find(
              (item) => item.year === year
            );

            if (!existingZScoreItem) {
              const newZScoreItem = {
                year,
                metrics: { [metricKey]: { ...metricData } },
              };
              result.push(newZScoreItem);
            } else {
              existingZScoreItem.metrics[metricKey] = { ...metricData };
            }
          });

          return result;
        },
        []
      );

      const varianceData = reversedZScore
        ?.slice?.()
        ?.sort?.((a, b) => b?.year - a?.year)?.[0]?.metrics;

      let criticalMetrics = {};
      for (const variable in varianceData) {
        const key = variable.charAt(0).toLowerCase() + variable.slice(1);
        const situation = varianceData[key].label;
        const trendOfKey = data?.currentYearTrend?.[key];

        const res = getPerformanceMatrixHoverText(
          "english",
          key,
          situation,
          trendOfKey
        )?.[0];

        if (res?.color === "danger") {
          criticalMetrics[variable] = {
            pattern: `${situation}-${trendOfKey}`,
            internalControls: res?.internalControls,
          };
        }
      }

      const currentSelectedMetric = Object.keys(criticalMetrics || {})?.[0];

      const updatedClientMetricCache = {
        clientId: data?.clientId,
        criticalMetrics,
        currentSelectedMetric,
        lastRefreshed: data?.updatedAt,
      };

      setClientsMetricPatterns((prev) => {
        const filteredClientMetric = prev?.filter?.((cm) => {
          if (clientId === cm?.clientId) {
            return false;
          }
          return true;
        });

        return [...filteredClientMetric, { ...updatedClientMetricCache }];
      });
    } catch (error) {
      console.error(error);

      const message = errMsg(error);

      toast.error(message, {
        position: "bottom-center",
        toastId: message,
      });
    }
  };

  const setClientId = (clientId) => {
    dispatch(setCurrentClient(clientId));
    navigate(`/agency/client/${clientId}/risk-assessment`);
  };

  const OnClickToOrganisationHome = (client) => {
    const data = { client: { ...client } };
    dispatch({
      type: "GET_ORG_DETAILS_SUCCESS",
      payload: data,
    });
  };

  useEffect(() => {
    if (isAuthenticatedUser) dispatch(getRegisteredClientsData());
  }, [isAuthenticatedUser, dispatch]);

  useEffect(() => {
    getClientMetricFromCache();
  }, []);

  const printThing = () => {
    // console.log('uploadedClients : ' ,uploadedClients);
    // console.log('clientsMetricPatterns " ', clientsMetricPatterns)

    return true;
  };

  return (
    <Stack className="body-container" overflowY={"auto"}>
      {isAuthenticatedUser && (
      <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Heading
            fontSize={"23px"}
            fontWeight={700}
            color={"#0C543C"}
            width={"100%"}
          >
          Manage Your Clients
        </Heading>
        <Box pr={5}>
          {/* <ChakraLink
            color="#30d5c8"
            _hover={{ color: "#41e6d9" }}
            fontWeight="bold"
            href="https://beta.sylvr.co.in/blogs/beyond-smes-audits-tax-nurturing-multi-disciplinary-growth-for-small-medium-practices"
            target="_blank"
          >
            Beyond SMEs Audits & Tax: Multi-Disciplinary growth for CA practices{" "}
            <InfoIcon />
          </ChakraLink> */}
            {is_tour && addclient_tour && (
              <Box className="add_client-tooplip add-segments-picture add_client-position">
                <Flex padding="15px 15px 0px">
                  <Flex w="100%" justifyContent={"space-between"}>
                    <Flex>
                      <Box>
                        <Img src={bulb_on} alt="alert" />
                      </Box>
                      <Box className="add-client">Add a new client</Box>
                    </Flex>
                    <Box
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setAddClientTour(false);
                        setIsTour(false);
                      }}
                    >
                      <Img src={cross_icon} alt="cross_icon" />
                    </Box>
                  </Flex>
                </Flex>
                <Box className="single-dashboard">
                  Access your clients from a single dashboard
                </Box>

                <Box className="trends-bar">
                  <Box className="add_client-bar-fill"></Box>
                </Box>
                <Box className="next-block">
                  <Box
                    className="next-btn"
                    onClick={() => {
                      setBenchMarkedTour(true);
                      setAddClientTour(false);
                    }}
                  >
                    Next
                  </Box>
                </Box>
              </Box>
            )}
            {clientsData.length > 0 && (
              <Button
                h={"40px"}
                bg={"#0C543C"}
                fontWeight={600}
                fontSize={"16px"}
                color={"#F8FDFD"}
                _hover={{}}
                onClick={addClientButtonHandler}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "8px" }}
                >
                <g clipPath="url(#clip0_2388_649)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.2857 3.85714C10.2857 4.88012 9.87934 5.8612 9.15598 6.58455C8.43263 7.30791 7.45155 7.71429 6.42857 7.71429C5.40559 7.71429 4.42451 7.30791 3.70116 6.58455C2.9778 5.8612 2.57143 4.88012 2.57143 3.85714C2.57143 2.83416 2.9778 1.85309 3.70116 1.12973C4.42451 0.406376 5.40559 0 6.42857 0C7.45155 0 8.43263 0.406376 9.15598 1.12973C9.87934 1.85309 10.2857 2.83416 10.2857 3.85714ZM13.8214 9.64286C13.9481 9.64286 14.0735 9.6678 14.1904 9.71626C14.3074 9.76472 14.4137 9.83575 14.5033 9.92529C14.5928 10.0148 14.6639 10.1211 14.7123 10.2381C14.7608 10.3551 14.7857 10.4805 14.7857 10.6071V12.8571H17.0357C17.2915 12.8571 17.5367 12.9587 17.7176 13.1396C17.8984 13.3204 18 13.5657 18 13.8214C18 14.0772 17.8984 14.3224 17.7176 14.5033C17.5367 14.6841 17.2915 14.7857 17.0357 14.7857H14.7857V17.0357C14.7857 17.2915 14.6841 17.5367 14.5033 17.7176C14.3224 17.8984 14.0772 18 13.8214 18C13.5657 18 13.3204 17.8984 13.1396 17.7176C12.9587 17.5367 12.8571 17.2915 12.8571 17.0357V14.7857H10.6071C10.3514 14.7857 10.1061 14.6841 9.92529 14.5033C9.74445 14.3224 9.64286 14.0772 9.64286 13.8214C9.64286 13.5657 9.74445 13.3204 9.92529 13.1396C10.1061 12.9587 10.3514 12.8571 10.6071 12.8571H12.8571V10.6071C12.8571 10.3514 12.9587 10.1061 13.1396 9.92529C13.3204 9.74445 13.5657 9.64286 13.8214 9.64286ZM6.42857 9C8.34814 9 10.0723 9.84214 11.25 11.1767V11.25H10.6071C10.0369 11.2495 9.48262 11.4386 9.03155 11.7875C8.58049 12.1364 8.2582 12.6254 8.11539 13.1775C7.97258 13.7296 8.01735 14.3135 8.24265 14.8374C8.46796 15.3612 8.86101 15.7953 9.36 16.0714H0.642857C0.472361 16.0714 0.308848 16.0037 0.188289 15.8831C0.0677294 15.7626 0 15.5991 0 15.4286C0 13.7236 0.677294 12.0885 1.88288 10.8829C3.08848 9.67729 4.72361 9 6.42857 9Z"
                      fill="#F8FDFD"
                    />
                </g>
                <defs>
                  <clipPath id="clip0_2388_649">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              Add Clients
            </Button>
            )}
        </Box>
      </Flex>
      )}

      {isAuthenticatedUser && clientsData.length === 0 && (
        <VStack mt={10} spacing={4}>
          <Text
            fontWeight={600}
            fontSize={29}
            textAlign={"center"}
            textColor={"#92ACAC"}
          >
            Create your first dashboard. Connect to a new client in less than 2
            minutes.
          </Text>
          <Button bg={"#0C543C"} onClick={addClientButtonHandler}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2857 3.85714C10.2857 4.88012 9.87934 5.8612 9.15598 6.58455C8.43263 7.30791 7.45155 7.71429 6.42857 7.71429C5.40559 7.71429 4.42451 7.30791 3.70116 6.58455C2.9778 5.8612 2.57143 4.88012 2.57143 3.85714C2.57143 2.83416 2.9778 1.85309 3.70116 1.12973C4.42451 0.406376 5.40559 0 6.42857 0C7.45155 0 8.43263 0.406376 9.15598 1.12973C9.87934 1.85309 10.2857 2.83416 10.2857 3.85714ZM13.8214 9.64286C13.9481 9.64286 14.0735 9.6678 14.1904 9.71626C14.3074 9.76472 14.4137 9.83575 14.5033 9.92529C14.5928 10.0148 14.6639 10.1211 14.7123 10.2381C14.7608 10.3551 14.7857 10.4805 14.7857 10.6071V12.8571H17.0357C17.2915 12.8571 17.5367 12.9587 17.7176 13.1396C17.8984 13.3204 18 13.5657 18 13.8214C18 14.0772 17.8984 14.3224 17.7176 14.5033C17.5367 14.6841 17.2915 14.7857 17.0357 14.7857H14.7857V17.0357C14.7857 17.2915 14.6841 17.5367 14.5033 17.7176C14.3224 17.8984 14.0772 18 13.8214 18C13.5657 18 13.3204 17.8984 13.1396 17.7176C12.9587 17.5367 12.8571 17.2915 12.8571 17.0357V14.7857H10.6071C10.3514 14.7857 10.1061 14.6841 9.92529 14.5033C9.74445 14.3224 9.64286 14.0772 9.64286 13.8214C9.64286 13.5657 9.74445 13.3204 9.92529 13.1396C10.1061 12.9587 10.3514 12.8571 10.6071 12.8571H12.8571V10.6071C12.8571 10.3514 12.9587 10.1061 13.1396 9.92529C13.3204 9.74445 13.5657 9.64286 13.8214 9.64286ZM6.42857 9C8.34814 9 10.0723 9.84214 11.25 11.1767V11.25H10.6071C10.0369 11.2495 9.48262 11.4386 9.03155 11.7875C8.58049 12.1364 8.2582 12.6254 8.11539 13.1775C7.97258 13.7296 8.01735 14.3135 8.24265 14.8374C8.46796 15.3612 8.86101 15.7953 9.36 16.0714H0.642857C0.472361 16.0714 0.308848 16.0037 0.188289 15.8831C0.0677294 15.7626 0 15.5991 0 15.4286C0 13.7236 0.677294 12.0885 1.88288 10.8829C3.08848 9.67729 4.72361 9 6.42857 9Z"
                fill="#F8FDFD"
              />
            </svg>
            Add Clients
          </Button>
          {financial_statements ? (
            <Box className="practice-tooplip add-tip">
              <Flex p="15px">
                <Flex w="100%" justifyContent={"space-between"}>
                  <Flex>
                    <Box>
                      <Img src={bulb_on} alt="alert" />
                    </Box>
                    <Box className="add-client">Add a new client</Box>
                  </Flex>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsTour(false);
                    }}
                  >
                    <Img src={cross_icon} alt="cross_icon" />
                  </Box>
                </Flex>
              </Flex>
              <Box className="single-dashboard">
                Access your clients from a single dashboard
              </Box>
              <Box className="next-block">
                <Box
                  className="next-btn"
                  onClick={() => {
                    setAddClientTour(false);
                    setBenchMarkedTour(true);
                  }}
                >
                  Next
                </Box>
              </Box>
            </Box>
          ) : (
          <Image src={AddClients} boxSize="120px" alt="add Client" />
          )}
          {financial_statements && (
            <Box className="practice-tooplip add-tip">
              <Flex p="15px">
                <Flex w="100%" justifyContent={"space-between"}>
                  <Flex>
                    <Box>
                      <Img src={bulb_on} alt="alert" />
                    </Box>
                    <Box className="add-client">
                      Upload Financial Statements
                    </Box>
                  </Flex>
                  <Box style={{ cursor: "pointer" }}>
                    <Img src={cross_icon} alt="cross_icon" />
                  </Box>
                </Flex>
              </Flex>
              <Box className="single-dashboard">
                Optimize your workflow with a template for your statements
              </Box>
              <Box className="next-block">
                <Box className="next-btn">Finish</Box>
              </Box>
            </Box>
          )}

          {/* <Box>
            <Text
              fontWeight={400}
              fontSize={'18px'}
              color={'#162421'}
              mt={4}
              mb={-1}
              w={'100%'}
              textAlign={'center'}
            >
              Preview the sample below
            </Text>
            <video controls autoplay>
              <source src={DashboardCard} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box> */}
        </VStack>
      )}
        {isAuthenticatedUser && clientsData.length > 0 && (
          <>
          <HStack px={3} mt={1} mb={-1}>
            <HStack >
              <Tag 
                fontWeight={400}
                fontSize={"16px"}
                textColor={currentClientType === "All" ? "#FFFFFF" : "#4E6565"}
                bg={currentClientType === "All" ? "#4E6565" : "#FFFFFF"}
                h={"32px"}
                onClick={() => setCurrentClientType("All")}
                _hover={{
                  cursor: "pointer",
                }}
              >
                All({clientsData.length})
              </Tag>
              <Tag 
                fontWeight={400}
                fontSize={"16px"}
                textColor={
                  currentClientType === "Benchmarked Clients"
                    ? "#FFFFFF"
                    : "#4E6565"
                }
                bg={
                  currentClientType === "Benchmarked Clients"
                    ? "#4E6565"
                    : "#FFFFFF"
                }
                h={"32px"}
                onClick={() => setCurrentClientType("Benchmarked Clients")}
                _hover={{
                  cursor: "pointer",
                }}
              >
                Benchmarked Clients ({uploadedClients.length})
              </Tag>
              {is_tour && benchmarked_tour && (
                <Box className="benchmarked_client-tooplip add-segments-picture benchmarked_client-position">
                  <Flex padding="15px 15px 0px">
                    <Flex w="100%" justifyContent={"space-between"}>
                      <Flex>
                        <Box>
                          <Img src={bulb_on} alt="alert" />
                        </Box>
                        <Box className="add-client">Filter your clients</Box>
                      </Flex>
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBenchMarkedTour(false);
                          setIsTour(false);
                        }}
                      >
                        <Img src={cross_icon} alt="cross_icon" />
                      </Box>
                    </Flex>
                  </Flex>
                  <Box className="single-dashboard">
                    Access your clients based on their status. Depending as per
                    peer availability, clients are categorized as benchmarked or
                    unbenchmarked.
                  </Box>
                  <Box className="trends-bar">
                    <Box className="benchmarked_client-bar-fill"></Box>
                  </Box>
                  <Box className="next-block">
                    <Box
                      className="next-btn"
                      onClick={() => {
                        setBenchMarkedTour(false);
                        setCriticalMetricTour(true);
                      }}
                    >
                      Next
                    </Box>
                  </Box>
                </Box>
              )}
              <Tag 
                fontWeight={400}
                fontSize={"16px"}
                textColor={
                  currentClientType === "Upload Pending" ? "#FFFFFF" : "#4E6565"
                }
                bg={
                  currentClientType === "Upload Pending" ? "#4E6565" : "#FFFFFF"
                }
                h={"32px"}
                onClick={() => setCurrentClientType("Upload Pending")}
                _hover={{
                  cursor: "pointer",
                }}
              >
                Upload Pending ({pendingUploadClients.length})
              </Tag>
            </HStack>

            <Spacer />

            <HStack >
              <Text color={"#4E6565"} fontWeight={400} fontSize={16}>
                Sort By{" "}
              </Text>
              <Tag
                h={"32px"}
                bg={"#DBE8E8"}
                color={"#162421"}
                _hover={{
                  cursor: "pointer",
                }}
                pl={0}
                onClick={() => {
                  setIsSortedByMostCritical(!isSortedByMostCritical);
                }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <IconButton
                  h={"32px"}
                  bg="rgba(219, 232, 232, 0.5)"
                  _hover={{}}
                  icon={
                    <svg
                      width="13"
                      height="16"
                      viewBox="0 0 13 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#162421"
                      fillOpacity="0.5"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.24145 12.2501C1.1014 12.11 1.00604 11.9315 0.967416 11.7372C0.928793 11.5429 0.948644 11.3415 1.02446 11.1585C1.10027 10.9755 1.22865 10.8191 1.39335 10.709C1.55806 10.599 1.7517 10.5402 1.9498 10.5402L3.79292 10.5402L3.78159 1.6263C3.78577 1.36365 3.89294 1.11316 4.08002 0.928766C4.2671 0.744375 4.51912 0.640845 4.7818 0.640473C5.04448 0.640101 5.29679 0.74292 5.4844 0.92678C5.672 1.11064 5.77988 1.36083 5.7848 1.62346L5.8018 14.3794C5.80206 14.5403 5.76358 14.6988 5.6896 14.8417C5.61563 14.9845 5.50834 15.1075 5.37681 15.2001C5.24527 15.2927 5.09337 15.3523 4.93395 15.3738C4.77452 15.3953 4.61226 15.3781 4.46089 15.3236C4.31549 15.2743 4.1834 15.1921 4.07484 15.0835L1.24145 12.2501ZM10.0349 5.45988L10.0349 14.3752C10.0303 14.6378 9.9228 14.8881 9.73546 15.0722C9.54811 15.2563 9.29596 15.3595 9.03329 15.3595C8.77062 15.3595 8.51846 15.2563 8.33112 15.0722C8.14378 14.8881 8.03625 14.6378 8.03168 14.3752L8.03168 4.45827V1.62488C8.0308 1.37175 8.12617 1.12774 8.29847 0.942292C8.47076 0.756844 8.70711 0.643816 8.95962 0.626106C9.10321 0.615216 9.24745 0.635535 9.38244 0.685667C9.51743 0.735799 9.63997 0.814556 9.74164 0.916529L12.575 3.74993C12.7154 3.89004 12.811 4.06865 12.8497 4.26313C12.8884 4.45761 12.8686 4.65922 12.7927 4.84241C12.7167 5.0256 12.5881 5.18213 12.4232 5.29218C12.2582 5.40223 12.0643 5.46084 11.866 5.46059L10.0349 5.45988Z"
                        fill="#162421"
                      />
                    </svg>
                  }
                />
                {isSortedByMostCritical ? "Most Critical" : "Least Critical"}
              </Tag>
            </HStack>
          </HStack>
          </>
        )}
      {isAuthenticatedUser &&
        (currentClientType === "All" ||
          currentClientType === "Benchmarked Clients") &&
        uploadedClients.length > 0 && (
            <>
            {loading ? (
              <>
                <Box height={"300px"}>
                  <Loader />
                </Box>
              </>
            ) : (
              <Box
                w={"100%"}
                maxH={
                  currentClientType === "Benchmarked Clients" ? "85%" : "50vh"
                }
                mb={4}
              >
                <Text
                  fontWeight={600}
                  fontSize={"18px"}
                  color={"#162421"}
                  ml={2}
                >
                  Benchmarked clients
                </Text>
                <Flex
                  flexWrap={"wrap"}
                  justify={"center"}
                  h={"100%"}
                  overflowX={"auto"}
                  alignItems={"stretch"}
                >
              <>
                    {uploadedClients &&
                      printThing() &&
                      uploadedClients
                .slice()
                .sort((a, b) => {
                  if (isSortedByMostCritical) {
                            if (a.criticalMetricLength > b.criticalMetricLength)
                              return -1;
                            if (a.criticalMetricLength < b.criticalMetricLength)
                              return 1;
                    return 0;
                  } else {
                            if (a.criticalMetricLength < b.criticalMetricLength)
                              return -1;
                            if (a.criticalMetricLength > b.criticalMetricLength)
                              return 1;
                    return 0;
                  }
                        })
                        .map((client, index) => {
                  return (
                            <Box
                              key={index}
                              height="auto"
                              width="364px"
                              bg={"#f8fdfd"}
                              m={3}
                              borderRadius={"8px"}
                              boxShadow={"md"}
                            >
                              <VStack align="flex-start" p={2} height={"100%"}>
                                <Flex alignItems={"center"} w={"100%"} p={2}>
                                  <Text
                                    color={"#0C543C"}
                                    fontSize={"18px"}
                                    fontWeight={"700"}
                                    width={"70%"}
                                    lineHeight={"1"}
                                  >
                                    {client.companyName}
                                  </Text>
                                      <Spacer />  
                                      <Button 
                                    bg={"#0C543C"}
                                        fontWeight={600}
                                    fontSize={"16px"}
                                    color={"white"}
                                    h={"32px"}
                                    minW={"149px"}
                                        onClick={() => {
                                          setClientId(client.id);
                                          OnClickToOrganisationHome(client);
                                        }}
                                        _hover={{
                                      boxShadow: "lg",
                                        }}
                                      >
                                        See DashBoard
                                      </Button>
                                  {is_tour && clientdashboard_tour && (
                                    <Box className="client_dashboard-tooplip add-segments-picture client_dashboard-position">
                                      <Flex padding="15px 15px 0px">
                                        <Flex
                                          w="100%"
                                          justifyContent={"space-between"}
                                        >
                                          <Flex>
                                            <Box>
                                              <Img src={bulb_on} alt="alert" />
                                            </Box>
                                            <Box className="add-client">
                                              Client dashboard
                                            </Box>
                                          </Flex>
                                          <Box
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setClientDashboardTour(false);
                                              setIsTour(false);
                                            }}
                                          >
                                            <Img
                                              src={cross_icon}
                                              alt="cross_icon"
                                            />
                                          </Box>
                                        </Flex>
                                      </Flex>
                                      <Box className="single-dashboard">
                                        Access client dashboard to get valuable
                                        analysis
                                      </Box>
                                      <Box className="next-block">
                                        <Box
                                          className="next-btn"
                                          onClick={() => {
                                            setClientDashboardTour(false);
                                            localStorage.setItem("is_tour", false);
                                            setIsTour(false);
                                            // navigate(
                                            //   `/agency/practice-dashboard/ghhgf`
                                            // );
                                          }}
                                        >
                                          Take the tour for Competitive RIsks
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                  </Flex>

                                <Flex alignItems={"center"} w={"100%"} p={2}>
                                  <Text
                                    color={"#0C543C"}
                                    fontWeight={600}
                                    fontSize={"16px"}
                                    lineHeight={"1"}
                                    mr={1}
                                  >
                                    {client.sector}
                                  </Text>
                                      <Spacer />  
                                  <Text
                                    color={"#0C543C"}
                                    fontWeight={600}
                                    fontSize={"16px"}
                                    lineHeight={"1"}
                                    borderLeft={"2px solid #0C543C"}
                                    pl={1}
                                    textAlign={"center"}
                                  >
                                    {client.homestate}
                                  </Text>
                                  </Flex>

                                <Text
                                  color={"black"}
                                  pl={2}
                                  fontWeight={600}
                                  fontSize={"16px"}
                                >
                                  Critical Metrics
                                </Text>
                                {is_tour && criticalmetric_tour && (
                                  <Box className="critical_metrics-tooplip add-segments-picture critical_metrics-position">
                                    <Flex padding="15px 15px 0px">
                                      <Flex
                                        w="100%"
                                        justifyContent={"space-between"}
                                      >
                                        <Flex>
                                          <Box>
                                            <Img src={bulb_on} alt="alert" />
                                          </Box>
                                          <Box className="add-client">
                                            Critical Metrics
                                          </Box>
                                        </Flex>
                                        <Box
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setCriticalMetricTour(false);
                                            setIsTour(false);
                                          }}
                                        >
                                          <Img
                                            src={cross_icon}
                                            alt="cross_icon"
                                          />
                                        </Box>
                                      </Flex>
                                    </Flex>
                                    <Box className="single-dashboard">
                                      Quickly glance at the metrics requiring
                                      your priority
                                    </Box>
                                    <Box className="trends-bar">
                                      <Box className="critical_metrics-bar-fill"></Box>
                                    </Box>
                                    <Box className="next-block">
                                      <Box
                                        className="next-btn"
                                        onClick={() => {
                                          setClientDashboardTour(true);
                                          setCriticalMetricTour(false);
                                        }}
                                      >
                                        Next
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                                <Box w={"100%"}>
                                    <CriticalMetricsCell
                                      currentClient={client?.id}
                                      clientsWithMetrics={clientsMetricPatterns}
                                      changeCurrentClientSelectedMetric={
                                        changeCurrentClientSelectedMetric
                                      }
                                    />
                                  </Box>

                                  <CurrentCriticalMetric
                                    currentClient={client?.id}
                                    clientsWithMetrics={clientsMetricPatterns}
                                  />

                                <Text
                                  color={"black"}
                                  pl={2}
                                  mt={1}
                                  mb={-3}
                                  fontWeight={600}
                                  fontSize={"16px"}
                                >
                                  Internal Controls
                                </Text>

                                <Box w={"100%"}>
                                    <CriticalMetricsInternalControlCell
                                      currentClient={client?.id}
                                      clientsWithMetrics={clientsMetricPatterns}
                                    />
                                  </Box>

                                  <Spacer />
                                  
                                <Box w={"100%"}>
                                    <LastRefreshedCell
                                      currentClient={client?.id}
                                      clientsWithMetrics={clientsMetricPatterns}
                                      refreshClientMetricCache={
                                        refreshClientMetricCache
                                      }
                                    />
                                  </Box>
                              </VStack>
                          </Box> 
                          );
                })}
              </>
              </Flex>
      </Box>
            )} 
            </>
          )}
      {isAuthenticatedUser &&
        (currentClientType === "All" ||
          currentClientType === "Upload Pending") &&
        pendingUploadClients.length > 0 && (
          <Box
            maxWidth={"100%"}
            maxHeight={currentClientType === "Upload Pending" ? "85%" : "60vh"}
          >
            <Text fontWeight={600} fontSize={"18px"} color={"#162421"} ml={2}>
              Upload pending
            </Text>
            <TableContainer h={"100%"} maxWidth={"100%"} overflowY={"scroll"}>
              <Table variant="simple" maxWidth={"100%"}>
            <Thead >
              <Tr >
                    <Th
                      position="sticky"
                      top="0"
                      zIndex="1"
                      bg={"white"}
                      color="#0C543C"
                      fontWeight={700}
                      fontSize={"18px"}
                      maxW={"25%"}
                      textTransform="none"
                    >
                      Company Name
                    </Th>
                    <Th
                      position="sticky"
                      top="0"
                      zIndex="1"
                      bg={"white"}
                      color="#0C543C"
                      fontWeight={700}
                      fontSize={"18px"}
                      maxW={"20%"}
                      textTransform="none"
                    >
                      Sector
                    </Th>
                    <Th
                      position="sticky"
                      top="0"
                      zIndex="1"
                      bg={"white"}
                      color="#0C543C"
                      fontWeight={700}
                      fontSize={"18px"}
                      maxW={"20%"}
                      textTransform="none"
                    >
                      Home State
                    </Th>
                    <Th
                      position="sticky"
                      top="0"
                      zIndex="1"
                      bg={"white"}
                      color="#0C543C"
                      fontWeight={700}
                      fontSize={"18px"}
                      maxW={"35%"}
                      textTransform="none"
                    >
                      Audit Status
                    </Th>
              </Tr>
            </Thead>
            <Tbody>
              {pendingUploadClients 
              .slice()
              .sort((a, b) => {
                const companyNameA = a.companyName.toLowerCase();
                const companyNameB = b.companyName.toLowerCase();
                if (companyNameA < companyNameB) return -1;
                if (companyNameA > companyNameB) return 1;
                return 0;
              })
              .map((client,index) => {
                return (
                        <Tr key={index} bg={"white"}>
                          <Td fontWeight={500} fontSize={"16px"}>
                            <Text
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"normal"}
                            >
                        {client.companyName}
                      </Text>
                    </Td>
                          <Td fontWeight={400} fontSize={"16px"}>
                            <Text
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"normal"}
                            >
                        {client.sector}
                      </Text>
                    </Td>
                          <Td fontWeight={400} fontSize={"16px"}>
                            {client.homestate}
                          </Td>
                          <Td fontWeight={600} fontSize={"16px"}>
                      <Link
                        to={`/agency/client/${client.id}/client-background?navigateTo=${CLIENT_BACKGROUND_TABS.UPLOAD_STATEMENT}`}
                      >
                              <Button bg={"#0C543C"} mr={2}>
                          <Box
                            as="svg"
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-label="Custom Icon"
                            mr={2} 
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.4737 6.06254C17.3764 6.2975 17.2116 6.49833 17.0001 6.63961C16.7886 6.78089 16.54 6.85628 16.2857 6.85626H13.2857V11.5705C13.2857 11.9115 13.1503 12.2386 12.9091 12.4797C12.668 12.7208 12.341 12.8563 12 12.8563C11.659 12.8563 11.332 12.7208 11.0909 12.4797C10.8497 12.2386 10.7143 11.9115 10.7143 11.5705V6.85626H7.71429C7.46016 6.85603 7.2118 6.7805 7.00057 6.63921C6.78935 6.49792 6.62473 6.2972 6.52751 6.06241C6.43029 5.82762 6.40483 5.56928 6.45435 5.32002C6.50386 5.07077 6.62613 4.84178 6.80571 4.66197L11.0914 0.376256C11.3325 0.135483 11.6593 0.000244141 12 0.000244141C12.3407 0.000244141 12.6675 0.135483 12.9086 0.376256L17.1943 4.66197C17.3741 4.84165 17.4966 5.0706 17.5464 5.31989C17.5961 5.56918 17.5708 5.82761 17.4737 6.06254ZM0.857143 14.2637C0.629814 14.2637 0.411797 14.354 0.251051 14.5147C0.0903059 14.6755 0 14.8935 0 15.1208L0 21.4123C0 21.7499 0.066512 22.0843 0.195738 22.3963C0.324965 22.7083 0.514375 22.9918 0.753154 23.2305C0.991933 23.4693 1.27541 23.6587 1.58739 23.7879C1.89937 23.9172 2.23374 23.9837 2.57143 23.9837H21.4286C22.1106 23.9837 22.7646 23.7128 23.2468 23.2305C23.7291 22.7483 24 22.0942 24 21.4123V15.1208C24 14.8935 23.9097 14.6755 23.7489 14.5147C23.5882 14.354 23.3702 14.2637 23.1429 14.2637H18.0463C17.3643 14.2637 16.7102 14.5346 16.228 15.0168C15.7458 15.4991 15.4749 16.1531 15.4749 16.8351C15.4749 18.5048 13.8017 19.8043 11.9949 19.7923C10.2394 19.7803 8.61771 18.4757 8.61771 16.8351C8.61771 16.1531 8.3468 15.4991 7.86456 15.0168C7.38232 14.5346 6.72827 14.2637 6.04629 14.2637H0.857143Z"
                              fill="white"
                            />
                          </Box>
                          Upload Statement
                        </Button>
                      </Link>

                      <Link
                        to={`/agency/client/${client.id}/industry-activity`}
                      >
                              <Button bg={"#0C543C"}>
                                View Industry Insights
                              </Button>
                      </Link>
                    </Td>
                  </Tr>
                      );
              })}
            </Tbody>
        </Table>
    </TableContainer>
            </Box>
                              )}
      {!isAuthenticatedUser && (
        <>
          <Navbar />
        <Flex className="agency-main-content">
            <FloatingLeftNav />
          <Box
              width={{ base: "100%", lg: "80%" }}
            px={5}
            className="main-agency-tab"
          >
            <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <iframe
            width="90%"
            height="600"
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmGn4SFKWbU47ToM8WwLLCU%2F%255BEMBED%255D-Practice-Dashboard%3Fnode-id%3D49-2%26t%3DqYswWEmTbwcNipLv-1%26scaling%3Dmin-zoom%26content-scaling%3Dfixed%26page-id%3D0%253A1"
            title="Pratice Dashboard"
          ></iframe>
            </Flex>
          </Box>
        </Flex>
        </>
          )}
    </Stack>
  );
};

const LastRefreshedCell = ({
  currentClient,
  clientsWithMetrics,
  refreshClientMetricCache,
}) => {
  const clientMetricsFound = clientsWithMetrics?.find?.(
    (clientMP) => clientMP?.clientId === currentClient
  );

  let timeAgoRefreshed = "";

  if (clientMetricsFound?.lastRefreshed) {
    timeAgoRefreshed = new Date(clientMetricsFound.lastRefreshed);
    // timeAgoRefreshed = timeAgo(clientMetricsFound.lastRefreshed);
  }

  return (
    <>
    <HStack mb={2}>
        <Text color={"black"} pl={2} fontWeight={600} fontSize={"16px"}>
          Last Refresh
        </Text>
        <Text color={"black"} pl={2} fontWeight={400} fontSize={"16px"}>
          {timeAgoRefreshed.toString().substring(0, 15)}
        </Text>
    </HStack>
                          
      <Text
        color={"#0C543C"}
        fontWeight={600}
        fontSize={"16px"}
        borderBottom={"2px solid #0C543C"}
        h={"32px"}
        w={"141px"}
        ml={1}
        pl={-2}
        mb={2}
        cursor="pointer"
        onClick={() => refreshClientMetricCache(currentClient)}
      >
      <IconButton
          color={"#0C543C"}
          bg={"transparent"}
        _hover={{}}
        icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ margin: "0px", padding: "0px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8286 1.71793C12.8288 1.46381 12.9043 1.21545 13.0456 1.00422C13.1869 0.792994 13.3876 0.628375 13.6224 0.531156C13.8572 0.433936 14.1156 0.408477 14.3648 0.457993C14.6141 0.507509 14.8431 0.62978 15.0229 0.809362L19.1337 4.92365C19.3745 5.16472 19.5097 5.4915 19.5097 5.83222C19.5097 6.17293 19.3745 6.49972 19.1337 6.74079L15.0229 10.8516C14.8431 11.0312 14.6141 11.1535 14.3648 11.203C14.1156 11.2525 13.8572 11.2271 13.6224 11.1299C13.3876 11.0326 13.1869 10.868 13.0456 10.6568C12.9043 10.4456 12.8288 10.1972 12.8286 9.94308V7.54308H10C8.71243 7.54308 7.45376 7.92485 6.38313 8.64012C5.3125 9.35539 4.47798 10.372 3.98509 11.5615C3.4922 12.751 3.36306 14.06 3.61401 15.3229C3.86495 16.5858 4.48471 17.7459 5.39493 18.6566C6.30515 19.5673 7.46495 20.1877 8.72771 20.4393C9.99046 20.6909 11.2995 20.5624 12.4892 20.0702C13.679 19.5779 14.6961 18.7439 15.4119 17.6737C16.1278 16.6034 16.5102 15.3449 16.5109 14.0574C16.5109 13.8322 16.5552 13.6093 16.6414 13.4013C16.7275 13.1933 16.8538 13.0044 17.013 12.8452C17.1722 12.686 17.3611 12.5597 17.5691 12.4736C17.7771 12.3874 18 12.3431 18.2252 12.3431C18.4503 12.3431 18.6732 12.3874 18.8812 12.4736C19.0892 12.5597 19.2782 12.686 19.4373 12.8452C19.5965 13.0044 19.7228 13.1933 19.8089 13.4013C19.8951 13.6093 19.9394 13.8322 19.9394 14.0574C19.9391 16.0231 19.3559 17.9446 18.2635 19.5789C17.1711 21.2133 15.6187 22.4869 13.8025 23.239C11.9863 23.991 9.98782 24.1876 8.05987 23.8039C6.13193 23.4202 4.36105 22.4734 2.97117 21.0833C1.58128 19.6932 0.634816 17.9221 0.25144 15.9941C-0.131936 14.0661 0.0649976 12.0677 0.81734 10.2516C1.56968 8.4355 2.84364 6.88328 4.47814 5.7912C6.11263 4.69911 8.03425 4.11622 10 4.11622H12.8286V1.71793Z"
                fill="#0C543C"
              />
          </svg>
        }
      />
      Refresh Now
    </Text>
  </>
  );
};

const CurrentCriticalMetric = ({ currentClient, clientsWithMetrics }) => {
  const clientMetricsFound = clientsWithMetrics?.find?.(
    (clientMP) => clientMP?.clientId === currentClient
  );

  const selectedMetric = clientMetricsFound?.currentSelectedMetric;

  const metricPattern =
    clientMetricsFound?.criticalMetrics?.[selectedMetric]?.pattern;

  if (!selectedMetric) return <></>;

  return (
    <>
    <HStack spacing={4} pl={2} >
        <Box minW={"6rem"}>
          <Tag
            variant="solid"
            bg={"#30D5C8"}
            h={"24px"}
            color={"#030C0C"}
            fontWeight={"bold"}
          >
            {selectedMetric}
          </Tag>
      </Box>
        <Text color={"#B31B1B"} fontWeight={400} fontSize={"16px"}>
          {metricPattern}
        </Text>
    </HStack>
  </>
  );
};

const CriticalMetricsCell = ({
  currentClient,
  clientsWithMetrics,
  changeCurrentClientSelectedMetric,
}) => {
  const clientMetricsFound = clientsWithMetrics?.find?.(
    (clientMP) => clientMP?.clientId === currentClient
  );

  const criticalMetrics = [];
  for (const metric in clientMetricsFound?.criticalMetrics) {
    criticalMetrics.push(metric);
  }

  return (
    <Flex wrap="wrap" columnGap={1} rowGap={1} mx={1} mt={-1}>
      {criticalMetrics?.map?.((cm, index) => (
        <Tag
          key={`${cm}-${index}`}
          px={2}
          py={1}
          borderRadius={8}
          bg={
            clientMetricsFound?.currentSelectedMetric === cm
              ? "gray.300"
              : "#F2F7F7"
          }
          fontWeight={600}
          fontSize={"16px"}
          textTransform="capitalize"
          h={"24px"}
          color={
            clientMetricsFound?.currentSelectedMetric === cm
              ? "black"
              : "#4E6565"
          }
          _hover={{ cursor: "pointer" }}
          onClick={() => {
            changeCurrentClientSelectedMetric(currentClient, cm);
          }}
        >
          {cm}
        </Tag>
      ))}
    </Flex>
  );
};

const CriticalMetricsInternalControlCell = ({
  currentClient,
  clientsWithMetrics,
}) => {
  const clientMetricsFound = clientsWithMetrics?.find?.(
    (clientMP) => clientMP?.clientId === currentClient
  );

  const selectedMetric = clientMetricsFound?.currentSelectedMetric;

  const criticalMetricsInternalControl =
    clientMetricsFound?.criticalMetrics?.[selectedMetric]?.internalControls;

  return (
    <Box mx={2} mt={-2} fontWeight={400} fontSize={"14px"}>
      {criticalMetricsInternalControl}
    </Box>
  );
};

export default PracticeDashboard;
