import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getPreviewDataApi } from "../../../../api/organisationApi";
import { Stack, Flex, Button } from "@chakra-ui/react";
import { getRechartContainerWidth } from "../../../../utils/common";
import { formatCurrencyValue } from "../../../../utils/helperFunction";

function getDataForStackedBarChart(plPreview) {
  const stackedBarData = plPreview.map((data) => {
    const TR = data["total Revenue"];
    const CoR = data["cost of revenue"];
    const GP = data["gross profit"];
    const OE = data["operating expense"];
    const OI = data["operating income"];
    const NNonOIIE = data["net non operating interest income expense"];
    const PI = data["pretax income"];
    const TP = data["tax provision"];
    const NI = data["net income"];
    const StbarObj = {};
    StbarObj["total Revenue"] = { name: "total Revenue", black: TR };
    StbarObj["Cost of Revenue"] =
      TR > CoR
        ? { name: "Cost of Revenue", white: TR - CoR, Red1: CoR }
        : { name: "Cost of Revenue", Red2: TR - CoR, Red1: TR };
    StbarObj["Operating Expense"] =
      GP > OE
        ? { name: "Operating Expense", white: GP - OE, Red1: OE }
        : { name: "Operating Expense", Red2: GP - OE, Red1: GP };
    StbarObj["Net Non Operating Interest Income Expense"] =
      OI > NNonOIIE
        ? {
            name: "Net Non Operating Interest Income Expense",
            white: OI - NNonOIIE,
            Red1: NNonOIIE,
          }
        : {
            name: "Net Non Operating Interest Income Expense",
            Red2: OI - NNonOIIE,
            Red1: OI,
          };
    StbarObj["Tax Provision"] =
      PI > TP
        ? { name: "Tax Provision", white: PI - TP, Red1: TP }
        : { name: "Tax Provision", Red2: PI - TP, Red1: PI };
    StbarObj["Net Income"] = { name: "Net Income", black: NI };
    return { year: data.year, data: { ...StbarObj } };
  });
  return stackedBarData;
}

const StackedBarChart = () => {
  const dispatch = useDispatch();
  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );
  const { auditsheet,client } = organisationData;
  const { windowSize } = useSelector((state) => state.uiReducer);
  const [sakeyDataArr, setsakeyDataArrPL] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2017);
  const [chartData, setChartData] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    if (client) {
      setYears(client?.auditUploadYears);
    }
  }, [dispatch, client]);

  useEffect(() => {   
    if(auditsheet?.data){     // Process the fetched data and set it in state
        const newPlData = getDataForStackedBarChart(auditsheet.data.plPreview);
        setsakeyDataArrPL(newPlData);
    }
    
  }, [auditsheet]);
  useEffect(() => {
    if (sakeyDataArr.length > 0) {
      const yearData = sakeyDataArr.find((item) => item.year === selectedYear);

      if (yearData) {
        const data = yearData.data;

        // Extract the keys from the data object (e.g., "total Revenue", "Cost of Revenue", etc.)
        const categories = Object.keys(data);

        // Create an array of objects for each category
        const chartData = categories.map((category) => {
          return {
            name: category,
            black: data[category].black,
            white: data[category].white || 0,
            Red1: data[category].Red1,
            Red2: data[category].Red2,
          };
        });

        // Update the chartData state with the formatted data
        setChartData(chartData);
      }
    }
  }, [sakeyDataArr, selectedYear]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  return (
    <>
      <Stack>
        <ResponsiveContainer
          width={getRechartContainerWidth(windowSize.innerWidth)}
          aspect={1.2}
          style={{ background: " rgba(145 , 152, 229, 0.11)" }}
        >
          <BarChart
            data={chartData}
            // maxBarSize={10}
                margin={{
                  top: 15,
                  right: 60,
                  left: 20,
                  bottom: 5,
                }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" label={{ value: "Period", position: "insideBottom", dy: 10 }}/>
            <YAxis tickFormatter={formatCurrencyValue} />
            <Tooltip />
            <Legend />
            <Bar dataKey="black" stackId="a" fill="#000000" />
            <Bar dataKey="white" stackId="a" fill="steelblue" />
            <Bar dataKey="Red1" stackId="a" fill="#FF0000" />
            <Bar dataKey="Red2" stackId="a" fill="#FFA500" />
          </BarChart>
        </ResponsiveContainer>
        <Flex justifyContent={"space-evenly"} flexWrap={"wrap"} p={"18px 0"}>
          {sakeyDataArr.map((item) => (
            <Button
              key={item.year}
              onClick={() => handleYearChange(item.year)}
              variant={item.year === selectedYear ? "solid" : "outline"}
            >
              {item.year}
            </Button>
          ))}
        </Flex>
      </Stack>
    </>
  );
};

export default StackedBarChart;
