import { Box } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  return (
    <>
      <Box height={"65vh"} minH={"575px"} border={"1px solid purple"}>
        Banner
      </Box>
    </>
  );
};

export default Banner;
