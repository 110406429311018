import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
} from "@chakra-ui/react";

import { getsectorWiseResult } from "../../actions/home/homeActions";
import Loader from "../../components/Loader/Loader";
import { TYPES_OF_SYSTEMATIC_METRICS } from "../../constants/metrics";
import TotalWorkingCapital from "../Home/aggregateChartsSection/TotalWorkingCapital";
import IndustryLeaderInterview from "../../components/IndustryLeaderInterview/IndustryLeaderInterview";
import SystematicFactorsStackedAreaChart from "../../components/charts/SystematicFactorsCharts/SystematicFactorsStackedAreaChart";
import SystematicFactorsStackedBarChart from "../../components/charts/SystematicFactorsCharts/SystematicFactorsStackedBarChart";
import SystematicFactorsLineChart from "../../components/charts/SystematicFactorsCharts/SystematicFactorsLineChart";
import IndustryObservations from "../../components/IndustryObservations/IndustryObservations";
import AdvisoryNotes from "../../components/AdvisoryNotes/AdvisoryNotes";
import "./IndustryActivity.scss";
import SegmentRisks from "../Industry/SegmentRisks/SegmentRisks";

const FinancingAndGrowth = ({ graphData, params }) => {
  return (
    <>
      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalShareholdersEquity}
          heading="Total Shareholders Equity"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalDebt}
          heading="Total Debt"
        />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalSales}
          heading="Total Sales"
        />
      </GridItem>

      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalEbitda}
          heading="Total EBITDA"
        />
      </GridItem>
    </>
  );
};

const Assets = ({ graphData }) => {
  return (
    <>
      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTangibleAssets}
          heading="Total Tangible Assets"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalIntangibleAssets}
          heading="Total Intangible Assets"
        />
      </GridItem>

      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalFinancialAssets}
          heading="Total Financial Assets"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalLongTermAssetsUnderDevelopment}
          heading="Total Long Term Assets under development"
        />
      </GridItem>
    </>
  );
};

const CostHeads = ({ graphData }) => {
  return (
    <>
      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalMaterialsCost}
          heading="Total Materials Cost"
        />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalInterest}
          heading="Total Interest"
        />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalEmployeeCost}
          heading="Total Employee Cost"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalTax}
          heading="Total Tax"
        />
      </GridItem>
    </>
  );
};

const Trade = ({ graphData }) => {
  const averageInventorySorted = [...graphData.averageInventory]
    .sort((a, b) => a - b)
    .splice(1);

  const averageInventory = averageInventorySorted.map((d, i) => ({
    value: {
      averageInventory:
        (d.value.currentInventory + d.value.previousInventory) / 2,
    },
    year: d.year,
  }));

  return (
    <>
      {/* composite */}
      <GridItem maxW={"500px"} w="100%">
        <TotalWorkingCapital data={graphData.totalWorkingCapital} />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={averageInventory}
          heading="Average Inventory"
        />
      </GridItem>

      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTradeReceivables}
          heading="Total Trade Receivables"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalTradePayables}
          heading="Total Trade Payables"
        />
      </GridItem>
    </>
  );
};

const sectorWiseMetricKeys = [
  "totalShareholdersEquity",
  "totalDebt",
  "totalSales",
  "totalEbitda",
  "totalTangibleAssets",
  "totalIntangibleAssets",
  "totalFinancialAssets",
  "totalLongTermAssetsUnderDevelopment",
  "totalMaterialsCost",
  "totalInterest",
  "totalEmployeeCost",
  "totalTax",
  "averageInventory",
  "totalWorkingCapital",
  "totalTradeReceivables",
  "totalTradePayables",
];

const sectorWiseMetricKeysMapper = [
  {
    key: "totalShareholdersEquity",
    label: "Total Shareholders Equity",
    sublevel: [
      {
        skey: "equityShareCapital",
        slabel: "Equity Share Capital",
      },
      {
        skey: "otherEquity",
        slabel: "Other Equity",
      },
    ],
  },
  {
    key: "totalDebt",
    label: "Total Debt",
    sublevel: [
      {
        skey: "ltBorrowings",
        slabel: "Long Term Borrowings",
      },
      {
        skey: "stBorrowings",
        slabel: "Short Term Borrowings",
      },
    ],
  },
  {
    key: "totalSales",
    label: "Total Sales",
    sublevel: [
      {
        skey: "income",
        slabel: "Income",
      },
    ],
  },
  {
    key: "totalEbitda",
    label: "Total EBITDA",
    sublevel: [
      {
        skey: "financeCosts",
        slabel: "Finance Costs",
      },
      {
        skey: "profitOrLossBeforeEIT",
        slabel: "Profit Or Loss BeforeEIT",
      },
      {
        skey: "depreciationAndAmortisationExpense",
        slabel: "Depreciation And Amortisation Expense",
      },
    ],
  },
  {
    key: "totalTangibleAssets",
    label: "Total Tangible Assets",
    sublevel: [
      {
        skey: "investmentProperties",
        slabel: "Investment Properties",
      },
      {
        skey: "propertyPlantAndEquipment",
        slabel: "Property Plant And Equipment",
      },
      {
        skey: "rightOfUseAssets",
        slabel: "Right Of UseAssets",
      },
    ],
  },
  {
    key: "totalIntangibleAssets",
    label: "Total Intangible Assets",
    sublevel: [
      {
        skey: "goodwill",
        slabel: "Goodwill",
      },
    ],
  },
  {
    key: "totalFinancialAssets",
    label: "Total Financial Assets",
    sublevel: [
      {
        skey: "bankBalancesOtherThan32",
        slabel: "Bank Balances Other Than 32",
      },
      {
        skey: "cashAndCashEquivalents",
        slabel: "Cash And Cash Equivalents",
      },
      {
        skey: "currInvestments",
        slabel: "Current Investments",
      },
      {
        skey: "currLoans",
        slabel: "Current Loans",
      },
    ],
  },
  {
    key: "totalLongTermAssetsUnderDevelopment",
    label: "Total Long Term Assets under development",
    sublevel: [
      {
        skey: "capitalWorkInProgress",
        slabel: "Capital Work In Progress",
      },
      {
        skey: "intangibleAssetsUnderDevelopment",
        slabel: "Intangible Assets Under Development",
      },
    ],
  },
  {
    key: "totalMaterialsCost",
    label: "Total Materials Cost",
    sublevel: [
      {
        skey: "changesInStockOfFinishedGoodsWIPAndSIT",
        slabel: "Changes In Stock Of Finished Goods WIP And SIT",
      },
      {
        skey: "costOfMaterialsConsumed",
        slabel: "Cost Of Materials Consumed",
      },
      {
        skey: "purchasesOfStockInTrade",
        slabel: "Purchases Of Stock InTrade",
      },
    ],
  },
  {
    key: "totalInterest",
    label: "Total Interest",
    sublevel: [
      {
        skey: "financeCosts",
        slabel: "Finance Costs",
      },
    ],
  },
  {
    key: "totalEmployeeCost",
    label: "Total Employee Cost",
    sublevel: [
      {
        skey: "employeeBenefitExpense",
        slabel: "Employee Benefit Expense",
      },
    ],
  },
  {
    key: "totalTax",
    label: "Total Tax",
    sublevel: [
      {
        skey: "currentTax",
        slabel: "Current Tax",
      },
      {
        skey: "deferredTax",
        slabel: "Deferred Tax",
      },
    ],
  },
  {
    key: "averageInventory",
    label: "Average Inventory",
    sublevel: [
      {
        skey: "currentInventory",
        slabel: "Current Inventory",
      },
      {
        skey: "previousInventory",
        slabel: "Previous Inventory",
      },
    ],
  },
  {
    key: "totalWorkingCapital",
    label: "Total Working Capital",
    sublevel: [
      {
        skey: "totalCurrentAssets",
        slabel: "Total Current Assets",
      },
      {
        skey: "totalCurrentLiabilities",
        slabel: "Total Current Liabilities",
      },
    ],
  },
  {
    key: "totalTradeReceivables",
    label: "Total Trade Receivables",
    sublevel: [
      {
        skey: "currTradeReceivables",
        slabel: "Current Trade Receivables",
      },
      {
        skey: "nonCurrTradeReceivables",
        slabel: "Non-Current Trade Receivables",
      },
    ],
  },
  {
    key: "totalTradePayables",
    label: "Total Trade Payables",
    sublevel: [
      {
        skey: "currTradePayable",
        slabel: "Current Trade Payable",
      },
      {
        skey: "nonCurrTradePayable",
        slabel: "Non-Current Trade Payable",
      },
    ],
  },
];

const ObservationsModelPopUp = ({ graphData, params }) => {
  return (
    <>
      <Modal
        onClose={() => params.setObservationModelPopUp(false)}
        isOpen={params.observationModelPopUp}
        scrollBehavior={"inside"}
        isCentered={true}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody minH={"100px"}>
            {params.isLoading ? (
              <Loader />
            ) : (
              <>
                This graph shows the 'other equity' of a company over several
                years. For 'other equity': - A steady increase in the last two
                years by 55.06%. - Th overall trend is steady decrease by steady
                decrease - The Compound Annual Growth Rate (CAGR) for each year
                is as follows: - 0: Compound Annual Growth Rate (CAGR) is 2.18%,
                indicating an increase by 2.18% for 31-03-18. - 1: Compound
                Annual Growth Rate (CAGR) is 19.65%, indicating an increase by
                19.65% for 31-03-19. - 2: Compound Annual Growth Rate (CAGR) is
                -14.41%, indicating an decrease by 14.41% for 31-03-20. - 3:
                Compound Annual Growth Rate (CAGR) is -100.00%, indicating an
                decrease by 100.00% for 31-03-21. - 4: Compound Annual Growth
                Rate (CAGR) is -34.63%, indicating an decrease by 34.63% for
                31-03-22. - 5: Compound Annual Growth Rate (CAGR) is 55.06%,
                indicating an increase by 55.06% for 31-03-23. This graph shows
                the 'Equity share capital' of a company over several years. For
                'Equity share capital': - A steady increase in the last two
                years by 5.63%. - Th overall trend is steady increase by steady
                increase - The Compound Annual Growth Rate (CAGR) for each year
                is as follows: - 0: Compound Annual Growth Rate (CAGR) is
                79.12%, indicating an increase by 79.12% for 31-03-18. - 1:
                Compound Annual Growth Rate (CAGR) is 0.00%, indicating an
                decrease by 0.00% for 31-03-19. - 2: Compound Annual Growth Rate
                (CAGR) is 0.00%, indicating an decrease by 0.00% for 31-03-20. -
                3: Compound Annual Growth Rate (CAGR) is -24.52%, indicating an
                decrease by 24.52% for 31-03-21. - 4: Compound Annual Growth
                Rate (CAGR) is 40.61%, indicating an increase by 40.61% for
                31-03-22. - 5: Compound Annual Growth Rate (CAGR) is 5.63%,
                indicating an increase by 5.63% for 31-03-23.
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const IndustryActivity = () => {
  const { clientId } = useParams();

  const dispatch = useDispatch();

  const { loading, data } = useSelector(
    (state) => state.homeReducer.sectorWiseSection
  );

  const { clientsData = [] } = useSelector((state) => state.agencyReducer);

  const clientData = clientsData.find((client) => client.id === clientId);
  const [isLoading, setIsLoading] = useState(false);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [sectorWiseData, setSectorWiseData] = useState([]);

  const [graphData, setGraphData] = useState({
    totalShareholdersEquity: [],
    totalDebt: [],
    totalSales: [],
    totalEbitda: [],
    totalTangibleAssets: [],
    totalIntangibleAssets: [],
    totalFinancialAssets: [],
    totalLongTermAssetsUnderDevelopment: [],
    totalMaterialsCost: [],
    totalInterest: [],
    totalEmployeeCost: [],
    totalTax: [],
    averageInventory: [],
    totalWorkingCapital: [],
    totalTradeReceivables: [],
    totalTradePayables: [],
  });

  const [currentType, setCurrentType] = useState(
    Object.keys(TYPES_OF_SYSTEMATIC_METRICS)?.[0]
  );
  const [industryPayloadApi, setIndustryPayloadApi] = useState(null);

  useEffect(() => {
    dispatch(getsectorWiseResult());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setSectorOptions(
        data?.map((swr) => {
          return {
            name: swr.sector,
            value: swr.sector,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if(clientData){
    const sectorValue = sectorOptions.find((sector) => {
      return sector.value === clientData.sector;
    });
    sectorOptions && setSelectedSector(sectorValue?.value || null);
  }
  }, [sectorOptions, clientData]);

  useEffect(() => {
    if (selectedSector) {
      setSectorWiseData(
        data?.filter((swr) => swr.sector === selectedSector)[0]
      );
    }
  }, [selectedSector, data]);

  useEffect(() => {
    const yearsWiseData = sectorWiseData?.yearWiseData;
    const years = yearsWiseData && Object.keys(yearsWiseData);
    // debugger;
    var industryObservationPayload = {};
    if (yearsWiseData && years.length > 0) {
      sectorWiseMetricKeys.forEach((key) => {
        const obj = years.map((year) => {
          const d = Object.keys(yearsWiseData[year][key]);

          let total = 0;
          d.forEach((dd) => {
            total += yearsWiseData[year][key][dd];
          });

          return {
            year,
            value: { ...yearsWiseData[year][key] },
            total,
          };
        });

        setGraphData((currState) => {
          return {
            ...currState,
            [key]: obj,
          };
        });
        let particulars = [];
        let values = {};
        obj.forEach((data) => {
          // console.log(data);
          particulars.push(data.year);
          var value = data.value;
          var vkeys = Object.keys(value);
          vkeys.forEach((vkey)=>{
            if (!values[vkey]){
              values[vkey] = [];
            }
            values[vkey].push(value[vkey]);
          });
        });
        industryObservationPayload[key] = {
          particulars,
          ...values,
        };        
      });
      setIndustryPayloadApi(industryObservationPayload);
    }
  }, [sectorWiseData]);

  return (
    <>
      <SegmentRisks
      // setObserveTrends={setObserveTrends}
      // observe_trends={observe_trends}
      // // financial_growth={financial_growth}
      // setFinancialGrowth={setFinancialGrowth}
      // benchmark_now={benchmark_now}
      // setBenchmarkNow={setBenchmarkNow}
      />
      { false && <Box margin="0px 0px 0px 5px">
        <HStack
          w={"100%"}
          spacing={4}
          alignItems={"start"}
          className="shadowBox"
        >
          <Box w={"200px!important"} h="350px" overflowY={"auto"}>
            <VStack
              // w={'70%'}
              spacing={4}
              m={1}
              id={"segment_risks_type"}
              className="leftNavOptions"
              alignItems={"center"}
              p="10px 3px"
            >
              {Object.keys(TYPES_OF_SYSTEMATIC_METRICS)?.map?.((key, index) => (
                <Box m={"0px"} w={"100%"}>
                  <Text
                    className={
                      currentType === key
                        ? "riskSubTabs selected"
                        : "riskSubTabs"
                    }
                    cursor="pointer"
                    _hover={{}}
                    onClick={() => {
                      setCurrentType(key);
                    }}
                  >
                    {TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label}
                  </Text>
                </Box>
              ))}
            </VStack>
          </Box>
          <Box m={2} w={"50%"}>
          {loading ? (
            <>
                <Box h={"200px"}>
                  <Loader />
                </Box>
              </>
            ) : (
              <>
                <SegmentRisks
                // setObserveTrends={setObserveTrends}
                // observe_trends={observe_trends}
                // // financial_growth={financial_growth}
                // setFinancialGrowth={setFinancialGrowth}
                // benchmark_now={benchmark_now}
                // setBenchmarkNow={setBenchmarkNow}
                />

                <Box className="activity-content" gap={2}>
                  <Box
                    className="activity-content-graph"
                    // width={'75%'}
                    height={"395px"}
                    overflowY={"scroll"}
                  >
                    {data?.length > 0 && (
                      <>
                        <Box>
                          <Box
                            gridRow={1}
                            gridColumn={1}
                            p={"5px 0"}
                            justifyItems={"center"}
                            maxHeight={"500px"}
                          >
                            <Grid
                              templateColumns="repeat(1, 1fr)"
                              gap={4}
                              p={"5px 0"}
                              className="activity-content-graph-data"
                              // overflowX={"auto"}
                              // justifyItems={"center"}
                            >
                              {currentType === "financingAndGrowth" && (
                                <FinancingAndGrowth graphData={graphData} />
                              )}

                              {currentType === "assets" && (
                                <Assets graphData={graphData} />
                              )}

                              {currentType === "costHeads" && (
                                <CostHeads graphData={graphData} />
                              )}

                              {currentType === "trade" && (
                                <Trade graphData={graphData} />
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box w={{ base: "35%" }}>
            <Box id={"segment_risks_observations"} height={"100%"}>
              <Box height={"50%"}>
                <Box id={"segment_risks_observations_content"}>
                      <IndustryObservations
                        industryPayload={industryPayloadApi}
                        industrySeqLoad={sectorWiseMetricKeysMapper}
                      />
                  </Box>
                </Box>
              <Box height={"50%"}>
                <Box id={"segment_risks_advisory_content"}>
                  <AdvisoryNotes industryId={""} />
                </Box>
      </Box>
              </Box>
                            </Box>
                  </HStack>
      </Box>}
    </>
  );
};

export default IndustryActivity;
