export const timeAgo = (date) => {
  const currentDate = new Date();
  const timestamp = new Date(date);
  const seconds = Math.floor((currentDate - timestamp) / 1000);

  // Calculate time intervals
  const intervals = [
    { label: 'year', value: 31536000 },
    { label: 'month', value: 2592000 },
    { label: 'week', value: 604800 },
    { label: 'day', value: 86400 },
    { label: 'hour', value: 3600 },
    { label: 'minute', value: 60 },
    { label: 'second', value: 1 }
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const count = Math.floor(seconds / interval.value);

    if (count >= 1) {
      return count === 1 ? `${count} ${interval.label} ago` : `${count} ${interval.label}s ago`;
    }
  }

  return 'Just now';
};

export const toCamelCase = (str) => {
  // Replace any hyphens or underscores with a space
  str = str.replace(/[-_]/g, " ");

  // Convert the string to title case
  str = str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Replace any spaces with an empty string and make the first character lowercase
  str = str.replace(/\s+/g, "");
  str = str.charAt(0).toLowerCase() + str.slice(1);

  return str;
};

export const regionalLanguageList = [
  {
    label: "English",
    value: "english",
  },
  // {
  //   label: "Hindi (हिन्दी)",
  //   value: "hindi",
  // },
  // {
  //   label: "Bengali (বাংলা)",
  //   value: "bengali",
  // },
  // {
  //   label: "Telugu (తెలుగు)",
  //   value: "telugu",
  // },
  // {
  //   label: "Marathi (मराठी)",
  //   value: "marathi",
  // },
  // {
  //   label: "Tamil (தமிழ்)",
  //   value: "tamil",
  // },
  // {
  //   label: "Urdu (اردو)",
  //   value: "urdu",
  // },
  // {
  //   label: "Gujarati (ગુજરાતી)",
  //   value: "gujarati",
  // },
  // {
  //   label: "Kannada (ಕನ್ನಡ)",
  //   value: "kannada",
  // },
  // {
  //   label: "Odia (ଓଡ଼ିଆ)",
  //   value: "odia",
  // },
  // {
  //   label: "Malayalam (മലയാളം)",
  //   value: "malayalam",
  // },
  // {
  //   label: "Punjabi (ਪੰਜਾਬੀ)",
  //   value: "punjabi",
  // },
  // {
  //   label: "Assamese (অসমীয়া)",
  //   value: "assamese",
  // },
  // {
  //   label: "Sindhi (सिन्धी / سنڌي)",
  //   value: "sindhi",
  // },
  // {
  //   label: "Nepali (नेपाली)",
  //   value: "nepali",
  // },
  // {
  //   label: "Konkani (कोंकणी / ಕೊಂಕಣಿ)",
  //   value: "konkani",
  // },
  // {
  //   label: "Manipuri (মণিপুরী / ꯃꯅꯤꯄꯣꯟ)",
  //   value: "manipuri",
  // },
  // {
  //   label: "Sanskrit (संस्कृत)",
  //   value: "sanskrit",
  // },
  // {
  //   label: "Dogri (डोगरी)",
  //   value: "dogri",
  // },
];

export const getRechartContainerWidth = (windowsWidth) => {
  if (windowsWidth >= 670) {
    return 600;
  } else if (windowsWidth > 400 && windowsWidth < 670) {
    return windowsWidth - 30;
  }

  return 400 - 30;
};
