import { Box, Button, Heading, Select } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { allUnits } from "../../assets/auditSheetFormats";

const Instructions = ({
  balanceSheetRefOnClickHandler,
  statementRefOnClickHandler,
  setUnit,
}) => {
  return (
        <>
              <Box m={"17px 0"}>
        <Heading size={"md"}>
          Follow these steps to successfully upload your Audit sheets
        </Heading>
      </Box>

      <Box alignItems={"center"} p={"4px 0"}>
        1. Download Balance Sheet Chat template from this{" "}
        <Link
          to={
            "https://docs.google.com/spreadsheets/d/1eFh3R4u_Zy0mBBmfcmLSwp_kc-b6gXJldIuSJypYIhE/edit#gid=0"
          }
          target="_blank"
        >
          <Button variant={"link"}>link</Button>
        </Link>
      </Box>

      <Box alignItems={"center"} p={"4px 0"}>
        2. Download Standalone Profit and Loss Statement template from this{" "}
        <Link
          to={
            "https://docs.google.com/spreadsheets/d/15PzZApEf5ye-yzwBVgZkkl6yzHX3pGPCHSYcsEc2-6M/edit#gid=0"
          }
          target="_blank"
        >
          <Button variant={"link"}>link</Button>
        </Link>
      </Box>

      <Box alignItems={"center"} p={"4px 0"}>
        3. Select your unit in ₹ only:{" "}
        <Select
          focusBorderColor="primary.400"
          size={"sm"}
          ml={"10px"}
          borderRadius={"10px"}
          w={"120px"}
          onChange={(e) => setUnit(e.target.value)}
          cursor={"pointer"}
        >
          <option value={""}>--Select</option>
          {allUnits.map((unit, index) => (
            <option key={index} value={unit.value}>
              {unit.name}
            </option>
          ))}
        </Select>
      </Box>

      <Box alignItems={"center"} p={"4px 0"}>
        4. Fill your details in the Balance Sheet template and upload it to the{" "}
        <Button
          variant={"ghost"}
          ml={"5px"}
          p={"4px"}
          onClick={balanceSheetRefOnClickHandler}
        >
          Balance Sheet Upload section
        </Button>
      </Box>

      <Box alignItems={"center"} p={"4px 0"}>
        5. Fill your details in the Standalone Profit and Loss Statement
        template and upload it to the{" "}
        <Button
          variant={"ghost"}
          ml={"5px"}
          p={"4px"}
          onClick={statementRefOnClickHandler}
        >
          Income Statement Upload section
        </Button>
      </Box>

      <Box alignItems={"center"} p={"4px 0"} color={"red.500"}>
        *Note: Do not modify any of the template structure provided
      </Box>
    </>
  );
};

export default Instructions;
