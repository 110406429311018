import { Navigate, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import AboutUs from "./pages/Common/AboutUs";
import ContactUs from "./pages/Common/ContactUs";
import Login from "./pages/Auth/Agency/Login";
import NavigateToLogin from "./pages/Auth/NavigateToLogin";
import OrgLogin from "./pages/Auth/Organisation/OrgLogin";
import OrgRegister from "./pages/Auth/Organisation/OrgRegister";
import Register from "./pages/Auth/Agency/Register";
import ForgotPasword from "./pages/Auth/Agency/ForgotPasword";
import OrgForgetPassword from "./pages/Auth/Organisation/OrgForgetPassword";
import UpdatePassword from "./pages/Auth/Agency/UpdatePassword";
import OrgUpdatePassword from "./pages/Auth/Organisation/OrgUpdatePassword";
import ResetPassword from "./pages/Auth/Agency/ResetPassword";
import OrgResetPassword from "./pages/Auth/Organisation/OrgResetPassword";
import AgencyHome from "./pages/Agency/AgencyHome";
import OrganisationHome from "./pages/Organisation/Dashboard/OrganisationHome";
import CreateClient from "./pages/Agency/CreateClient";
import AuditSheetUploader from "./pages/Audit/AuditSheetUploader";
import AuditUploadSuccess from "./pages/Audit/AuditUploadSuccess";
import AuditSheetPreview from "./pages/Audit/AuditSheetPreview";
import PastPerformance from "./pages/Agency/PastPerformance";

import PracticeDashboard from "./pages/PractiveDashboard/PracticeDashboard";
import CreateClient2 from "./pages/Agency/CreateClient2";
import ClientBackground from "./pages/Agency/ClientBackground";
import IndustryActivity from "./pages/Agency/IndustryActivity";
import RiskAssessment from "./pages/Agency/RiskAssessment";

// import HomeLayout from "./layouts/HomeLayout/HomeLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import AgencyLayout from "./layouts/AgencyLayout/AgencyLayout";
import AgencyLayout2 from "./layouts/AgencyLayout2/AgencyLayout2";
import ManageClientLayout from "./layouts/ManageClientLayout/ManageClientLayout";

import RegisterNew from "./pages/Register";
import IndustryLeaderInterviews from "./pages/IndustryLeaderInterviews/IndustryLeaderInterviews";
import ClientLayout from "./layouts/ClientLayout/ClientLayout";
import ManageSMEDashboardLayout from "./layouts/ManageDashboardLayout/ManageDashboardLayout";
import SMEBackground from "./pages/Client/SMEBackground.jsx";
import SMEIndustryActivity from "./pages/Client/IndustryActivity.jsx";
import SMERiskAssessment from "./pages/Client/RiskAssessment.jsx";
import IndustryDashboard from "./pages/Industry/IndustryDashboard.jsx";
import IndustryLayout from "./layouts/IndustryLayout/IndustryLayout.jsx";
import HomeV1 from "./pages/Home/HomeV1.jsx";
import Layout from "./shared/Layout/Layout.jsx";
import UserProfile from "./pages/Auth/Agency/UserProfile.jsx";

export const HomeRoutes = (
  // <Route path="/" element={<HomeLayout />}>
  <>
    <Route
      path="/:agencyId/industry-leader-interviews"
      element={<IndustryLeaderInterviews />}
    />
    {/* <Route element={<IndustryLayout />}>
      <Route path="/dashboard1" element={<IndustryDashboard />} />
    </Route> */}
    <Route element={<Layout />}>
      <Route path="/dashboard" element={<IndustryDashboard />} />
    </Route>
    <Route path="/home" element={<HomeV1 />} />
    <Route path="/home1" element={<Home />} />
    <Route path="https://beta.sylvr.co.in/about" element={<AboutUs />} />
    <Route path="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us" element={<ContactUs />} />
    <Route path="/" element={<Login />} />
    <Route path="/register-new" element={<RegisterNew />} />
    <Route path="/practice-dashboard" element={<PracticeDashboard />} />
    <Route path="/linkedinlogin" element={<PracticeDashboard />} />
  </>
  // </Route>
);

export const IndustryRoutes = (
  <Route path="industry/:industryId" element={<Layout />}>
    <Route path="dashboard" element={<IndustryDashboard />} />
    <Route path="industry-activity" element={<IndustryDashboard />} />
    <Route path="risk-assessment" element={<RiskAssessment />} />
  </Route>
);

export const AgencyRoutes2 = (
  <Route path="agency">
    <Route path="industry/:industryId" element={<Layout />}>
      <Route path="industry-activity" element={<IndustryDashboard />} />
      <Route path="risk-assessment" element={<RiskAssessment />} />
    </Route>
    <Route element={<AgencyLayout2 />}>
    <Route path="client-new" element={<CreateClient2 />} />
      {/* <Route path="practice-dashboard" element={<PracticeDashboard />} /> */}
    <Route path="client/:clientId" element={<ManageClientLayout />}>
      <Route path="client-background" element={<ClientBackground />} />
      <Route path="industry-activity" element={<IndustryActivity />} />
      <Route path="risk-assessment" element={<RiskAssessment />} />
    </Route>
  </Route>
    <Route element={<Layout />}>
      <Route path="profile" element={<UserProfile />} />
      <Route path="practice-dashboard" element={<PracticeDashboard />} />
    </Route>
  </Route>
);

export const ClientRoutes2 = (
  <Route path="client" element={<ClientLayout />}>
    <Route path=":clientId" element={<ManageSMEDashboardLayout />}>
      <Route path="sme-background" element={<SMEBackground />} />
      <Route path="sme-industry-activity" element={<SMEIndustryActivity />} />
      <Route path="sme-risk-assessment" element={<SMERiskAssessment />} />
    </Route>
  </Route>
);

export const AgencyRoutes = (
  <Route element={<AgencyLayout />}>
    <Route path="/agency/:id" element={<AgencyHome />} />
    <Route path="/agency/practice-dashboard" element={<PracticeDashboard />} />
    <Route exact path="/agency/client/new" element={<CreateClient />} />
    <Route
      path="/agency/client/pastPerformance"
      element={<PastPerformance />}
    />
    <Route
      path="/agency/client/auditsheet/uploadauditsheet"
      element={<AuditSheetUploader />}
    />
    <Route path="audit/preview/:id" element={<AuditSheetPreview />} />
    <Route path="/audit/success" element={<AuditUploadSuccess />} />
    <Route path="/organisation" element={<OrganisationHome />} />
  </Route>
);

export const OrganisationRoutes = (
  <>
    <Route path="/organisation/:id" element={<OrganisationHome />} />
    <Route path="/audit/upload/:id" element={<AuditSheetUploader />} />
    <Route path="audit/preview/:id" element={<AuditSheetPreview />} />
    <Route path="/audit/success" element={<AuditUploadSuccess />} />
  </>
);

export const LoginRoutes = (
  <>
    <Route path="/" element={<Login />} />
    {/* <Route path="/register-new" element={<RegisterNew />} /> */}
    <Route path="/" element={<AuthLayout />}>
      {/* agency routes */}
      <Route path="/register" element={<Register />} />
      <Route exact path="/password/forgot" element={<ForgotPasword />} />
      <Route path="/password/reset/:token" element={<ResetPassword />} />
      <Route exact path="/password/update" element={<UpdatePassword />} />

      {/* organiisation routes */}
      <Route exact path="/organisation/login" element={<OrgLogin />} />
      <Route exact path="/organisation/register" element={<OrgRegister />} />
      <Route
        exact
        path="/organisation/password/forgot"
        element={<OrgForgetPassword />}
      />
      <Route
        path="/organisation/password/reset/:token"
        element={<OrgResetPassword />}
      />
      <Route
        exact
        path="/organisation/password/update"
        element={<OrgUpdatePassword />}
      />
    </Route>

    <Route path="*" element={<NavigateToLogin />} />
  </>
);

export const NotFoundRoute = (
  <Route path="*" element={<Navigate to="/" replace={true} />} />
);
