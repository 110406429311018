import { BALANCE_SHEET, PROFIT_LOSS_STATEMENT } from "../../utils/Stringconstant";
import { tolerantDiff } from "../../utils/helperFunction";

const returnDataForEachYear = (firstRow, rowNo, sheetData,unit) => {
  const yearWiseData = [];
  for (let i = firstRow.length-1; i >=1 ; i--) {
    yearWiseData.push({
      label: firstRow[i],
      value: unit ? (sheetData[rowNo][i]*unit) : sheetData[rowNo][i],
    });
  }
  return yearWiseData;
};

const formattedYearInAuditSheet = (dateString, boxName) => {
  if (boxName === BALANCE_SHEET) {
    const match = dateString.match(/(\d{2})-(\w{3})-(\d{2})/);

    if (match) {
      const year = match[3];
      return (`20${year}`);
    }
  }
  if (boxName === PROFIT_LOSS_STATEMENT) {
    const match = dateString.match(/(\d{4})-(\d{2})/);
    if (match) {
      const year = match[2];
      return (`20${year}`);
    }
  }
  return null;
};

// format balance sheet data for sending to server.
export const balanceSheetReqBody = (balanceSheetData,unit=null) => {
  const firstRow = balanceSheetData[0];
  return {
    assets: {
      nonCurrentAssets: {
        propertyPlantAndEquipment: {
          year: returnDataForEachYear(firstRow, 2, balanceSheetData,unit),
        },
        rightOfUseAssets: {
          year: returnDataForEachYear(firstRow, 3, balanceSheetData,unit),
        },
        capitalWorkInProgress: {
          year: returnDataForEachYear(firstRow, 4, balanceSheetData,unit),
        },
        investmentProperties: {
          year: returnDataForEachYear(firstRow, 5, balanceSheetData,unit),
        },
        goodwill: {
          year: returnDataForEachYear(firstRow, 6, balanceSheetData,unit),
        },
        otherIntangibleAssets: {
          year: returnDataForEachYear(firstRow, 7, balanceSheetData,unit),
        },
        intangibleAssetsUnderDevelopment: {
          year: returnDataForEachYear(firstRow, 8, balanceSheetData,unit),
        },
        biologicalAssetsOtherThanBearerPlants: {
          year: returnDataForEachYear(firstRow, 9, balanceSheetData,unit),
        },
        financialAssets: {
          investments: {
            year: returnDataForEachYear(firstRow, 12, balanceSheetData,unit),
          },
          tradeReceivables: {
            year: returnDataForEachYear(firstRow, 13, balanceSheetData,unit),
          },
          loans: {
            year: returnDataForEachYear(firstRow, 14, balanceSheetData,unit),
          },
          otherFinancialAssets: {
            year: returnDataForEachYear(firstRow, 15, balanceSheetData,unit),
          },
        },
        deferredTaxAssets: {
          year: returnDataForEachYear(firstRow, 17, balanceSheetData,unit),
        },
        otherNonCurrentAssets: {
          year: returnDataForEachYear(firstRow, 18, balanceSheetData,unit),
        },
        subTotal: {
          year: returnDataForEachYear(firstRow, 20, balanceSheetData,unit),
        },
      },
      currentAssets: {
        inventories: {
          year: returnDataForEachYear(firstRow, 23, balanceSheetData,unit),
        },
        financialAssets: {
          investments: {
            year: returnDataForEachYear(firstRow, 25, balanceSheetData,unit),
          },
          tradeReceivables: {
            year: returnDataForEachYear(firstRow, 26, balanceSheetData,unit),
          },
          cashAndCashEquivalents: {
            year: returnDataForEachYear(firstRow, 27, balanceSheetData,unit),
          },
          bankBalancesOtherThan32: {
            year: returnDataForEachYear(firstRow, 28, balanceSheetData,unit),
          },
          loans: {
            year: returnDataForEachYear(firstRow, 29, balanceSheetData,unit),
          },
          otherFinancialAssets: {
            year: returnDataForEachYear(firstRow, 30, balanceSheetData,unit),
          },
        },
        currentTaxAssets: {
          year: returnDataForEachYear(firstRow, 32, balanceSheetData,unit),
        },
        otherCurrentAssets: {
          year: returnDataForEachYear(firstRow, 33, balanceSheetData,unit),
        },
        subTotal: {
          year: returnDataForEachYear(firstRow, 35, balanceSheetData,unit),
        },
      },
      nonCurrentAssetsClassifiedAsHeldForSale: {
        year: returnDataForEachYear(firstRow, 37, balanceSheetData,unit),
      },
      totalAssets: {
        year: returnDataForEachYear(firstRow, 39, balanceSheetData,unit),
      },
    },
    equityAndLiabilities: {
      equity: {
        equityShareCapital: {
          year: returnDataForEachYear(firstRow, 45, balanceSheetData,unit),
        },
        otherEquity: {
          year: returnDataForEachYear(firstRow, 46, balanceSheetData,unit),
        },
        totalEquity: {
          year: returnDataForEachYear(firstRow, 47, balanceSheetData,unit),
        },
      },
      liabilities: {
        nonCurrentLiabilities: {
          financialLiabilities: {
            borrowings: {
              year: returnDataForEachYear(firstRow, 52, balanceSheetData,unit),
            },
            tradePayable: {
              year: returnDataForEachYear(firstRow, 53, balanceSheetData,unit),
            },
            otherFinancialLiabilities: {
              year: returnDataForEachYear(firstRow, 54, balanceSheetData,unit),
            },
          },
          provisions: {
            year: returnDataForEachYear(firstRow, 56, balanceSheetData,unit),
          },
          deferredTaxLiabilities: {
            year: returnDataForEachYear(firstRow, 57, balanceSheetData,unit),
          },
          otherNonCurrentLiabilities: {
            year: returnDataForEachYear(firstRow, 58, balanceSheetData,unit),
          },
          totalNonCurrentLiabilities: {
            year: returnDataForEachYear(firstRow, 60, balanceSheetData,unit),
          },
        },
        currentLiabilities: {
          financialLiabilities: {
            borrowings: {
              year: returnDataForEachYear(firstRow, 64, balanceSheetData,unit),
            },
            tradePayables: {
              year: returnDataForEachYear(firstRow, 65, balanceSheetData,unit),
            },
            otherFinancialLiabilities: {
              year: returnDataForEachYear(firstRow, 66, balanceSheetData,unit),
            },
          },
          provisions: {
            year: returnDataForEachYear(firstRow, 68, balanceSheetData,unit),
          },
          otherCurrentLiabilities: {
            year: returnDataForEachYear(firstRow, 69, balanceSheetData,unit),
          },
          currentTaxLiabilities: {
            year: returnDataForEachYear(firstRow, 70, balanceSheetData,unit),
          },
          totalCurrentLiabilities: {
            year: returnDataForEachYear(firstRow, 72, balanceSheetData,unit),
          },
        },
      },
      totalEquityAndLiabilities: {
        year: returnDataForEachYear(firstRow, 74, balanceSheetData,unit),
      },
    },
  };
};

// format profit loss sheet data for sending to server.
export const IncomeStatReqBody = (profitLossSheetData,unit=null) => {
  const firstRow = profitLossSheetData[0];
  return {
    continuingOperations: {
      revenueFromOperations: {
        year: returnDataForEachYear(firstRow, 2, profitLossSheetData,unit),
      },
      otherIncome: {
        year: returnDataForEachYear(firstRow, 3, profitLossSheetData,unit),
      },
      totalRevenue: {
        year: returnDataForEachYear(firstRow, 4, profitLossSheetData,unit),
      },
    },

    expenses: {
      costOfMaterialsConsumed: {
        year: returnDataForEachYear(firstRow, 7, profitLossSheetData,unit),
      },
      purchasesOfStockInTrade: {
        year: returnDataForEachYear(firstRow, 8, profitLossSheetData,unit),
      },
      changesInStockOfFinishedGoodsWorkInProgressAndStockInTrade: {
        year: returnDataForEachYear(firstRow, 9, profitLossSheetData,unit),
      },
      employeeBenefitExpense: {
        year: returnDataForEachYear(firstRow, 10, profitLossSheetData,unit),
      },
      financeCosts: {
        year: returnDataForEachYear(firstRow, 11, profitLossSheetData,unit),
      },
      depreciationAndAmortisationExpense: {
        year: returnDataForEachYear(firstRow, 12, profitLossSheetData,unit),
      },
      impairmentExpensesLosses: {
        year: returnDataForEachYear(firstRow, 13, profitLossSheetData,unit),
      },
      otherExpenses: {
        year: returnDataForEachYear(firstRow, 14, profitLossSheetData,unit),
      },
      totalExpenses: {
        year: returnDataForEachYear(firstRow, 15, profitLossSheetData,unit),
      },
    },

    profitOrLossBeforeExceptionalItemsAndTax: {
      year: returnDataForEachYear(firstRow, 17, profitLossSheetData,unit),
    },
    exceptionalItems: {
      year: returnDataForEachYear(firstRow, 18, profitLossSheetData,unit),
    },
    profitOrLossBeforeTax: {
      year: returnDataForEachYear(firstRow, 19, profitLossSheetData,unit),
    },

    taxExpense: {
      currentTax: {
        year: returnDataForEachYear(firstRow, 22, profitLossSheetData,unit),
      },
      deferredTax: {
        year: returnDataForEachYear(firstRow, 23, profitLossSheetData,unit),
      },
      totalTaxExpense: {
        year: returnDataForEachYear(firstRow, 24, profitLossSheetData,unit),
      },
    },

    profitOrLossAfterTaxFromContinuingOperations: {
      year: returnDataForEachYear(firstRow, 26, profitLossSheetData,unit),
    },

    discontinuedOperations: {
      profitOrLossFromDiscontinuedOperations: {
        year: returnDataForEachYear(firstRow, 29, profitLossSheetData,unit),
      },
      taxExpenseOfDiscontinuedOperations: {
        year: returnDataForEachYear(firstRow, 30, profitLossSheetData,unit),
      },
      profitOrLossAfterTaxFromDiscontinuedOperations: {
        year: returnDataForEachYear(firstRow, 32, profitLossSheetData,unit),
      },
    },
    profitOrLossForThePeriodContandDiscont: {
      year: returnDataForEachYear(firstRow, 33, profitLossSheetData,unit),
    },

    otherComprehensiveIncome: {
      itemsThatWillNotBeReclassifiedToProfitOrLoss: {
        changesInRevaluationSurplus: {
          year: returnDataForEachYear(firstRow, 37, profitLossSheetData,unit),
        },
        remeasurementsOfTheDefinedBenefitLiabilitiesOrAsset: {
          year: returnDataForEachYear(firstRow, 38, profitLossSheetData,unit),
        },
        equityInstrumentsThroughOtherComprehensiveIncome: {
          year: returnDataForEachYear(firstRow, 39, profitLossSheetData,unit),
        },
        fairValueChangesRelatingToOwnCreditRisk: {
          year: returnDataForEachYear(firstRow, 40, profitLossSheetData,unit),
        },
        others: {
          year: returnDataForEachYear(firstRow, 41, profitLossSheetData,unit),
        },
        incomeTaxRelatingToItemsThatWillNotBeReclassifiedToProfitOrLoss: {
          year: returnDataForEachYear(firstRow, 42, profitLossSheetData,unit),
        },
      },

      itemsThatMayBeReclassifiedToProfitOrLoss: {
        exchangeDifferencesInTranslatingTheFinancialStatementsOfForeignOperations:
          {
            year: returnDataForEachYear(firstRow, 45, profitLossSheetData,unit),
          },
        debtInstrumentsThroughOtherComprehensiveIncome: {
          year: returnDataForEachYear(firstRow, 46, profitLossSheetData,unit),
        },
        effectivePortionOfGainsAndLossOnDesignatedPortionOfHedgingInstrumentsInACashFlowHedge:
          {
            year: returnDataForEachYear(firstRow, 47, profitLossSheetData,unit),
          },
        shareOfOtherComprehensiveIncomeOfEquityAccountedInvestees: {
          year: returnDataForEachYear(firstRow, 48, profitLossSheetData,unit),
        },
        others: {
          year: returnDataForEachYear(firstRow, 49, profitLossSheetData,unit),
        },

        incomeTaxOnItemsThatMayBeReclassifiedToProfitOrLoss: {
          year: returnDataForEachYear(firstRow, 51, profitLossSheetData,unit),
        },
      },
    },

    totalComprehensiveIncomeForThePeriod: {
      year: returnDataForEachYear(firstRow, 53, profitLossSheetData,unit),
    },

    earningsPerEquityShareForContinuingOperation: {
      basic: {
        year: returnDataForEachYear(firstRow, 56, profitLossSheetData,unit),
      },
      diluted: {
        year: returnDataForEachYear(firstRow, 57, profitLossSheetData,unit),
      },
    },

    earningsPerEquityShareForDiscontinuedOperation: {
      basic: {
        year: returnDataForEachYear(firstRow, 60, profitLossSheetData,unit),
      },
      diluted: {
        year: returnDataForEachYear(firstRow, 61, profitLossSheetData,unit),
      },
    },

    earningsPerEquityShareForContinuingAndDiscontinuedOperations: {
      basic: {
        year: returnDataForEachYear(firstRow, 64, profitLossSheetData,unit),
      },
      diluted: {
        year: returnDataForEachYear(firstRow, 65, profitLossSheetData,unit),
      },
    },
  };
};

/**
 * Formats and returns a 2D array by filtering valid columns and modifying year values.
 *
 * @param {Array<Array>} sheetData - The 2D array of sheet data.
 * @param {string} boxName - The name of the box being processed.
 * @return {Array<Array>} formattedResult - The formatted 2D array with valid columns and modified year values.
 */
export const returnFormattedArr = (sheetData, boxName) => {
  const result = [];
  const validCols = [0];
  const firstValue = sheetData[0][0];
  const allYears = [];

  sheetData[0] = sheetData[0]
    .slice(1)
    .filter((val, index) => {
      if (!val) {
        return false;
      }
      validCols.push(index + 1);
      return true;
    })
    .map((val) => {
      const year = formattedYearInAuditSheet(val, boxName);
      allYears.push(year);
      return year;
    });
  result.push([firstValue, ...sheetData[0]]);

  sheetData.slice(1).forEach((r) => {
    const row = r.filter((val, i) => validCols.includes(i));
    result.push(row);
  });
  return {result, allYears};
};

/**
 * Returns array of yearwise data(actual and expected) for all formula rows.
 *
 * @param {Object} param - The request body containing financial data.
 * @return {Array<Object>} - yearWiseData - year, actual, expected value of formula row metrics for each year
 */
const returnActualAndDesiredValuesForBalanceSheet = (param) => {
  const years = param.assets.totalAssets.year.map((item) => item.label);
  const {
    assets: {
      nonCurrentAssets,
      currentAssets,
      totalAssets,
      nonCurrentAssetsClassifiedAsHeldForSale,
    },
    equityAndLiabilities: {
      equity,
      liabilities: { nonCurrentLiabilities, currentLiabilities },
      totalEquityAndLiabilities: actualResultTotalEquityAndLiabilities,
    },
  } = param;

  const {
    biologicalAssetsOtherThanBearerPlants,
    capitalWorkInProgress,
    deferredTaxAssets,
    financialAssets: {
      investments: nonCurrentInvestments,
      loans: nonCurrentLoans,
      otherFinancialAssets: nonCurrentotherFinancialAssets,
      tradeReceivables: nonCurrentTradeReceivables,
    },
    goodwill,
    intangibleAssetsUnderDevelopment,
    investmentProperties,
    otherNonCurrentAssets,
    otherIntangibleAssets,
    propertyPlantAndEquipment,
    rightOfUseAssets,
    subTotal: actualResultNonCurrAssets,
  } = nonCurrentAssets;

  const {
    subTotal: actualResultCurrAssets,
    currentTaxAssets,
    financialAssets: {
      bankBalancesOtherThan32,
      cashAndCashEquivalents,
      investments: currentInvestments,
      loans: currentLoans,
      otherFinancialAssets: currentotherFinancialAssets,
      tradeReceivables: currenttradeReceivables,
    },
    inventories,
    otherCurrentAssets,
  } = currentAssets;

  const {
    equityShareCapital,
    otherEquity,
    totalEquity: actualResultTotalEquity,
  } = equity;

  const {
    totalNonCurrentLiabilities: actualResultNonCurrLiabiites,
    deferredTaxLiabilities,
    financialLiabilities: {
      borrowings: nonCurrLiabilitiesBorrowings,
      otherFinancialLiabilities: nonCurrLiabilitiesOtherFinanacial,
      tradePayable: nonCurrLiabilitiesTradePayable,
    },
    otherNonCurrentLiabilities,
    provisions: nonCurrLiabilitiesProvisions,
  } = nonCurrentLiabilities;

  const {
    totalCurrentLiabilities: actualResultCurrLiabilities,
    currentTaxLiabilities,
    financialLiabilities: {
      borrowings: currLiabilitiesBorrowings,
      otherFinancialLiabilities: currLiabilitiesOtherFinanacial,
      tradePayables: currLiabilitiesTradePayable,
    },
    otherCurrentLiabilities,
    provisions: currLiabilitiesProvisions,
  } = currentLiabilities;

  const yearWiseData = years.map((item, i) => {
    return {
      year: item,
      currentAssets: {
        actualResult: actualResultCurrAssets?.year[i]?.value,
        expected:
          currentTaxAssets?.year[i]?.value +
          bankBalancesOtherThan32?.year[i]?.value +
          cashAndCashEquivalents?.year[i]?.value +
          currentInvestments?.year[i]?.value +
          currentLoans?.year[i]?.value +
          currentotherFinancialAssets?.year[i]?.value +
          currenttradeReceivables?.year[i]?.value +
          inventories?.year[i]?.value +
          otherCurrentAssets?.year[i]?.value,
      },
      nonCurrentAssets: {
        actualResult: actualResultNonCurrAssets?.year[i]?.value,
        expected:
          biologicalAssetsOtherThanBearerPlants?.year[i]?.value +
          capitalWorkInProgress?.year[i]?.value +
          deferredTaxAssets?.year[i]?.value +
          nonCurrentInvestments?.year[i]?.value +
          nonCurrentLoans?.year[i]?.value +
          nonCurrentotherFinancialAssets?.year[i]?.value +
          nonCurrentTradeReceivables?.year[i]?.value +
          goodwill?.year[i]?.value +
          intangibleAssetsUnderDevelopment?.year[i]?.value +
          investmentProperties?.year[i]?.value +
          otherNonCurrentAssets?.year[i]?.value +
          otherIntangibleAssets?.year[i]?.value +
          propertyPlantAndEquipment?.year[i]?.value +
          rightOfUseAssets?.year[i]?.value,
      },
      totalAssets: {
        actualResult: totalAssets?.year[i]?.value,
        expected:
          actualResultCurrAssets?.year[i]?.value +
          actualResultNonCurrAssets?.year[i]?.value +
          nonCurrentAssetsClassifiedAsHeldForSale?.year[i]?.value,
      },
      equity: {
        actualResult: actualResultTotalEquity?.year[i]?.value,
        expected:
          equityShareCapital?.year[i]?.value + otherEquity?.year[i]?.value,
      },
      nonCurrentLiabilities: {
        actualResult: actualResultNonCurrLiabiites?.year[i]?.value,
        expected:
          deferredTaxLiabilities?.year[i]?.value +
          nonCurrLiabilitiesBorrowings?.year[i]?.value +
          nonCurrLiabilitiesOtherFinanacial?.year[i]?.value +
          nonCurrLiabilitiesTradePayable?.year[i]?.value +
          otherNonCurrentLiabilities?.year[i]?.value +
          nonCurrLiabilitiesProvisions?.year[i]?.value,
      },
      currentLiabilities: {
        actualResult: actualResultCurrLiabilities?.year[i]?.value,
        expected:
          currentTaxLiabilities?.year[i]?.value +
          currLiabilitiesBorrowings?.year[i]?.value +
          currLiabilitiesOtherFinanacial?.year[i]?.value +
          currLiabilitiesTradePayable?.year[i]?.value +
          otherCurrentLiabilities?.year[i]?.value +
          currLiabilitiesProvisions?.year[i]?.value,
      },
      totalEquityAndLiabilities: {
        actualResult: actualResultTotalEquityAndLiabilities?.year[i]?.value,
        expected:
          actualResultCurrLiabilities?.year[i]?.value +
          actualResultNonCurrLiabiites?.year[i]?.value +
          actualResultTotalEquity?.year[i]?.value,
      },
      balancedSheet: {
        actualResult: actualResultTotalEquityAndLiabilities?.year[i]?.value,
        expected: totalAssets?.year[i]?.value,
      },
    };
  });
  return yearWiseData;
};

const returnActualAndDesiredValuesForPlSheet = (param) => {
  const years = param.continuingOperations.totalRevenue.year.map(
    (item) => item.label
  );
  const {
    continuingOperations,
    expenses,
    profitOrLossBeforeExceptionalItemsAndTax,
    profitOrLossBeforeTax,
    exceptionalItems,
    taxExpense,
    profitOrLossAfterTaxFromContinuingOperations,
    profitOrLossForThePeriodContandDiscont,
    discontinuedOperations:{
      profitOrLossAfterTaxFromDiscontinuedOperations,
      profitOrLossFromDiscontinuedOperations,
      taxExpenseOfDiscontinuedOperations
    }
    ,
    totalComprehensiveIncomeForThePeriod,
    otherComprehensiveIncome: {
      itemsThatMayBeReclassifiedToProfitOrLoss,
      itemsThatWillNotBeReclassifiedToProfitOrLoss,
    },
  } = param;

  const {
    totalRevenue: actualResultTotalRevenue,
    revenueFromOperations,
    otherIncome,
  } = continuingOperations;

  const {
    totalExpenses: actualResultTotalExpenses,
    changesInStockOfFinishedGoodsWorkInProgressAndStockInTrade,
    costOfMaterialsConsumed,
    depreciationAndAmortisationExpense,
    employeeBenefitExpense,
    financeCosts,
    impairmentExpensesLosses,
    otherExpenses,
    purchasesOfStockInTrade,
  } = expenses;

  const {
    totalTaxExpense: actualResultTotalTaxExpense,
    currentTax,
    deferredTax,
  } = taxExpense;

  const {
    debtInstrumentsThroughOtherComprehensiveIncome,
    effectivePortionOfGainsAndLossOnDesignatedPortionOfHedgingInstrumentsInACashFlowHedge,
    exchangeDifferencesInTranslatingTheFinancialStatementsOfForeignOperations,
    incomeTaxOnItemsThatMayBeReclassifiedToProfitOrLoss,
    others: othersItemsThatMayBeReclassified,
    shareOfOtherComprehensiveIncomeOfEquityAccountedInvestees,
  } = itemsThatMayBeReclassifiedToProfitOrLoss;

  const {
    changesInRevaluationSurplus,
    equityInstrumentsThroughOtherComprehensiveIncome,
    fairValueChangesRelatingToOwnCreditRisk,
    incomeTaxRelatingToItemsThatWillNotBeReclassifiedToProfitOrLoss,
    others: otherItemsThatWillNotBeReclassified,
    remeasurementsOfTheDefinedBenefitLiabilitiesOrAsset,
  } = itemsThatWillNotBeReclassifiedToProfitOrLoss;

  const yearWiseData = years.map((item, i) => {
    return {
      year: item,
      continuingOperations: {
        actualResult: actualResultTotalRevenue?.year[i]?.value,
        expected:
          revenueFromOperations?.year[i]?.value + otherIncome?.year[i]?.value,
      },
      expenses: {
        actualResult: actualResultTotalExpenses?.year[i]?.value,
        expected:
          changesInStockOfFinishedGoodsWorkInProgressAndStockInTrade?.year[i]
            ?.value +
          costOfMaterialsConsumed?.year[i]?.value +
          depreciationAndAmortisationExpense?.year[i]?.value +
          employeeBenefitExpense?.year[i]?.value +
          financeCosts?.year[i]?.value +
          impairmentExpensesLosses?.year[i]?.value +
          otherExpenses?.year[i]?.value +
          purchasesOfStockInTrade?.year[i]?.value,
      },
      profitOrLossBeforeExceptionalItemsAndTax: {
        actualResult: profitOrLossBeforeExceptionalItemsAndTax?.year[i]?.value,
        expected:
          actualResultTotalRevenue?.year[i]?.value -
          actualResultTotalExpenses?.year[i]?.value,
      },
      profitOrLossBeforeTax: {
        actualResult: profitOrLossBeforeTax?.year[i]?.value,
        expected: profitOrLossBeforeExceptionalItemsAndTax?.year[i]?.value-
        exceptionalItems?.year[i]?.value
      },
      taxExpense: {
        actualResult: actualResultTotalTaxExpense?.year[i]?.value,
        expected: currentTax?.year[i]?.value + deferredTax?.year[i]?.value,
      },
      profitOrLossAfterTaxFromContinuingOperations: {
        actualResult:
          profitOrLossAfterTaxFromContinuingOperations?.year[i]?.value,
        expected:
          profitOrLossBeforeTax?.year[i]?.value -
          actualResultTotalTaxExpense?.year[i]?.value,
      },
      profitOrLossAfterTaxFromDiscontinuedOperations:{
        actualResult:profitOrLossAfterTaxFromDiscontinuedOperations?.year[i]?.value,
        expected: profitOrLossFromDiscontinuedOperations?.year[i]?.value - 
        taxExpenseOfDiscontinuedOperations?.year[i]?.value,
      },
      profitOrLossForThePeriodContandDiscont: {
        actualResult: profitOrLossForThePeriodContandDiscont?.year[i]?.value,
        expected:
          profitOrLossAfterTaxFromContinuingOperations?.year[i]?.value +
          profitOrLossAfterTaxFromDiscontinuedOperations?.year[i]?.value,
      },
      totalComprehensiveIncomeForThePeriod: {
        actualResult: totalComprehensiveIncomeForThePeriod?.year[i]?.value,
        expected:
        profitOrLossForThePeriodContandDiscont?.year[i]?.value +
          debtInstrumentsThroughOtherComprehensiveIncome?.year[i]?.value +
          effectivePortionOfGainsAndLossOnDesignatedPortionOfHedgingInstrumentsInACashFlowHedge
            ?.year[i]?.value +
          exchangeDifferencesInTranslatingTheFinancialStatementsOfForeignOperations
            ?.year[i]?.value +
          incomeTaxOnItemsThatMayBeReclassifiedToProfitOrLoss?.year[i]?.value +
          othersItemsThatMayBeReclassified?.year[i]?.value +
          shareOfOtherComprehensiveIncomeOfEquityAccountedInvestees?.year[i]
            ?.value +
          changesInRevaluationSurplus.year[i]?.value +
          equityInstrumentsThroughOtherComprehensiveIncome?.year[i]?.value +
          fairValueChangesRelatingToOwnCreditRisk?.year[i]?.value +
          incomeTaxRelatingToItemsThatWillNotBeReclassifiedToProfitOrLoss?.year[
            i
          ]?.value +
          otherItemsThatWillNotBeReclassified?.year[i]?.value +
          remeasurementsOfTheDefinedBenefitLiabilitiesOrAsset?.year[i]?.value,
      },
    };
  });
  return yearWiseData;
};

// validate formulas for balance sheet and profit loss statement for each year and atleast 3 years of audit data
export const isFormulasAndMinEnteredAuditValidated = (
  currentUploadOnly,param,
  boxName
) => {
  const yearWiseData =
    boxName === BALANCE_SHEET
      ? returnActualAndDesiredValuesForBalanceSheet(param)
      : returnActualAndDesiredValuesForPlSheet(param);
  const allKeys = Object.keys(yearWiseData[0]).reduce((acc, curr) => {
    return curr !== "year" && { ...acc, [curr]: 0 };
  },{});

  for (const yearData of yearWiseData) {
    for (const section in yearData) {
      if (section !== "year") {
        const actualResult = yearData[section].actualResult;
        const expectedResult = yearData[section].expected;
        if (actualResult) {
          allKeys[section]++;
        }

        if (!tolerantDiff(actualResult,expectedResult)) {
          if (section === "balancedSheet") {
            throw new Error(
              `Balanced sheet is not balanced in ${yearData.year}`
            );
          }
          throw new Error(
            `Please verify formula with ${section} in ${yearData.year}`
          );
        }
      }
    }
  }
  validateMinAuditDataAllowed(allKeys);
};

// validation for atleast 3 years of audit data
const validateMinAuditDataAllowed = (currentUploadOnly,formulaRows) => {
  for (const key in formulaRows) {
    if (!keysExemptedFromMinYears.includes(key) && ((currentUploadOnly && formulaRows[key] >= 1) || formulaRows[key] < 3)) {
      throw new Error(
        `Please verify atleast 3 years of audit data is entered for ${key}`
      );
    }
  }
};

export const isAuditFormatValid = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate through the arrays and compare elements while ignoring \r\n
  for (let i = 0; i < arr1.length; i++) {
    const element1 = arr1[i];
    const element2 = arr2[i];

    // Normalize both elements by replacing \r\n with \n (if they are strings)
    const normalizedElement1 = typeof element1 === 'string' ? element1.replace(/\r\n/g, '\n') : element1;
    const normalizedElement2 = typeof element2 === 'string' ? element2.replace(/\r\n/g, '\n') : element2;

    // Compare the normalized elements
    if (normalizedElement1 !== normalizedElement2) {
      return false;
    }
  }

  // If all elements are equal, the arrays are equal
  return true;
}

const keysExemptedFromMinYears = [
  "balancedSheet",
  "profitOrLossAfterTaxFromDiscontinuedOperations",
  "taxExpense",
];
