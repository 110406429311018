import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  ButtonGroup,
  Button,
  TabList,
  Tab,
  Text,
  Heading,
  Link as ChakraLink,
  Flex,
  Img,
} from "@chakra-ui/react";
import consultExpert from "../../assets/images/consult-expert.png";
import { InfoIcon } from "@chakra-ui/icons";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import YearSelection from "../../components/YearSelection";
import { sendRequest } from "../../libs/axiosConfig";
import { errMsg } from "../../utils/helperFunction";
import { toast } from "react-toastify";
const AgencyTabs = () => {
  const navigate = useNavigate();

  const { clientId } = useParams();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setTabIndex(0);
  }, [clientId]);

  return (
    <Tabs
      className="main-agency-tab"
      variant="enclosed"
      colorScheme="teal"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <Flex justify="end" align="center">
        {/* <TabList>
          <Tab
            mr={5}
            onClick={() => {
              navigate(`/agency/client/${clientId}/client-background`);
            }}
          >
            Client Background
          </Tab>
          <Tab
            mr={5}
            onClick={() => {
              navigate(`/agency/client/${clientId}/industry-activity`);
            }}
          >
            Systematic factors
          </Tab>
          <Tab
            onClick={() => {
              navigate(`/agency/client/${clientId}/risk-assessment`);
            }}
          >
            Unsystematic factors
          </Tab>
        </TabList> */}
        <Box>
          {tabIndex === 0 && (
            <ChakraLink
              href="https://beta.sylvr.co.in/blogs/financial-statements-analysis-to-improve-business-complete-framework"
              target="_blank"
              color="#30d5c8"
              _hover={{ color: "#41e6d9" }}
              fontWeight="bold"
            >
              Understanding Financial Statements analysis to improve business{" "}
              <InfoIcon />
            </ChakraLink>
          )}
          {tabIndex === 1 && (
            <ChakraLink
              href="https://beta.sylvr.co.in/blogs/how-to-create-a-sense-making-performance-improvement-template-for-sme-leaders"
              target="_blank"
              color="#30d5c8"
              _hover={{ color: "#41e6d9" }}
              fontWeight="bold"
            >
              SMEs Strategic Decision Making: Sense-Making for Better
              Performance <InfoIcon />
            </ChakraLink>
          )}
          {tabIndex === 2 && (
            <ChakraLink
              href="https://beta.sylvr.co.in/blogs/master-financial-risk-management"
              target="_blank"
              color="#30d5c8"
              _hover={{ color: "#41e6d9" }}
              fontWeight="bold"
            >
              How to Master Financial Risk Management without Losing Sleep{" "}
              <InfoIcon />
            </ChakraLink>
          )}
        </Box>
      </Flex>
    </Tabs>
  );
};

const ManageSMEDashboardLayout = () => {
  const { user } = useSelector((state) => state.userReducer);
  const [clientSector, setClientSector] = useState(null);

  useEffect(() => {
    const fetchClientSector = async () => {
      try {
        const { data } = await sendRequest({
          url: `/api/v1/client/${user.id}/getMySector`,
        });

        setClientSector(data.sector);
      } catch (err) {
        const message = errMsg(err);

        toast.error(message, {
          position: "bottom-center",
          toastId: message,
        });
      }
    };

    fetchClientSector();
  }, [user]);

  return (
    <Box
      height={"calc(100% - 0px)"}
      width={{ base: "100%" }}
      px={5}
      backgroundColor="#fff"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Heading fontFamily={"Inter"} fontSize={"24px"} pt={5} pb={18} pr={6}>
            {user?.companyName}
          </Heading>
          <Text
            fontSize={"18px"}
            color={"#5D5D5D"}
            fontStyle="italic"
            fontWeight="semibold"
            borderLeft="3px solid #E6E6E6"
            borderRight="3px solid #E6E6E6"
            px={6}
            fontFamily={"Inter"}
          >
            {clientSector}
          </Text>
        </Box>
      </Box>
      <AgencyTabs />
      <Outlet />
    </Box>
  );
};

export default ManageSMEDashboardLayout;
