import Select from "react-select";

function SectorCategoriesDropdown({
  sectorCategories = [],
  selectedSectorCategory,
  handleSectorCategoryChange,
}) {
  const sectorCategoryOption =
    sectorCategories?.map?.((category) => ({
      value: category?.category,
      label: category?.category,
    })) || [];

  const selectedSectorCategoryOption = sectorCategoryOption?.find?.(
    (category) => category?.value === selectedSectorCategory
  );

  return (
    <div>
      <Select
        value={selectedSectorCategoryOption}
        onChange={handleSectorCategoryChange}
        options={sectorCategoryOption}
      />
    </div>
  );
}

export default SectorCategoriesDropdown;
