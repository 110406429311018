import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  Img,
  Input,
  Text,
} from "@chakra-ui/react";
import {
  Center,
  Checkbox,
  Divider,
  HStack,
  Heading,
  IconButton,
  InputGroup,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import ProductsDropdown from "../components/ProductsDropdown";

import { registerAgency } from "../actions/user/agencyActions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SectorCategoriesDropdown from "../components/SectorCategoriesDropdown";
import {
  getProductsApi,
  getSectorsCategoriesApi,
} from "../api/organisationApi";
import { registerOrganisation } from "../actions/user/organisationActions";
import sylvr_logo from "../assets/images/sylvr-logo.jpg";

export default function Register(props) {
  const [userType, setUserType] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Box >
        {!props.isModelPopUp && (
          <Link to={"https://beta.sylvr.co.in"}>
            <Img src={sylvr_logo} width={100} />
          </Link>
        )}
        <Box textAlign="center">
          <Box>
            <Container>
              <Text
                w={"603px"}
                textAlign={"left"}
                mb={1}
                fontWeight={600}
                fontSize={"16px"}
                textColor={"#4E6565"}
              >
                Hey there. Glad to have you on board
              </Text>
              <Box
                w={"603px"}
                display="flex"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  mb={{ base: "3", md: "0" }}
                  fontWeight="600"
                  mr={{ base: "0", md: "10" }}
                >
                  Are you a
                </Text>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box
                    textAlign="center"
                    padding={{ base: "8px 10px", md: "8px 20px" }}
                    backgroundColor={userType ? "#fff" : "#0C543C"}
                    color={userType ? "#0C543C" : "#fff"}
                    cursor="pointer"
                    fontSize={{ base: "14px", md: "16px" }}
                    borderRadius="8px 0px 0px 8px"
                    border={
                      userType ? "1px solid #0C543C" : "1px solid #0C543C"
                    }
                    onClick={() => setUserType(false)}
                  >
                    SME 
                  </Box>
                  <Box
                    textAlign="center"
                    color={userType ? "#fff" : "#0C543C"}
                    cursor="pointer"
                    fontSize={{ base: "14px", md: "16px" }}
                    padding={{ base: "8px 10px", md: "8px 20px" }}
                    backgroundColor={userType ? "#0C543C" : "#fff"}
                    borderRadius="0px 8px 8px 0px"
                    border={
                      userType ? "1px solid #0C543C" : "1px solid #0C543C"
                    }
                    onClick={() => setUserType(true)}
                  >
                    Practice
                  </Box>
                </Box>
              </Box>
              <Box
                pt={2}
                fontSize={"16px"}
                textColor={"#4E6565"}
                fontWeight={500}
              >
                {!userType ? (
                  <Text w={"603px"} textAlign={"center"} mb={1}>
                    Best for SME Leader or Finance Controller
                  </Text>
                ) : (
                  <Text w={"603px"} textAlign={"center"} mb={1}>
                    Ideal for Virtual CFOs or Business Consultants having SMEs
                    as client
                  </Text>
                )}
              </Box>
            </Container>
            
            <Box
              // boxShadow="0px 0px 50px 0px rgba(0, 0, 0, 0.06)"
              borderRadius="18px"
              // backgroundColor="#fff"
              marginTop="25px"
              padding={{ base: "25px", md: "50px" }}
            >
              {!userType ? (
                <>
                  <ClientRegistrationForm userType={userType} />
                {/* <ClientRegistrationFormOld /> */}
                </>
              ) : (
                <AgencyRegistrationForm userType={userType}/>
              )}
              <Center>
                <Text
                  textAlign={"center"}
                mt={10}
                fontWeight={600}
                  fontSize={"18px"}
                  color={"#0C543C"}
                  borderBottom={"2px solid #0C543C"}
                _hover={{
                    cursor: "pointer",
                }}
                  onClick={() => navigate("/")}
                >
                  I already have an account
                </Text>
              </Center>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const CommonLoginForm = ({
  userType,
  setIsSignUp,
  data,
  setData,
  // data,
  // dataError,
  // changeHandler,
  // setDataError
}) => {
  const [curretnInputFocous, setCurrentInputFocous] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [dataError, setDataError] = useState({
    mobileno: false,
    email: false,
    password: false,
    cnfPswd: false,
    pswd: false,
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const nextHandler = () => {
    if (userType && data.mobileno === "") {
      toast.error("Please enter mobile", {
        position: "bottom-center",
        toastId: "pswd-mismatch",
      });
      setCurrentInputFocous("mobileno");
    } else if (data.email === "") {
      toast.error("Please enter email", {
        position: "bottom-center",
        toastId: "pswd-mismatch",
      });
      setCurrentInputFocous("email");
    } else if (data.pswd && data.cnfPswd && data.pswd === data.cnfPswd) {
      // dispatch(registerAgency({ ...data }, navigate));
      setIsSignUp(false);
    } else {
      toast.error("Password and confirm password must be same", {
        position: "bottom-center",
        toastId: "pswd-mismatch",
      });
      setCurrentInputFocous("cnfPswd");
    }
  };
  return (
    <>
      <Flex pt={4}>
        <Heading color={"#0C543C"} size={"md"} fontWeight={750} mr={1}>
          Sign Up
        </Heading>
        <Text fontWeight={600} color={"#4E6565"}>
          to discover the smart suite of future financial tools
        </Text>
      </Flex>

      {userType && (
        <Box w={"511px"}>
          {curretnInputFocous === "mobileno" ? (
            <FormLabel
              fontWeight={400}
              fontSize={"13px"}
              textColor={"#0C543C"}
              w={"511px"}
              mb={0}
            >
              Mobile Number
            </FormLabel>
          ) : (
            ""
          )}
          <Input
            maxW="511px"
            type="text"
            name="mobileno"
            placeholder={
              curretnInputFocous === "mobileno" ? "" : "Mobile Number"
            }
            focusBorderColor="#30d5c8"
            focusborderwidth="5px"
            transition="height 0.3s"
            fontWeight={"bold"}
            onFocus={() => setCurrentInputFocous("mobileno")}
            onBlur={() => {
              setCurrentInputFocous("");
              setDataError((prevDataError) => ({
                ...prevDataError,
                mobileno: data.mobileno.trim() === "",
              }));
            }}
            isInvalid={dataError.mobileno}
            errorBorderColor="red.500"
            _placeholder={{
              fontWeight: "normal",
              color: "#0C543C",
            }}
            _focus={{
              height: "3rem",
              borderLeft: "5px solid #30d5c8",
            }}
            onChange={changeHandler}
          />
        </Box>
      )}

      <Box w={"511px"}>
        {curretnInputFocous === "email" ? (
          <FormLabel
            fontWeight={400}
            fontSize={"13px"}
            textColor={"#0C543C"}
            w={"511px"}
            mb={0}
          >
            Email
          </FormLabel>
        ) : (
          ""
        )}
        <Input
          maxW="511px"
          type="email"
          name="email"
          placeholder={curretnInputFocous === "email" ? "" : "Email"}
          focusBorderColor="#30d5c8"
          focusborderwidth="5px"
          transition="height 0.3s"
          fontWeight={"bold"}
          onFocus={() => setCurrentInputFocous("email")}
          onBlur={() => {
            setCurrentInputFocous("");
            setDataError((prevDataError) => ({
              ...prevDataError,
              email: data.email.trim() === "",
            }));
          }}
          isInvalid={dataError.email}
          errorBorderColor="red.500"
          _placeholder={{
            fontWeight: "normal",
            color: "#0C543C",
          }}
          _focus={{
            height: "3rem",
            borderLeft: "5px solid #30d5c8",
          }}
          onChange={changeHandler}
        />
      </Box>

      <Box w={"511px"}>
        {curretnInputFocous === "pswd" ? (
          <FormLabel
            fontWeight={400}
            fontSize={"13px"}
            textColor={"#0C543C"}
            w={"511px"}
            mb={0}
          >
            Password
          </FormLabel>
        ) : (
          ""
        )}
        <InputGroup maxW="511px">
          <Input
            type={showPassword ? "text" : "password"}
            name="pswd"
            placeholder={curretnInputFocous === "pswd" ? "" : "Password"}
            focusBorderColor="#30d5c8"
            focusborderwidth="5px"
            transition="height 0.3s"
            fontWeight={"bold"}
            onFocus={() => setCurrentInputFocous("pswd")}
            onBlur={() => {
              setCurrentInputFocous("");
              setDataError((prevDataError) => ({
                ...prevDataError,
                pswd: data.pswd.trim() === "",
              }));
            }}
            isInvalid={dataError.pswd}
            errorBorderColor="red.500"
            _placeholder={{
              fontWeight: "normal",
              color: "#0C543C",
            }}
            _focus={{
              height: "3rem",
              borderLeft: "5px solid #30d5c8",
            }}
            onChange={changeHandler}
          />
          <InputRightElement width="4.5rem" h={"100%"}>
            <IconButton
              h="1.75rem"
              size="md"
              onClick={() => setShowPassword(!showPassword)}
              bg={"gray.200"}
              icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            />
          </InputRightElement>
        </InputGroup>
      </Box>

      <Box w={"511px"}>
        {curretnInputFocous === "cnfPswd" ? (
          <FormLabel
            fontWeight={400}
            fontSize={"13px"}
            textColor={"#0C543C"}
            w={"511px"}
            mb={0}
          >
            Confirm Password
          </FormLabel>
        ) : (
          ""
        )}
        <Input
          maxW="511px"
          type={showPassword ? "text" : "password"}
          name="cnfPswd"
          placeholder={
            curretnInputFocous === "cnfPswd" ? "" : "Confirm Password"
          }
          focusBorderColor="#30d5c8"
          focusborderwidth="5px"
          transition="height 0.3s"
          fontWeight={"bold"}
          onFocus={() => setCurrentInputFocous("cnfPswd")}
          onBlur={() => {
            setCurrentInputFocous("");
            setDataError((prevDataError) => ({
              ...prevDataError,
              cnfPswd: data.cnfPswd !== data.pswd, // Example validation rule: Organization is required
            }));
          }}
          isInvalid={data.cnfPswd !== data.pswd}
          errorBorderColor="red.500"
          _placeholder={{
            fontWeight: "normal",
            color: "#0C543C",
          }}
          _focus={{
            height: "3rem",
            borderLeft: "5px solid #30d5c8",
          }}
          onChange={changeHandler}
        />
      </Box>

      <Box>
        <Button bg={"#0C543C"} color={"white"} onClick={nextHandler} mb={4}>
          Next
        </Button>
      </Box>
    </>
  );
};

const ClientRegistrationForm = ({ userType }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [curretnInputFocous, setCurrentInputFocous] = useState("");

  const [data, setData] = useState({
    name: "",
    designation: "",
    organization: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    email: "",
    password: "",
    cnfPswd: "",
    pswd: "",
    products: [],
  });

  const [dataError, setDataError] = useState({
    name: false,
    designation: false,
    organization: false,
    addressLine1: false,
    addressLine2: false,
    pincode: false,
    email: false,
    password: false,
    cnfPswd: false,
    products: false,
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.userReducer);

  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [sectorCategories, setSectorCategories] = useState([]);
  const [selectedSectorCategory, setSelectedSectorCategory] = useState(null);

  useEffect(() => {
    fetchSectorCategories();
  }, []);

  const fetchSectorCategories = async () => {
    setCategoriesLoading(true);
    try {
      const data = await getSectorsCategoriesApi();
      setSectorCategories(data);
      // setSelectedSectorCategory(data?.[0]?.category || null);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setCategoriesLoading(false);
    }
  };

  const handleSectorCategoryChange = (selectedSectorCategory) => {
    setSelectedSectorCategory(selectedSectorCategory?.value);
  };
  
  const handleFormSubmit = (e) => {
    if (!termsAccepted) {
      toast.error("Please accept the Terms of Service to proceed", {
        position: "bottom-center",
        toastId: "terms-not-selected",
      });
    } else {
      dispatch(registerOrganisation(data, navigate));
    }
    console.log(data);
  };

return (
  <>
      <Container >
        <Box
          maxWidth="603px"
          minWidth="580px"
          borderRadius={10}
          boxShadow="lg"
          borderWidth={1}
        >
          <VStack w={"100%"} bg={"white"} p={3} spacing={8} borderRadius={10}>
            {isSignUp && (
              <CommonLoginForm
                userType={userType}
                setIsSignUp={setIsSignUp}
                data={data}
                setData={setData}
              />
            )}
            {!isSignUp && (
              <>
                <Box width={"511px"}>
                  <Text
                    fontWeight={600}
                    fontSize={"18px"}
                    color={"#162421"}
                    textAlign={"left"}
                  >
                    Your account details
                  </Text>
                </Box>
                <Box w={"511px"}>
                  {curretnInputFocous === "name" ? (
              <FormLabel
                fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                mb={0}
                    >
                      Your name
                    </FormLabel>
                  ) : (
                    ""
                  )}
              <Input
                    maxW="511px"
                    name="name"
                    type="text"
                    placeholder={
                      curretnInputFocous === "name" ? "" : "Your Name"
                    }
                  focusBorderColor="#30d5c8"
                  focusborderwidth="5px"
                  transition="height 0.3s"
                    fontWeight={"bold"}
                  onBlur={() => {
                      setCurrentInputFocous("");
                    setDataError((prevDataError) => ({
                        ...prevDataError,
                        name: data.name.trim() === "",
                    }));
                }}
                  isInvalid={dataError.name}
                  errorBorderColor="red.500"
                  _placeholder={{
                      fontWeight: "normal",
                      color: "#162421",
                  }}
                    onFocus={() => setCurrentInputFocous("name")}
                  _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
                   }}
                   onChange={changeHandler}
              />
          </Box>
                <Box w={"511px"}>
                  {curretnInputFocous === "designation" ? (
              <FormLabel
                fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                mb={0}
                    >
                      Your designation
                    </FormLabel>
                  ) : (
                    ""
                  )}
              <Input
                    maxW="511px"
                    name="designation"
                    type="text"
                    placeholder={
                      curretnInputFocous === "designation"
                        ? ""
                        : "Your designation"
                    }
                  focusBorderColor="#30d5c8"
                  focusborderwidth="5px"
                  transition="height 0.3s"
                    fontWeight={"bold"}
                    onFocus={() => setCurrentInputFocous("designation")}
                  onBlur={() => {
                      setCurrentInputFocous("");
                    setDataError((prevDataError) => ({
                        ...prevDataError,
                        designation: data.designation.trim() === "",
                    }));
                }}
                  isInvalid={dataError.designation}
                  errorBorderColor="red.500"
                  _placeholder={{
                      fontWeight: "normal",
                      color: "#0C543C",
                  }}
                  _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
                   }}
                   onChange={changeHandler}
              />
           </Box>
                <Divider w={"466px"} borderWidth="1px" borderColor="gray.200" />
                <Heading
                  size={"md"}
                  width={"95%"}
                  fontWeight={600}
                  textAlign={"left"}
                >
                  Your Organization
                </Heading>
                <Box w={"511px"}>
                  {curretnInputFocous === "organization" ? (
                <FormLabel
                  fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                  mb={0}
                    >
                      Organization name
                    </FormLabel>
                  ) : (
                    ""
                  )}
                <Input
                  mb={3}
                    maxW="511px"
                    name="organization"
                    type="text"
                    placeholder={
                      curretnInputFocous === "organization"
                        ? ""
                        : "Organization name"
                    }
                  focusBorderColor="#30d5c8"
                  focusborderwidth="5px"
                  transition="height 0.3s"
                    fontWeight={"bold"}
                    onFocus={() => setCurrentInputFocous("organization")}
                  onBlur={() => {
                      setCurrentInputFocous("");
                    setDataError((prevDataError) => ({
                        ...prevDataError,
                        organization: data.organization.trim() === "", // Example validation rule: Organization is required
                    }));
                  }}
                  isInvalid={dataError.organization}
                  errorBorderColor="red.500"
                  _placeholder={{
                      fontWeight: "normal",
                      color: "#0C543C",
                  }}
                  _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
                   }}
                   onChange={changeHandler}
                />
              </Box>
              <HStack
                flexWrap="wrap"
                spacing={{ base: 4, md: 8 }}
                  width={{ base: "100%", md: "auto" }}
              >
                  <Text
                    width={{ base: "100%", md: "8rem" }}
                    textAlign={"left"}
                    whiteSpace="nowrap"
                    ml={"4px"}
                  >
                  Industry Category
                </Text>

                <Box
                    width={{ base: "100%", md: "21rem" }}
                    pt={{ base: 2, md: "0" }}
                >
                {categoriesLoading ? (
                  <p>Loading categories...</p>
                ) : (
                  <SectorCategoriesDropdown
                    sectorCategories={sectorCategories}
                    selectedSectorCategory={selectedSectorCategory}
                    handleSectorCategoryChange={handleSectorCategoryChange}
                  />
                )}
                </Box>
              </HStack>
                {selectedSectorCategory && (
                <Flex
                  // maxW='100%' //{'490px'}
                    width={{ base: "100%", md: "auto" }}
                    wrap={"wrap"}
                    alignItems={"flex-start"}
                    direction={{ base: "column", md: "row" }}
                    justifyContent={{ base: "flex-start", md: "space-between" }}
                >
                    <Text
                      width={{ base: "100%", md: "9rem" }}
                      pt={{ base: "1rem", md: "0" }}
                      textAlign={"left"}
                      ml={{ base: "0", md: "10px" }}
                    >
                    Product
                  </Text>

                    <Box w={{ base: "100%", md: "21rem" }}>
                  <Text fontWeight={500}>
                        {data.products.length === 0
                          ? "Minimum 3 required/Maximum 20 available"
                          : data.products.length < 3
                          ? "Minimum 3 required"
                          : "Maximum 20 available"}
                    </Text>
                    <ProductsDropdown
                      selectedSectorCategory={selectedSectorCategory}
                      fetchDataFunction={getProductsApi}
                      placeholder="Search Products..."
                      isMulti
                      handleOptionChange={(selectedOptions) => {
                          const products = selectedOptions.map(
                            (val) => val.value
                          );
                        if(data.products.length < 20) {
                          setData((prev) => ({
                            ...prev,
                            products,
                          }));
                        }
                      }}
                      _focus={{ 
                          height: "3rem",
                          borderLeft: "5px solid #30d5c8",
                     }}
                    />
                  </Box>
                </Flex>
                )}
                <Box w={"511px"}>
                  {curretnInputFocous === "addressLine1" ? (
                  <FormLabel
                    fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                    mb={0}
                    >
                      Address Line 1
                    </FormLabel>
                  ) : (
                    ""
                  )}
                  <Input
                    maxW="511px"
                    name="addressLine1"
                    type="text"
                    placeholder={
                      curretnInputFocous === "addressLine1"
                        ? ""
                        : "Address Line 1"
                    }
                      focusBorderColor="#30d5c8"
                      focusborderwidth="5px"
                      transition="height 0.3s"
                    fontWeight={"bold"}
                    onFocus={() => setCurrentInputFocous("addressLine1")}
                      onBlur={() => {
                      setCurrentInputFocous("");
                        setDataError((prevDataError) => ({
                            ...prevDataError,
                        addressLine1: data.addressLine1.trim() === "",
                        }));
                      }}
                      isInvalid={dataError.addressLine1}
                      errorBorderColor="red.500"
                      _placeholder={{
                      fontWeight: "normal",
                      color: "#0C543C",
                      }}
                      _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
                      }}
                      onChange={changeHandler}
                  />
                </Box>
                <Box w={"511px"}>
                  {curretnInputFocous === "addressLine2" ? (
                    <FormLabel
                      fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                      mb={0}
                    >
                      Address Line 2
                    </FormLabel>
                  ) : (
                    ""
                  )}
                    <Input
                    maxW="511px"
                    name="addressLine2"
                    type="text"
                    placeholder={
                      curretnInputFocous === "addressLine2"
                        ? ""
                        : "Address Line 2"
                    }
                      focusBorderColor="#30d5c8"
                      focusborderwidth="5px"
                      transition="height 0.3s"
                    fontWeight={"bold"}
                    onFocus={() => setCurrentInputFocous("addressLine2")}
                      onBlur={() => {
                      setCurrentInputFocous("");
                        setDataError((prevDataError) => ({
                            ...prevDataError,
                        addressLine2: data.addressLine2.trim() === "",
                        }));
                      }}
                      isInvalid={dataError.addressLine2}
                      errorBorderColor="red.500"
                      _placeholder={{
                      fontWeight: "normal",
                      color: "#0C543C",
                      }}
                      _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
                      }}
                      onChange={changeHandler}
                    />
                </Box>
                <HStack w={"511px"} justify={"flex-start"} spacing={4}>
                  <Box maxW="173px">
                    {curretnInputFocous === "pincode" ? (
                    <FormLabel
                      fontWeight={400}
                        fontSize={"13px"}
                        textColor={"#0C543C"}
                        w={"511px"}
                      mb={0}
                      >
                        Enter Pincode
                      </FormLabel>
                    ) : (
                      ""
                    )}
                    <Input
                      name="pincode"
                      type="number"
                      placeholder={
                        curretnInputFocous === "pincode" ? "" : "Enter Pincode"
                      }
                      focusBorderColor="#30d5c8"
                      focusborderwidth="5px"
                      transition="height 0.3s"
                      fontWeight={"bold"}
                      onFocus={() => setCurrentInputFocous("pincode")}
                      onBlur={() => {
                        setCurrentInputFocous("");
                        const isValidPincode = /^\d{6}$/.test(data.pincode);  // Check if pincode is exactly 6 digits
                        setDataError((prevDataError) => ({
                            ...prevDataError,
                            pincode: !isValidPincode,
                        }));
                      }}
                      isInvalid={dataError.pincode}
                      errorBorderColor="red.500"
                      _placeholder={{
                        fontWeight: "normal",
                        color: "#0C543C",
                      }}
                      _focus={{ 
                        height: "3rem",
                        borderLeft: "5px solid #30d5c8",
                      }}
                      onChange={changeHandler}
                    />
                </Box>
              </HStack>
              
              <Flex>
              <Checkbox
                colorScheme="green"
                // defaultChecked
                onChange={() => setTermsAccepted(!termsAccepted)}
                sx={{
                      "& input:checked + span": {
                        bg: "#0C543C",
                        "&:after": {
                          bg: "white",
                    },
                  },
                }}
              />
                  <Text fontWeight={500} pl={2}>
                    I have read and agree to the{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/terms"
                    >
                      Terms of Service
                    </a>
                  </Text>
              </Flex>

              <Box>
                  <Button
                    bg={"#0C543C"}
                    color={"white"}
                    mr={"30px"}
                    onClick={() => setIsSignUp(true)}
                    mb={4}
                  >
                    Back
                  </Button>
                  <Button
                    bg={"#0C543C"}
                    color={"white"}
                    onClick={handleFormSubmit}
                    mb={4}
                  >
                    {loading ? "Wait..." : "Sign Up"}
                  </Button>
              </Box>
              </>
            )}
          </VStack>
        </Box>
  </Container>
  </>
  );
};
  
function AgencyRegistrationForm({ userType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

   const [isSignUp, setIsSignUp] = useState(true);
  const [isCA, setIsCA] = useState(true);
  const [curretnInputFocous, setCurrentInputFocous] = useState("");

  const { loading } = useSelector((state) => state.userReducer);

  const [data, setData] = useState({
    designation: "",
    name: "",
    mobileno: "",
    email: "",
    pswd: "",
    cnfPswd: "",
    avatar: "",
    // udin: "",
    is_ca: 1,
    mrn: "",
  });

  const [dataError, setDataError] = useState({
    designation: false,
    name: false,
    // mobileno: false,
    // email: false,
    // pswd: false,
    // cnfPswd: false,
    avatar: false,
    // udin: false,
    mrn: false,
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setData({ ...data, [name]: value });
  };

  const submitHandler = async (e) => {
    if ((data.mrn.length === 6 && isCA) || !isCA) {
      dispatch(registerAgency({ ...data }, navigate));
    } else if (data.mrn.length !== 6 && isCA) {
      toast.error("MRN must be of 6 digit", {
        position: "bottom-center",
        toastId: "pswd-mismatch",
      });
    }
  };

  return (
    <>
    <Container >
        <Box
          maxWidth="603px"
          minWidth="580px"
          borderRadius={10}
          boxShadow="lg"
          borderWidth={1}
        >
          <VStack
            h={"auto"}
            w={"100%"}
            bg={"white"}
            p={3}
            spacing={8}
            borderRadius={10}
          >
            {isSignUp && (
              <CommonLoginForm
                userType={userType}
                setIsSignUp={setIsSignUp}
                data={data}
                setData={setData}
              />
            )}

            {!isSignUp && (
              <>
                <Box width={"511px"}>
                  <Text
                    fontWeight={600}
                    fontSize={"18px"}
                    color={"#162421"}
                    textAlign={"left"}
                  >
                    Your account details
              </Text>
                </Box>
                <Box w={"511px"}>
                  {curretnInputFocous === "designation" ? (
              <FormLabel
                fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                mb={0}
                >
                  Your designation
                </FormLabel>
              ) : (
                    ""
              )}
            <Input
                maxW="511px"
                name="designation"
                type="text"
                placeholder={
                      curretnInputFocous === "designation"
                        ? ""
                        : "Your designation"
                }
              focusBorderColor="#30d5c8"
              focusborderwidth="5px"
              transition="height 0.3s"
                    fontWeight={"bold"}
                    onFocus={() => setCurrentInputFocous("designation")}
              onBlur={() => {
                      setCurrentInputFocous("");
                setDataError((prevDataError) => ({
                    ...prevDataError,
                        designation: data.designation.trim() === "",
                }));
              }}
              isInvalid={dataError.designation}
              errorBorderColor="red.500"
              _placeholder={{
                      fontWeight: "normal",
                      color: "#0C543C",
              }}
              _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
              }}
              onChange={changeHandler}
            />
          </Box>
                <Box w={"511px"}>
                  {curretnInputFocous === "name" ? (
              <FormLabel
                fontWeight={400}
                      fontSize={"13px"}
                      textColor={"#0C543C"}
                      w={"511px"}
                mb={0}
                >
                  Your Name
                </FormLabel>
              ) : (
                    ""
              )}
            <Input
                maxW="511px"
                name="name"
                type="text"
                    placeholder={
                      curretnInputFocous === "name" ? "" : "Your Name"
                    }
              focusBorderColor="#30d5c8"
              focusborderwidth="5px"
              transition="height 0.3s"
                    fontWeight={"bold"}
                    onFocus={() => setCurrentInputFocous("name")}
              onBlur={() => {
                      setCurrentInputFocous("");
                setDataError((prevDataError) => ({
                    ...prevDataError,
                        name: data.name.trim() === "",
                }));
              }}
              isInvalid={dataError.name}
              errorBorderColor="red.500"
              _placeholder={{
                      fontWeight: "normal",
                      color: "#0C543C",
              }}
              _focus={{ 
                      height: "3rem",
                      borderLeft: "5px solid #30d5c8",
              }}
              onChange={changeHandler}
            />
          </Box>
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight="600"
                  m={"15px 0px 0px 0px!important"}
                  w={"92%"}
                  display="flex"
                  justifyContent="start"
                  alignItems="start"
                >
                  I am a
                </Text>
                <Box
                  w={"92%"}
                  display="flex"
                  justifyContent="start"
                  alignItems="start"
                  m={"5px!important"}
                >
                  <Box
                    textAlign="center"
                    color={isCA ? "#fff" : "#0C543C"}
                    cursor="pointer"
                    fontSize={{ base: "14px", md: "16px" }}
                    padding={{ base: "8px 10px", md: "8px 20px" }}
                    backgroundColor={isCA ? "#0C543C" : "#fff"}
                    borderRadius="8px 0px 0px 8px"
                    border={isCA ? "1px solid #0C543C" : "1px solid #0C543C"}
                    onClick={() => {
                      setIsCA(true);
                      setData({ ...data, ["is_ca"]: 1 });
                    }}
                  >
                    CA
                  </Box>
                  <Box
                    textAlign="center"
                    padding={{ base: "8px 10px", md: "8px 20px" }}
                    backgroundColor={isCA ? "#fff" : "#0C543C"}
                    color={isCA ? "#0C543C" : "#fff"}
                    cursor="pointer"
                    borderRadius="0px 8px 8px 0px"
                    fontSize={{ base: "14px", md: "16px" }}
                    border={isCA ? "1px solid #0C543C" : "1px solid #0C543C"}
                    onClick={() => {
                      setIsCA(false);
                      setData({ ...data, ["is_ca"]: 0 });
                    }}
                  >
                    Other Financial Expert
                  </Box>
                </Box>
                {isCA && (
                  <Box w={"511px"}>
                    {curretnInputFocous === "mrn" ? (
                <FormLabel
                  fontWeight={400}
                        fontSize={"13px"}
                        textColor={"#0C543C"}
                        w={"511px"}
                  mb={0}
                >
                  MRN
                </FormLabel>
              ) : (
                      ""
              )}
              <Input
                maxW="511px"
                name="mrn"
                type="number"
                    placeholder={
                        curretnInputFocous === "mrn" ? "" : "Enter MRN"
                    }
                focusBorderColor="#30d5c8"
                focusborderwidth="5px"
                transition="height 0.3s"
                      fontWeight={"bold"}
                      onFocus={() => setCurrentInputFocous("mrn")}
                onBlur={() => {
                        setCurrentInputFocous("");
                  setDataError((prevDataError) => ({
                    ...prevDataError,
                          mrn: data.mrn.trim() === "",
                  }));
                }}
                isInvalid={dataError.mrn}
                errorBorderColor="red.500"
                _placeholder={{
                        fontWeight: "normal",
                        color: "#0C543C",
              }}
              _focus={{ 
                        height: "3rem",
                        borderLeft: "5px solid #30d5c8",
              }}
              onChange={changeHandler}
            />
          </Box>
                )}
                <Box>
                  <Button
                    bg={"#0C543C"}
                    color={"white"}
                    mr={"30px"}
                    onClick={() => setIsSignUp(true)}
                    mb={4}
            >
                    Back
                  </Button>
              <Button
                    bg={"#0C543C"}
                    color={"white"}
                onClick={submitHandler}
                mb={4}
              >
                    {loading ? "Wait..." : "Sign Up"}
              </Button>
              </Box>
              </>
            )}
        </VStack>
      </Box>
    </Container>
    </>
  );
}
