import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Instructions from "../Audit/Instructions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SheetUploaderBox from "./SMESheetUploaderBox";
import { toast } from "react-toastify";
import { uploadAuditSheets } from "../../actions/audit/auditUploadActions";
import Loader from "../../components/Loader/Loader";

import { areArraysEqual } from "../../utils/helperFunction";
import {
  BALANCE_SHEET,
  PROFIT_LOSS_STATEMENT,
} from "../../utils/Stringconstant";
import { balanceSheetReqBody, IncomeStatReqBody } from "../Audit/helper";
import { allUnits } from "../../assets/auditSheetFormats";
import { usePayment } from "../../contexts/PaymentContext";

// const toCamelCase = (str) => {
//   // Replace any hyphens or underscores with a space
//   str = str.replace(/[-_]/g, " ");

//   // Convert the string to title case
//   str = str.replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });

//   // Replace any spaces with an empty string and make the first character lowercase
//   str = str.replace(/\s+/g, "");
//   str = str.charAt(0).toLowerCase() + str.slice(1);

//   return str;
// };

const SMEAuditSheetUploader = ({ clientData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const balanceSheetRef = useRef(null);
  const statementRef = useRef(null);

  const [unit, setUnit] = useState("");
  const [balanceSheet, setBalanceSheetData] = useState("");
  const [rawBalanceSheet, setRawBalanceSheetData] = useState("");
  const [profitLoss, setStatementSheetData] = useState("");
  const [rawProfitLoss, setRawStatementSheetData] = useState("");
  const [balanceSheetEntryYears, setBalanceSheetEntryYears] = useState([]);
  const [statSheetEntryYears, setStatSheetEntryYears] = useState([]);
  const [balanceSheetFile, setBalanceSheetFile] = useState(null);
  const [statementSheetFile, setStatementSheetFile] = useState(null);

  /* const { loading: auditLoading, clientId } = useSelector(
    (state) => state.auditUploadReducer.audit
  ); */

  const { clientId } = useParams();

  const { showPaymentModal } = usePayment();

  const { user: userData } = useSelector((state) => state.userReducer);

  const openPaymentModal = () => {
    showPaymentModal();
  };

  const balanceSheetRefOnClickHandler = (e) => {
    balanceSheetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const statementRefOnClickHandler = (e) => {
    statementRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const submitHandler = (e) => {
    if (!userData?.isDashboardPurchased) {
      openPaymentModal();
      return;
    }

    e.preventDefault();

    if (!balanceSheet) {
      toast.error("Please Upload Your Balance Sheet to Proceed", {
        toastId: "bal-sheet-empty",
        position: "bottom-center",
      });
      return;
    }

    if (!profitLoss) {
      toast.error("Please Upload Your Statement Sheet to Proceed", {
        toastId: "st-sheet-empty",
        position: "bottom-center",
      });
      return;
    }

    if (!unit) {
      toast.error("Please select your unit to Proceed", {
        toastId: "unit-empty",
        position: "bottom-center",
      });
      return;
    }

    if (!areArraysEqual(balanceSheetEntryYears, statSheetEntryYears)) {
      toast.error("Both sheets must have data for equal number of years", {
        toastId: "unequal-cols",
        position: "bottom-center",
      });
      return;
    }
    const reqBodyB_S = balanceSheetReqBody(balanceSheet, unit);
    const reqBodyP_L = IncomeStatReqBody(profitLoss, unit);

    dispatch(
      uploadAuditSheets(
        clientId,
        allUnits.find((val) => val.value === Number(unit)).name,
        reqBodyB_S,
        reqBodyP_L,
        rawBalanceSheet,
        rawProfitLoss,
        userData.role,
        navigate
      )
    );
    // Reset the state here after dispatch
    setBalanceSheetFile(null);
    setStatementSheetFile(null);
    setBalanceSheetData("");
    setStatementSheetData("");
    setUnit("");

    toast.success(
      '⚠️ This will SUBMIT the current information. Any future EDITS to past audited financial performance will require Admin consent. You can VERIFY in "View Past Performance" tab.',
      {
        position: "bottom-center",
      }
    );
  };

  return (
    <>
      <Stack
        overflow={"auto"}
        p={["21px", "32px"]}
        className="body-container"
        height="89%"
        overflowY="auto"
      >
        <Instructions
          balanceSheetRefOnClickHandler={balanceSheetRefOnClickHandler}
          statementRefOnClickHandler={statementRefOnClickHandler}
          setUnit={setUnit}
        />

        <SheetUploaderBox
          setAllyears={setBalanceSheetEntryYears}
          boxName={BALANCE_SHEET}
          boxRef={balanceSheetRef}
          setData={setBalanceSheetData}
          setRawData={setRawBalanceSheetData}
          clientData={clientData}
        />

        <SheetUploaderBox
          setAllyears={setStatSheetEntryYears}
          boxName={PROFIT_LOSS_STATEMENT}
          boxRef={statementRef}
          setData={setStatementSheetData}
          setRawData={setRawStatementSheetData}
          clientData={clientData}
        />

        <Flex justifyContent={"space-around"} pt={"20px"}>
          <Button
            onClick={submitHandler}
            isDisabled={clientData?.auditUploadYears?.length > 0}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
      {/* </>
      )} */}
    </>
  );
};

export default SMEAuditSheetUploader;
