import { Stack } from "@chakra-ui/react";
import React from "react";
import Banner from "./Banner";
import "./home.scss";
import SectorWiseSection from "./aggregateChartsSection/SectorWiseSection";
import RegionWiseSection from "./aggregateChartsSection/RegionWiseSection";


const Home = () => {
  return (
    <>
      <Stack className="body-container" overflowY={"auto"} spacing={"14"}>
        {/* <Banner /> */}
        <RegionWiseSection />
        <SectorWiseSection />
      </Stack>
    </>
  );
};

export default Home;
