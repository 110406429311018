import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

import { getsectorWiseResult } from "../../actions/home/homeActions";
import Loader from "../../components/Loader/Loader";
import { TYPES_OF_SYSTEMATIC_METRICS } from "../../constants/metrics";
import TotalWorkingCapital from "../Home/aggregateChartsSection/TotalWorkingCapital";
import IndustryLeaderInterview from "../../components/IndustryLeaderInterview/IndustryLeaderInterview";
import SystematicFactorsStackedAreaChart from "../../components/charts/SystematicFactorsCharts/SystematicFactorsStackedAreaChart";
import SystematicFactorsStackedBarChart from "../../components/charts/SystematicFactorsCharts/SystematicFactorsStackedBarChart";
import SystematicFactorsLineChart from "../../components/charts/SystematicFactorsCharts/SystematicFactorsLineChart";

const FinancingAndGrowth = ({ graphData }) => {
  return (
    <>
      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalShareholdersEquity}
          heading="Total Shareholders Equity"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalDebt}
          heading="Total Debt"
        />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalSales}
          heading="Total Sales"
        />
      </GridItem>

      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalEbitda}
          heading="Total EBITDA"
        />
      </GridItem>
    </>
  );
};

const Assets = ({ graphData }) => {
  return (
    <>
      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTangibleAssets}
          heading="Total Tangible Assets"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalIntangibleAssets}
          heading="Total Intangible Assets"
        />
      </GridItem>

      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalFinancialAssets}
          heading="Total Financial Assets"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalLongTermAssetsUnderDevelopment}
          heading="Total Long Term Assets under development"
        />
      </GridItem>
    </>
  );
};

const CostHeads = ({ graphData }) => {
  return (
    <>
      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalMaterialsCost}
          heading="Total Materials Cost"
        />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalInterest}
          heading="Total Interest"
        />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalEmployeeCost}
          heading="Total Employee Cost"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalTax}
          heading="Total Tax"
        />
      </GridItem>
    </>
  );
};

const Trade = ({ graphData }) => {
  const averageInventorySorted = [...graphData.averageInventory]
    .sort((a, b) => a - b)
    .splice(1);

  const averageInventory = averageInventorySorted.map((d, i) => ({
    value: {
      averageInventory:
        (d.value.currentInventory + d.value.previousInventory) / 2,
    },
    year: d.year,
  }));

  return (
    <>
      {/* composite */}
      <GridItem maxW={"500px"} w="100%">
        <TotalWorkingCapital data={graphData.totalWorkingCapital} />
      </GridItem>

      {/* line */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsLineChart
          data={averageInventory}
          heading="Average Inventory"
        />
      </GridItem>

      {/* area */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTradeReceivables}
          heading="Total Trade Receivables"
        />
      </GridItem>

      {/* bar */}
      <GridItem maxW={"500px"} w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalTradePayables}
          heading="Total Trade Payables"
        />
      </GridItem>
    </>
  );
};

const sectorWiseMetricKeys = [
  "totalShareholdersEquity",
  "totalDebt",
  "totalSales",
  "totalEbitda",
  "totalTangibleAssets",
  "totalIntangibleAssets",
  "totalFinancialAssets",
  "totalLongTermAssetsUnderDevelopment",
  "totalMaterialsCost",
  "totalInterest",
  "totalEmployeeCost",
  "totalTax",
  "averageInventory",
  "totalWorkingCapital",
  "totalTradeReceivables",
  "totalTradePayables",
];

const SMEIndustryActivity = () => {
  const dispatch = useDispatch();

  const { loading, data } = useSelector(
    (state) => state.homeReducer.sectorWiseSection
  );

  const { user } = useSelector((state) => state.userReducer);

  const clientData = user;

  const [sectorOptions, setSectorOptions] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [sectorWiseData, setSectorWiseData] = useState([]);

  const [graphData, setGraphData] = useState({
    totalShareholdersEquity: [],
    totalDebt: [],
    totalSales: [],
    totalEbitda: [],
    totalTangibleAssets: [],
    totalIntangibleAssets: [],
    totalFinancialAssets: [],
    totalLongTermAssetsUnderDevelopment: [],
    totalMaterialsCost: [],
    totalInterest: [],
    totalEmployeeCost: [],
    totalTax: [],
    averageInventory: [],
    totalWorkingCapital: [],
    totalTradeReceivables: [],
    totalTradePayables: [],
  });

  const [currentType, setCurrentType] = useState(
    Object.keys(TYPES_OF_SYSTEMATIC_METRICS)?.[0]
  );

  useEffect(() => {
    dispatch(getsectorWiseResult());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setSectorOptions(
        data?.map((swr) => {
          return {
            name: swr.sector,
            value: swr.sector,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const sectorValue = sectorOptions.find((sector) => {
      return sector.value === clientData.sector;
    });
    sectorOptions && setSelectedSector(sectorValue?.value || null);
  }, [sectorOptions, clientData]);

  useEffect(() => {
    if (selectedSector) {
      setSectorWiseData(
        data?.filter((swr) => swr.sector === selectedSector)[0]
      );
    }
  }, [selectedSector, data]);

  useEffect(() => {
    const yearsWiseData = sectorWiseData?.yearWiseData;
    const years = yearsWiseData && Object.keys(yearsWiseData);

    if (yearsWiseData && years.length > 0) {
      sectorWiseMetricKeys.forEach((key) => {
        const obj = years.map((year) => {
          const d = Object.keys(yearsWiseData[year][key]);

          let total = 0;
          d.forEach((dd) => {
            total += yearsWiseData[year][key][dd];
          });

          return {
            year,
            value: { ...yearsWiseData[year][key] },
            total,
          };
        });

        setGraphData((currState) => {
          return {
            ...currState,
            [key]: obj,
          };
        });
      });
    }
  }, [sectorWiseData]);

  return (
    <>
      <Box className="agency-content">
        <Stack p={["10px", "30px"]} id="sectorwise_section">
          {loading ? (
            <>
              <Box h={"200px"}>
                <Loader />
              </Box>
            </>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  <Box display="flex">
                    <Text
                      textTransform="uppercase"
                      color="#1E1E1E"
                      fontFamily={"Inter"}
                      fontWeight="semibold"
                      letterSpacing=".9px"
                    >
                      TYPE
                    </Text>
                    <Box display="flex" ml={10} mr={20}>
                      {Object.keys(TYPES_OF_SYSTEMATIC_METRICS)?.map?.(
                        (key, index) => (
                          <Text
                            width="auto"
                            backgroundColor={
                              currentType === key ? "#5D5D5D" : "#fff"
                            }
                            ml={index === 0 ? 0 : 10}
                            color={currentType === key ? "#fff" : "#5D5D5D"}
                            fontFamily={"Inter"}
                            fontWeight="normal"
                            fontSize="14px"
                            px={5}
                            borderRadius={4}
                            boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                            cursor="pointer"
                            onClick={() => {
                              setCurrentType(key);
                            }}
                          >
                            {TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label}
                          </Text>
                        )
                      )}
                    </Box>
                    <Text
                      casing="uppercase"
                      fontSize={20}
                      fontWeight="semibold"
                    >
                      Activity of {sectorWiseData?.totalClients} SME's
                    </Text>
                  </Box>

                  <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                    <GridItem
                      colSpan={9}
                      maxHeight="calc(100vh - 343px)"
                      overflow="auto"
                    >
                      {sectorWiseData?.yearWiseData &&
                        Object.keys(sectorWiseData?.yearWiseData).length >
                          0 && (
                          <>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={4}
                              p={"10px 0"}
                              overflowX={"auto"}
                              justifyItems={"center"}
                            >
                              {currentType === "financingAndGrowth" && (
                                <FinancingAndGrowth graphData={graphData} />
                              )}

                              {currentType === "assets" && (
                                <Assets graphData={graphData} />
                              )}

                              {currentType === "costHeads" && (
                                <CostHeads graphData={graphData} />
                              )}

                              {currentType === "trade" && (
                                <Trade graphData={graphData} />
                              )}
                            </Grid>
                          </>
                        )}
                    </GridItem>

                    <GridItem
                      pr={1}
                      mt={3}
                      colSpan={3}
                      maxHeight="calc(100vh - 343px)"
                      overflow="auto"
                    >
                      <IndustryLeaderInterview
                        industryName={clientData?.sector}
                        industryType={
                          TYPES_OF_SYSTEMATIC_METRICS?.[currentType]?.label
                        }
                      />
                    </GridItem>
                  </Grid>
                </>
              ) : (
                <>
                  <Flex h={"200px"} alignItems={"center"}>
                    <Heading
                      as={"h4"}
                      size={"md"}
                      w={"full"}
                      textAlign={"center"}
                      h={"fit-content"}
                      color={"primary.500"}
                    >
                      None of the company has uploaded their audit sheets.
                      Please visit this page later.
                    </Heading>
                  </Flex>
                </>
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default SMEIndustryActivity;
