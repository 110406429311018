import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ScatterChart,
  XAxis,
  YAxis,
  Scatter,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Flex, Select, FormLabel } from "@chakra-ui/react";
import { getClusterwiseApi } from "../../../../api/organisationApi";
import { getRechartContainerWidth } from "../../../../utils/common";

const ScatterChartComponent = () => {
  const dispatch = useDispatch();
  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );
  const { competitiveMap, client } = organisationData;
  const { windowSize } = useSelector((state) => state.uiReducer);
  const [data, setData] = useState(null); // Initialize data as null
  const [selectedYear, setSelectedYear] = useState(2017);
  const [selectedMetric, setSelectedMetric] = useState("roE");
  const [years, setYears] = useState([]);
  const selectedClientCompanyName = client ? client.companyName : null;
  const [companyNames, setCompanyNames] = useState([]);
  let processedData = null;

  useEffect(() => {
    if (client && competitiveMap && competitiveMap.data) {
      setYears(client?.auditUploadYears);
      // Extract company names from the data
      const names = competitiveMap.data.clusterWiseData.map(
        (cluster) => cluster.companyName
      );
      setCompanyNames(names);
    }
  }, [dispatch, client, competitiveMap]);
  

  useEffect(() => {
    if (client) {
      setYears(client?.auditUploadYears);
    }
  }, [dispatch, client]);

  useEffect(() => {
    if (competitiveMap?.data) {
      const totalRevenueFromOperationOfPeers =
        competitiveMap.data.totalRevenueFromOperationOfPeers;

      processedData = competitiveMap.data.clusterWiseData.map((cluster) => {
        const financialMetrics = cluster.financialMetrics;
        return financialMetrics.map((item) => {
          const year = item.year; // Define year here
          // Check if the year exists in totalRevenueFromOperationOfPeers
          if (totalRevenueFromOperationOfPeers.hasOwnProperty(year)) {
            return {
              companyName: cluster.companyName,
              year: year,
              roE: item.roE.currNum / item.roE.currDenom,
              ebitda: item.ebitda.currNum / item.ebitda.currDenom,
              materialsAndEmployees:
                item.materialsAndEmployees.currNum / item.materialsAndEmployees.currDenom,
              overheadPercentage:
                item.overheadPercentage.currNum / item.overheadPercentage.currDenom,
              genAndAdmPercentage:
                item.genAndAdmPercentage.currNum /
                item.genAndAdmPercentage.currDenom,
              intTaxDepPercentage:
                item.intTaxDepPercentage.currNum /
                item.intTaxDepPercentage.currDenom,
              roCE: item.roCE.currNum / item.roCE.currDenom,
              roA: item.roA.currNum / item.roA.currDenom,
              timesInterestEarned:
                item.timesInterestEarned.currNum /
                item.timesInterestEarned.currDenom,
              debtRatio: item.debtRatio.currNum / item.debtRatio.currDenom,
              xValue:
                item.revenueFromOperations /
                totalRevenueFromOperationOfPeers[year],
            };
          } else {
            return null;
          }
        });
      });
    }
  }, [competitiveMap]);
  useEffect(() => {
    if (competitiveMap?.data) {
      const years = competitiveMap.data.clusterWiseData[0].financialMetrics.map(
        (item) => item.year
      );
      setYears(years);
    }
  }, [competitiveMap]);

  const metricLabels = {
    roE: "Return on Equity (ROE)",
    ebitda: "EBITDA",
    materialsAndEmployees: "Material Percentage",
    overheadPercentage: "Overhead Percentage",
    genAndAdmPercentage: "General and Admin Percentage",
    intTaxDepPercentage: "Interest, Tax, and Depreciation Percentage",
    roCE: "Return on Capital Employed (ROCE)",
    roA: "Return on Assets (ROA)",
    timesInterestEarned: "Times Interest Earned",
    debtRatio: "Debt Ratio",
  };

  const flattenedData = processedData?.flat();

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };
  
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // Filter data based on the selected year
  const filteredData = competitiveMap?.data.clusterWiseData
  .flatMap((cluster) => {
    return cluster.financialMetrics
      .filter((item) => item.year === parseInt(selectedYear))
      .map((item) => {
        return {
          companyName: cluster.companyName, // Include the company name
          year: item.year,
          xValue:
            item.revenueFromOperations /
            competitiveMap.data.totalRevenueFromOperationOfPeers[item.year],
          [selectedMetric]: item[selectedMetric].currNum / item[selectedMetric].currDenom,
        };
      });
  });
  
  return (
    <div>
      <Flex justifyContent={"flex-end"}>
        <FormLabel mb={"0"}>Select Year: </FormLabel>
        <Select
  w={"260px"}
  variant={"filled"}
  name="selectedYear"
  value={selectedYear}
  onChange={handleYearChange}
  focusBorderColor={"primary.400"}
  color={"primary.600"}
>
  {years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ))}
</Select>
<FormLabel mb={"0"}>Select Financial Metric: </FormLabel>
<Select
  w={"260px"}
  variant={"filled"}
  name="selectedMetric"
  value={selectedMetric}
  onChange={handleMetricChange}
  focusBorderColor={"primary.400"}
  color={"primary.600"}
>
  {Object.keys(metricLabels).map((metric) => (
    <option key={metric} value={metric}>
      {metricLabels[metric]}
    </option>
  ))}
</Select>
      </Flex>

      <ResponsiveContainer
        width={getRechartContainerWidth(windowSize.innerWidth)}
        aspect={1.2}
      >
        <ScatterChart margin={{ top: 20, right: 30, bottom: 20, left: 30 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="xValue"
            type="number"
            domain={["auto", "auto"]}
            label={{
              value: "Relative Market Share",
              position: "insideBottomRight",
              offset: 0,
            }}
          />
          <YAxis
            dataKey={selectedMetric}
            type="number"
            domain={["auto", "auto"]}
            label={{
              value: metricLabels[selectedMetric],
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          {Object.keys(metricLabels).map((metric) => (
          <Scatter
            key={metric}
            name={metricLabels[metric]}
            data={filteredData}
            dataKey={selectedMetric}
            fill="#8884d8"// Set a default color
            // fill={(item) => (item.isClient && hasSelectedClient ? "#4d7b87" : "#8884d8")}
          />
        ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ScatterChartComponent;
