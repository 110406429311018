import { Outlet } from "react-router-dom";

import Header from "../../components/Header/Navbar";
import Footer from "../../shared/Footer/Footer";
// import Footer from "../../components/Footer/Footer";

const AuthLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      {/* <Footer /> */}
      { <Footer />}
    </>
  );
};

export default AuthLayout;
