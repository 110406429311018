import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAuditPreviewData } from "../../actions/audit/auditPreviewActions";
import { Heading, Stack } from "@chakra-ui/react";
import Loader from "../../components/Loader/Loader";

const AuditSheetPreview = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    params.id && dispatch(getAuditPreviewData(params.id));
  }, [dispatch, params.id]);

  const { loading, previewData } = useSelector(
    (state) => state.auditPreviewReducer
  );

  return (
    <>
      <Stack className="body-container" p={"27px 9px"}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Heading textAlign={"center"} p={"12px 0"}>
              {previewData?.client?.companyName}
            </Heading>
          </>
        )}
      </Stack>
    </>
  );
};

export default AuditSheetPreview;
