import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { getPopularSectorsResult } from "../../../actions/home/homeActions";
import { loginGoogleUser, loginUser } from "../../../actions/user/userActions";
import sylvr_logo from '../../../assets/images/sylvr-logo.jpg';
import './styles/login.scss';
import { GoogleIcon } from "../OAuthGroup/ProviderIcons";
import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin_icon from '../../../assets/icons/linkedin-icon.png'

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [curretnInputFocous,setCurretnInputFocous] = useState('');

  const [data, setData] = useState({
    email: "",
    pswd: "",
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(loginUser(data, navigate));
  };

const googleLogin = useGoogleLogin({
  onSuccess: async (codeResponse) => {
      dispatch(loginGoogleUser(codeResponse, navigate));
  },
  onError: (errorResponse) => console.log(errorResponse),
});
const { linkedInLogin } = useLinkedIn({
  clientId: '78nj9df5hr5l6z',
  redirectUri: 'http://sylvr.co.in/linkedinlogin', // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
  onSuccess: (code) => {
    console.log(code);
  },
  onError: (error) => {
    console.log(error);
  },
});
  return (
    <>
      <Box height="80vh">
        <Link to={"https://beta.sylvr.co.in"}>
          <Img src={sylvr_logo} width={100} />
        </Link>
        <Container minHeight="100vh" w={["100%", "85%", "75%"]} padding="20px">
          <form className="loginform" onSubmit={submitHandler}>
            <Box p={4} w="100%">
              <VStack spacing={4} align="stretch">
              <Text
                  w={"100%"}
                color="#4E6565"
                fontSize="16px"
                fontWeight={600}
                letterSpacing=".9px"
              >
                Welcome back
              </Text>
              <Box
                mt="50px"
                  padding="40px 30px 20px 30px"
                boxShadow="0px 0px 75px 0px rgba(0, 0, 0, 0.06);"
                borderRadius="8px"
                background="#F8FDFD"
              >
                <Box>
                    {curretnInputFocous === "email" ? (
                    <FormLabel
                      fontWeight={400}
                        fontSize={"13px"}
                        textColor={"#0C543C"}
                        w={"511px"}
                      mb={0}
                    >
                      Email
                    </FormLabel>
                  ) : (
                      ""
                  )}
                  <Input
                    maxW="100%"
                    type="email"
                    name="email"
                      placeholder={
                        curretnInputFocous === "email" ? "" : "Email"
                      }
                    value={data.email}
                    focusBorderColor="#30d5c8"
                    focusborderwidth="5px"
                    transition="height 0.3s"
                      fontWeight={"600"}
                    fontSize={18}
                    mb={10}
                      onFocus={() => setCurretnInputFocous("email")}
                      onBlur={() => setCurretnInputFocous("")}
                    _placeholder={{
                        fontWeight: "400",
                        fontSize: "18px",
                        color: "#4E6565",
                    }}
                    _focus={{
                        height: "3rem",
                        borderLeft: "5px solid #30d5c8",
                    }}
                    onChange={changeHandler}
                    isRequired
                  />
                </Box>

                <Box>
                    {curretnInputFocous === "pswd" ? (
                    <FormLabel
                      fontWeight={400}
                        fontSize={"13px"}
                        textColor={"#0C543C"}
                        w={"511px"}
                      mb={0}
                    >
                      Password
                    </FormLabel>
                  ) : (
                      ""
                  )}
                  <InputGroup>
                    <Input
                      maxW="100%"
                        type={showPassword ? "text" : "password"}
                      name="pswd"
                      value={data.pswd}
                      placeholder={
                          curretnInputFocous === "pswd" ? "" : "Password"
                      }
                      focusBorderColor="#30d5c8"
                      focusborderwidth="5px"
                      transition="height 0.3s"
                        fontWeight={"600"}
                      fontSize={18}
                        onFocus={() => setCurretnInputFocous("pswd")}
                        onBlur={() => setCurretnInputFocous("")}
                      _placeholder={{
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "#4E6565",
                      }}
                      _focus={{
                          height: "3rem",
                          borderLeft: "5px solid #30d5c8",
                      }}
                      onChange={changeHandler}
                      isRequired
                    />
                      <InputRightElement width="4.5rem" h={"100%"}>
                      <IconButton
                        h="1.75rem"
                        size="md"
                        onClick={() => setShowPassword(!showPassword)}
                          bg={"gray.200"}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                  <Flex className="forgot-signup-block">
                    <Box>
                      <a href="/password/forgot" className="forgot-block">
                        Reset Password
                      </a>
                    </Box>
                  </Flex>
                <Button
                  type="submit"
                  borderRadius="8px"
                    fontSize={{ base: "14px", md: "18px" }}
                  fontWeight={600}
                  width="100%"
                    mt="5px"
                    paddingY={{ base: "12px", md: "10px" }}
                    height={"auto"}
                  background="#0C543C"
                    variant={"solid"}
                  onClick={submitHandler}
                  _hover={{
                      boxShadow: "lg",
                  }}
                >
                  Login
                </Button>
                  <HStack>
                    <Divider />
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                      or continue with
                    </Text>
                    <Divider />
                  </HStack>
                  <Box textAlign={"center"}>
                    <Button
                      onClick={() => googleLogin()}
                      key={"Google"}
                      flexGrow={1}
                      bg="inherit"
                    >
                      <GoogleIcon />
                    </Button>
                    <Button
                      onClick={linkedInLogin}
                      key={"Linkedin"}
                      flexGrow={1}
                      bg="inherit"
                    >
                      <Img
                        src={linkedin_icon}
                        alt="linkedin icon"
                        style={{ width: "30px" }}
                      />
                    </Button>
              </Box>
                </Box>
              </VStack>

              <HStack width="100%" justifyContent={"center"} mt={6}>
                <Text
                  fontWeight={400}
                  fontSize={["12px", "14px", "16px"]}
                  pt={"13px"}
                  textColor={"#1E1E1E"}
                >
                  Don’t have an account?
                </Text>
                <Text
                  fontWeight={600}
                  fontSize={["14px", "16px", "18px"]}
                  textColor={"#0C543C"}
                  borderBottom={"3px solid #0C543C"}
                  onClick={() => navigate("/register-new")}
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  Create one!
                </Text>
              </HStack>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
