import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

const InterpretationModal = ({
  position = { x: 0, y: 0 },
  text = [{ heading: "", text: "" }],
  textColor = '#162421'
}) => {
  return (
    <>
      <Box
        p={"7px 9px"}
        position={"absolute"}
        top={position.y}
        left={position.x}
        borderRadius={"7px"}
        backgroundColor={"#F8FDFD"}
        color={"white"}
        zIndex={"10"}
        width={'466px'}
        
      >
        <Stack>
          {text.map((item, index) => (
            <Box mb={2} key={index}>
              <Text color={textColor} fontWeight={600} fontSize={'18px'}>{item.heading}:</Text>
              <Text color={'#162421'} fontWeight={400} fontSize={'14px'}>{item.text}</Text>
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default InterpretationModal;
