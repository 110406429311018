import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Img,
  Heading,
  ListItem,
  List,
} from "@chakra-ui/react";
import bullet from "../../../assets/images/bullet.png";

import Loader from "../../../components/Loader/Loader";
import {
  getAuditSheetDataForClient,
  getCompetitiveMapData,
  getCompetitiveMapDataForClient,
  getTrendsForClient,
  getZScoreForClient,
} from "../../../actions/user/organisationActions";
import downarrowmenu from "../../../assets/images/arrow-down.png";
import uparrowmenu from "../../../assets/images/arrow-up.png";

import { COLORS, TYPES_OF_COMPETATIVE_METRICS, TYPES_OF_METRICS, TYPES_OF_SEGMENT_METRICS } from "../../../constants/metrics";
import AdvisoryNotes from "../../../components/AdvisoryNotes/AdvisoryNotes";
import { PerformanceMatrixComponent } from "../../../shared/CompetitiveRisk/PerformanceMatrixComponent";
import { CompetitiveMapComponent } from "../../../shared/CompetitiveRisk/CompetitiveMapComponent";
import { MetricsSpectrumComponent } from "../../../shared/CompetitiveRisk/MetricsSpectrumComponent";
import UnlockRegistration from "../../../shared/UnlockRegistration/UnlockRegistration";
import RegistrationPopUp from "../RegistrationPopUp";
import sales from "../../../assets/images/sales.png";
import distribution from "../../../assets/images/distribution.png";
import hr from "../../../assets/images/hr.png";
import production from "../../../assets/images/production.png";
import operation from "../../../assets/images/operation.png";
import financing from "../../../assets/images/financing.png";

const CompetitiveRisk = ({ industryId }) => {
  console.log("========CompetitiveRisk", industryId);
  const dispatch = useDispatch();
  const RIGHT_INTERPRETATIONS = [
    "align/maintain/manage/ensure/control",
    "focus/develop/invest/implement/conduct",
    "streamline/optimize/support",
    "enhance/improve/increase/srengthen/expand",
    "reduce/minimize/eliminate",
    "negotiate/restructure",
  ];
  const { user, isAuthenticatedUser } = useSelector(
    (state) => state.userReducer
  );
  const [currentCompetativeMetric, setCurrentCompetativeMetric] = useState(
    TYPES_OF_SEGMENT_METRICS[0].label
  );
  const METRIC_BG_COLORS = ["#E4F0DD", "#FFF1E1", "#FDE7E7"];
  const METRIC_COLORS = ["#2D6C08", "#BE4400", "#B31B1B"];
  const riskSideMenu = [
    "growthAndReturns",
    "efficiencyAndLeverage",
    "marginAndCost",
  ];
  const [modelRegistrationPopUp, setModelRegistrationPopUp] = useState(false);
  const [currentType, setCurrentType] = useState(riskSideMenu[0]);

  const [metricColors, setMetricColors] = useState([]);

  const [currentMetric, setCurrentMetric] = useState(
    TYPES_OF_METRICS[riskSideMenu[0]]?.value?.[0]?.label
  );

  const [patternData, setPatternData] = useState(null);

  const [patternValue, setPatternValue] = useState([]);

  const applyColorsToMetrics = (dataList) => {
    const coloredList = dataList.map((_, index) => {
      const colorIndex = index % METRIC_BG_COLORS.length;
      return {
        bgColor: METRIC_BG_COLORS[colorIndex],
        textColor: METRIC_COLORS[colorIndex],
      };
    });

    return coloredList;
  };

  const coloredDataList = applyColorsToMetrics(
    TYPES_OF_METRICS?.[currentType]?.value
  );

  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  const { client } = organisationData;

  useEffect(() => {
    if (organisationData?.client?.subsector) {
      dispatch(getCompetitiveMapData(organisationData.client.subsector));
    }
  }, [dispatch, organisationData?.client?.subsector]);
  console.log("====client.id", user, client);
  useEffect(() => {
    if (user?.id && user?.id !== "") {
      user?.id && dispatch(getZScoreForClient(user.id));
      user?.id && dispatch(getTrendsForClient(user.id));
      user?.id && dispatch(getCompetitiveMapDataForClient(user.id));
      user?.id && dispatch(getAuditSheetDataForClient(user.id));
    }
  }, [dispatch, user?.id]);

  // useEffect(() => {
  //   const data = { client: { ...client } };
  //   dispatch({
  //     type: "GET_ORG_DETAILS_SUCCESS",
  //     payload: data,
  //   });
  // }, [dispatch, client.id]);
  const imageObject = {
    Sales: sales,
    Distribution: distribution,
    HR: hr,
    Production: production,
    Operations: operation,
    Finance: financing,
  };
  return (
    <>
      {isAuthenticatedUser ? (
        <Flex id={"competitive_risk_container"}>
          <Box
            id={"competitive_risks_type"}
            className="leftNavOptions"
            style={{ height: "calc(100vh - 177px)" }}
          >
            {TYPES_OF_COMPETATIVE_METRICS?.map((data) => (
              <Box
                m={"0px"}
                w={"100%"}
                style={{
                  border: "1.3px solid #B1B1B1",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "4px",
                  padding: "8px",
                  marginBottom: "15px",
                }}
              >
                <Box>
                  <Flex
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      paddingBottom: "5px",
                    }}
                    onClick={() => {
                      setCurrentCompetativeMetric(data?.label);
                    }}
                  >
                    <Box
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {data?.label}
                    </Box>
                    <Box className="menu-icon">
                      <img
                        src={
                          currentCompetativeMetric === data?.label
                            ? uparrowmenu
                            : downarrowmenu
                        }
                        alt="menu-arrow"
                        className="metric-arrow"
                      />
                    </Box>
                  </Flex>
                </Box>
                {currentCompetativeMetric === data?.label &&
                  data?.value?.map((value) => (
                    <Box
                      m={"0px"}
                      w={"100%"}
                      style={{
                        backgroundColor: "#E6E6E6",
                        borderRadius: "4px",
                        marginBottom: "10px",
                      }}
                    >
                      <Flex justifyContent={"flex-start"} alignItems={"center"}>
                        <Box style={{ marginLeft: "9px" }}>
                          <img src={imageObject[value?.label]} />
                        </Box>
                        <Box
                          style={{
                            fontFamily: "Inter",
                            padding: "8px",
                            fontSize: "14px",
                            fontWeight: 400,
                            textAlign: "left",
                            color: "#000000",
                          }}
                        >
                          {value?.label}
                        </Box>
                      </Flex>
                      {value?.value?.map((row, index) => (
                        <>
                          <Box
                            m={"0px"}
                            w={"100%"}
                            style={{
                              padding: "8px",
                              backgroundColor: "white",
                            }}
                            className={
                              index === value?.value?.length - 1
                                ? "metric-bottomBorderRadius"
                                : ""
                            }
                          >
                            <Box
                              style={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 400,
                                textAlign: "left",
                                color: "#1E1E1E",
                              }}
                            >
                              {row?.label}
                            </Box>
                          </Box>
                          {index != value?.value?.length - 1 && (
                            <Box
                              style={{
                                // margin: "0px 5px",
                                backgroundColor: "white",
                              }}
                            >
                              <Box
                                style={{
                                  border: "1.5px solid #E6E6E6",
                                  margin: "0px 5px",
                                }}
                              ></Box>
                            </Box>
                          )}
                        </>
                      ))}
                    </Box>
                  ))}
              </Box>
            ))}
            {false && riskSideMenu?.map?.((key, index) => (
              <Box m={"0px"} w={"100%"}>
                <div
                  onClick={() => {
                    setCurrentType(key);
                    setCurrentMetric(TYPES_OF_METRICS?.[key]?.value[0]?.label);
                  }}
                  className={
                    currentType === key
                      ? "riskSubTabs menu-label-arrow menu-label-arrow-selected"
                      : "riskSubTabs menu-label-arrow"
                  }
                >
                  <div>{TYPES_OF_METRICS?.[key]?.label}</div>
                  <div className="menu-icon">
                    <img
                      src={currentType === key ? uparrowmenu : downarrowmenu}
                      alt="menu-arrow"
                      className="menu-arrow"
                    />
                  </div>
                </div>
                {currentType === key && (
                  <Box pt={5} borderTop="3px solid #E6E6E6">
                    {TYPES_OF_METRICS &&
                      TYPES_OF_METRICS?.[key]?.value?.map?.((val, index) => {
                        return (
                          <Text
                            width="auto"
                            backgroundColor={
                              currentMetric === val?.label
                                ? metricColors?.[val?.label]?.textColor ||
                                  coloredDataList?.[index]?.textColor
                                : metricColors?.[val?.label]?.bgColor ||
                                  coloredDataList?.[index]?.bgColor
                            }
                            color={
                              currentMetric === val?.label
                                ? "#fff"
                                : metricColors?.[val?.label]?.textColor ||
                                  coloredDataList?.[index]?.textColor
                            }
                            fontFamily={"Inter"}
                            fontWeight="400"
                            fontSize="14px"
                            p={1}
                            textTransform="uppercase"
                            borderRadius={4}
                            boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                            cursor="pointer"
                            onClick={() => {
                              setCurrentMetric(val.label);
                            }}
                          >
                            {val?.value}{" "}
                            {currentType === "marginAndCost" && "%"}
                          </Text>
                        );
                      })}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          <Box id={"competitive_risks_content"}>
            <Box id={"competitive_risks_graph"}>
              {false ? (
                <>
                  <Box h={"200px"}>
                    <Loader />
                  </Box>
                </>
              ) : (
                <>
                  <Box className="activity-content-graph">
                    <Grid
                      display={"block"}
                      templateAreas={`"PerformanceMatrix CompetitiveMap"
                              "MetricsSpectrum CompetitiveMap"`}
                      gridTemplateRows={"472px"}
                      gridTemplateColumns={"1fr"}
                      gap="1"
                    >
                      <GridItem
                        area={"PerformanceMatrix"}
                        borderBottom={"3px solid #F8F8F8"}
                        pb={4}
                      >
                        <Box py={5} pl={3} pr={3}>
                          <Text
                            textTransform="uppercase"
                            color={"#162421"}
                            fontWeight={600}
                            fontSize={16}
                          >
                            Patterns
                          </Text>
                          <PerformanceMatrixComponent
                            setCurrentType={setCurrentType}
                            currentMetric={currentMetric}
                            setPatternData={setPatternData}
                            setMetricColors={setMetricColors}
                            metricColors={metricColors}
                            setCurrentMetric={setCurrentMetric}
                          />
                        </Box>
                      </GridItem>
                      <GridItem area={"CompetitiveMap"}>
                        <Box p={8} colSpan={1} borderLeft="3px solid #F8F8F8">
                          <Box py={3} px={5}>
                            <CompetitiveMapComponent
                              patternData={patternData}
                              currentType={currentType}
                              currentMetric={currentMetric}
                              setPatternValue={setPatternValue}
                            />
                          </Box>
                        </Box>
                      </GridItem>
                      <GridItem area={"MetricsSpectrum"}>
                        <Box p={5}>
                          <MetricsSpectrumComponent
                            currentMetric={currentMetric}
                            patternData={patternData}
                            currentType={currentType}
                            metricColors={metricColors}
                          />
                        </Box>
                      </GridItem>
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
            <Box id={"segment_risks_observations"} height={"100%"}>
              <Box height={"50%"}>
                {false ? (
                  <>
                    <Box h={"200px"}>
                      <Loader />
                    </Box>
                  </>
                ) : (
                  <Box boxShadow="0 0 5px 0 rgba(0,0,0,0.2)">
                    <Box display="flex" p={"10px"}>
                      <Heading
                        color="#5D5D5D"
                        textTransform="uppercase"
                        fontWeight={600}
                        fontSize={"16px"}
                        letterSpacing={"0.9px"}
                      >
                        Pattern
                      </Heading>
                      <Text
                        width="auto"
                        backgroundColor={
                          COLORS[patternData?.interpretation?.color]?.bgColor ||
                          "#EAF4EB"
                        }
                        m={"0px 0px 0px 10px"}
                        color={
                          COLORS[patternData?.interpretation?.color]
                            ?.textColor || "#0D3B14"
                        }
                        fontFamily={"Inter"}
                        fontWeight={600}
                        fontSize="14px"
                        px={5}
                        borderRadius={1}
                        letterSpacing={"0.8px"}
                        boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                      >
                        <Text display="inline-block" m={"0px 0px 0px 10px"}>
                          {(patternData?.pattern || "").replace(
                            /\b\w/g,
                            function (match) {
                              return match.toUpperCase();
                            }
                          )}
                        </Text>
                        <Text display="inline-block" m={"0px 0px 0px 10px"}>
                          {patternValue?.metricValue}
                        </Text>
                      </Text>
                    </Box>

                    {currentType === "growthAndReturns" ? (
                      ""
                    ) : (
                      <Box p={"5px 0px 0px 10px"}>
                        {/* <Flex direction={{ base: 'column', lg: 'row' }}> */}
                        <Box
                          width={{ base: "100%" }}
                          borderRight={"4px solid #F2F7F7"}
                          pr={2}
                        >
                          <Heading
                            color="#5D5D5D"
                            textTransform="uppercase"
                            fontWeight={600}
                            fontSize={"16px"}
                            letterSpacing={"0.9px"}
                          >
                            Ways To Assess Or Diagnose
                          </Heading>
                          <List spacing={3} mt="2">
                            <ListItem
                              color="#1E1E1E"
                              letterSpacing="0.8px"
                              display="flex"
                              fontWeight={600}
                              fontSize={"14px"}
                              alignItems="center"
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V13"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <Box mr={2}></Box>
                              {patternData?.interpretation
                                ?.waysToAssessOrDiagnose || ""}
                            </ListItem>
                          </List>
                        </Box>

                        <Box maxW={{ base: "100%" }} ml={2}>
                          <Heading
                            color="#5D5D5D"
                            textTransform="uppercase"
                            fontWeight={600}
                            fontSize={"16px"}
                            letterSpacing={"0.9px"}
                          >
                            Internal Controls
                          </Heading>
                          <List spacing={3} mt="2">
                            <ListItem
                              color="#1E1E1E"
                              letterSpacing="0.8px"
                              display="flex"
                              fontWeight={600}
                              fontSize={"14px"}
                              alignItems="center"
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V13"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <Box mr={2}></Box>
                              {patternData?.interpretation?.internalControls ||
                                ""}
                            </ListItem>
                            {RIGHT_INTERPRETATIONS.map((intrPret) => {
                              const interpretation =
                                patternData?.interpretation
                                  ?.BusinessCapabilityRecommendation?.[
                                  intrPret
                                ];

                              if (!interpretation) return "";

                              return (
                                <ListItem
                                  color="#1E1E1E"
                                  fontWeight="400"
                                  letterSpacing="0.8px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Img src={bullet} mr={2} />
                                  {intrPret}: {interpretation}
                                </ListItem>
                              );
                            })}
                          </List>
                        </Box>
                        {/* </Flex> */}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box height={"50%"}>
                <Box id={"segment_risks_advisory_content"}>
                  <AdvisoryNotes clientId={user?.id} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      ) : (
        <Box p={"15%"}>
          <UnlockRegistration
            setModelRegistrationPopUp={setModelRegistrationPopUp}
          />
        </Box>
      )}
      <RegistrationPopUp
        setModelRegistrationPopUp={setModelRegistrationPopUp}
        modelRegistrationPopUp={modelRegistrationPopUp}
      />
    </>
  );
};

export default CompetitiveRisk;
